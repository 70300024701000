import { Button, Row, Col } from 'antd';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';

export const toastError = (text, status) => {
  const errorMessage = text?.length ? text : 'התרחשה שגיאה כללית.';
  const statusCode = status ? status : 500;
  return toast.error(
    <Row
      justify={'space-between'}
      className='px-1'
    >
      <Col>{errorMessage}</Col>
      <Col>({statusCode})</Col>
      <Col span={24}>
        נא לנסות{' '}
        <Button
          className='p-0'
          Button
          type='link'
          onClick={() => window.location.reload()}
        >
          {' '}
          לטעון מחדש את העמוד
        </Button>
        .
      </Col>
    </Row>,
    { autoClose: 8000 }
  );
};

export const calculateDiffTime = (inTime, outTime) => {
  let startTime = dayjs(inTime);
  let endTime = dayjs(outTime);

  if (startTime.isAfter(endTime)) {
    endTime = endTime.add(1, 'day');
  }

  const duration = endTime.diff(startTime, 'minute'); // Difference in minutes
  // Calculate hours and remaining minutes
  const hours = Math.floor(duration / 60);
  const minutes = duration % 60;

  return dayjs(startTime).hour(hours).minute(minutes);
};

export const calculateTotalTime = (timeList) => {
  let totalTime = 0;

  if (timeList.length)
    timeList.forEach((entry) => {
      let inTime = dayjs(entry.in_time);
      let outTime = dayjs(entry.out_time);
      if (inTime > outTime) {
        outTime = outTime.add(1, 'day');
      }
      const duration = outTime.diff(inTime, 'minute'); // Difference in minutes
      totalTime += duration;
    });

  // const totalHours = Math.floor(totalTime / 60);
  // const totalMinutes = totalTime % 60;

  // return { hours: totalHours, minutes: totalMinutes };
  return totalTime;
};

// Function to convert time string to minutes
export const timeStringToMinutes = (timeString) => {
  const [hours, minutes] = timeString.split(':').map(Number);
  return hours * 60 + minutes;
};

export const minutesToTimeString = (totalMinutes) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
};

// Function to convert timestring to decimal
export const convertTimeToDecimal = (timeString) => {
  const [hours, minutes] = timeString.split(':').map(Number);

  // Convert to decimal
  const decimalMinutes = minutes / 60;
  const totalHours = hours + decimalMinutes;

  if (Number.isInteger(totalHours)) {
    return totalHours.toString();
  } else {
    return totalHours.toFixed(2).replace(/\.?0+$/, '');
  }
};

export const convertStringToHTML = (inputString) => {
  // Split the input string into an array of rows
  const rows = inputString.split('\n');

  // Map each row to a <p> element
  const paragraphs = rows.map((row) => `<p>${row.trim()}</p>`);

  // Join the <p> elements into a single string
  const paragraphsString = paragraphs.join('\n');

  // Wrap the <p> elements with <div>
  const result = `<div>${paragraphsString}</div>`;

  return result;
};

export const areArraysEqual = (arr1, arr2) => {
  const existsProjects = arr1.slice().sort();
  const selectedProjects = arr2.slice().sort();

  let equal = false;
  if (selectedProjects.length === existsProjects.length) {
    // Check if all elements in the arrays are equal using JSON.stringify
    equal = selectedProjects.every((value, index) => value === existsProjects[index]);
  }
  return equal;
};

//redux
export const getAuthState = (state) => state.auth;
export const getManagedReportState = (state) => state.managedReport;
export const getSelectedDateState = (state) => state.selectedDate;
export const getDateReportsState = (state) => state.dateReports;
export const getDateEventsState = (state) => state.dateEvents;
export const getPageLayoutState = (state) => state.pageLayout;
