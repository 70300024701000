import { useCallback, useState } from 'react';
import {
  getUserPreferences,
  updateUserPreferences,
  getUserDetails,
  updateUserDetails,
} from '../services/users';
import logger from '../logger';
import { handleError } from '../utils/handleErrors';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { updateUserDetailsAction } from '../actions';

const useUserPersonalDetails = (initialToken) => {
  const dispatch = useDispatch();
  const [userDetails, setUserDetails] = useState(null);
  const [userPreferences, setUserPreferences] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [token, setToken] = useState(initialToken);

  const getUser = useCallback(() => {
    setLoading(true);
    getUserDetails(token)
      .then((res) => {
        setUserDetails(res.data);
      })
      .catch((error) => {
        const parsedError = handleError(error);
        logger.error(error, true);
        // toast.error(parsedError.toastMessage);
        setError(parsedError);
      });
    setLoading(false);
  }, [token]);

  const updateUser = (updatedDetails) => {
    setLoading(true);
    return updateUserDetails(token, updatedDetails)
      .then((res) => {
        //dispatch to redux updated details
        dispatch(updateUserDetailsAction(res.data));
        toast.success('הפרטים שלך התעדכנו בהצלחה');
        getUser();
        setLoading(false);
        return true;
      })
      .catch((error) => {
        const parsedError = handleError(error);
        logger.error(error, true);
        // toast.error(parsedError.toastMessage);
        setError(parsedError);
        setLoading(false);
        return false;
      });
  };

  const getPreferences = useCallback(
    (displayToast = false) => {
      setLoading(true);
      getUserPreferences(token)
        .then((res) => {
          setUserPreferences(res.data);
        })
        .catch((error) => {
          logger.error(error, displayToast);
        });
      setLoading(false);
    },
    [token]
  );

  const updatePreferences = (updatedPreferences) => {
    setLoading(true);
    return updateUserPreferences(token, updatedPreferences)
      .then((res) => {
        toast.success('ההעדפות שלך התעדכנו בהצלחה');
        getPreferences();
        setLoading(false);
        return true;
      })
      .catch((error) => {
        logger.error(error, true);
        // toast.error(parsedError.toastMessage);
        setLoading(false);
        return false;
      });
  };

  const setAuthToken = (token) => {
    setToken(token);
  };

  return {
    userDetails,
    userPreferences,
    loading,
    error,
    getUser,
    updateUser,
    getPreferences,
    updatePreferences,
    setAuthToken,
  };
};

export default useUserPersonalDetails;
