import { Table } from 'antd';
import React, { useEffect, useState } from 'react';

const paginationInit = {
  hideOnSinglePage: true,
  position: ['bottomRight'],
  size: 'small',
  showSizeChanger: true,
  pageSize: 8,
  showTotal: (total, range) => `מציג ${range[1]}-${range[0]} מתוך ${total}`,
};

const ReportsTable = ({ columns, data, loading, onSummary, footer, paginationSettings }) => {
  const [pagination, setPagination] = useState({
    ...paginationInit,
    ...paginationSettings,
    total: data.length,
    hideOnSinglePage: data.length > 50,
  });

  useEffect(() => {
    // Determine pageSizeOptions based on data length
    let pageSizeOptions = [];
    let showSizeChanger = true;

    if (data.length <= 10) {
      showSizeChanger = false; // Do not show page size options
    } else if (data.length > 10 && data.length < 50) {
      pageSizeOptions = ['10', '25'];
    } else {
      pageSizeOptions = ['10', '25', '50'];
    }

    setPagination((prev) => ({
      ...prev,
      ...paginationSettings, // Apply pagination settings passed as props
      pageSizeOptions, // Set the calculated pageSizeOptions
      showSizeChanger, // Set whether to show the pageSizeOptions
      total: data.length,
      hideOnSinglePage: data.length > 50,
    }));
  }, [data.length, paginationSettings]);

  const handleTableChange = (newPagination) => {
    setPagination({
      ...pagination,
      ...newPagination,
    });
  };

  return (
    <Table
      summary={onSummary}
      bordered={false}
      size='middle'
      columns={columns}
      dataSource={data.map((object, index) => ({ ...object, key: index }))}
      loading={loading.read}
      pagination={pagination}
      onChange={handleTableChange}
      footer={footer}
    />
  );
};

export default ReportsTable;
