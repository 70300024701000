import { DELETE_REPORT, DUPLICATE_REPORT, EDIT_REPORT, RESET_REPORT } from '../actions/types';

const initState = {
  formState: 'INIT',
  activity_id: undefined,
  in_time: null,
  location_id: undefined,
  out_time: null,
  project_id: undefined,
  user_id: undefined,
  date: undefined,
  startDate: undefined,
  endDate: undefined,
  comments: '',
  isAttachedFileNeeded: false,
};

export const manageTimeReportReducer = (state = initState, { type, payload }) => {
  switch (type) {
    // case CREATE_REPORT:
    //   return payload;
    case EDIT_REPORT:
      return {
        ...state,
        id: payload.id,
        activity_id: payload.activity_id,
        in_time: payload.in_time,
        location_id: payload.location_id,
        out_time: payload.out_time,
        project_id: payload.project_id,
        user_id: payload.user_id,
        comments: payload.comments,
        file: payload.file_id ? payload.file : undefined,
        formState: 'EDIT',
      };
    case DUPLICATE_REPORT:
      return {
        ...initState,
        project_id: payload.project_id,
        activity_id: payload.activity_id,
        location_id: payload.location_id,
        in_time: payload.in_time,
        out_time: payload.out_time,
        formState: 'DUPLICATE',
      };
    case DELETE_REPORT:
      return payload;
    case RESET_REPORT:
      return initState;
    default:
      return state;
  }
};
