import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Select,
  DatePicker,
  Form,
  Button,
  Row,
  Col,
  Switch,
  Typography,
  Popconfirm,
  Flex,
  Tooltip,
} from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import useProjectManagement from '../../hooks/useProjectManagement';
import useUserManagement from '../../hooks/useUserManagement';
import useTemplateFilter from '../../hooks/useTemplateFilter';
import { getAuthState } from '../../utils';
import CustomModal from '../modals/CustomModal';
import ReportTemplateForm from './ReportTemplateForm';
import logger from '../../logger';

const initReportsState = {
  users: [],
  startDate: undefined,
  endDate: undefined,
  projects: [],
  sortingOption: 'unfiltered',
  isSummary: false,
};
const sortingOptions = [
  { value: 'unfiltered', label: 'ללא סינון' },
  { value: 'byDate', label: 'סינון לפי תאריך' },
  { value: 'byActivity', label: 'סינון לפי פעילות' },
  { value: 'byUser', label: 'סינון לפי משתמש' },
];

const ReportsForm = ({ getRequiredReports, loading, isUserPermitted }) => {
  const { user, token } = useSelector((state) => getAuthState(state));
  const [form] = Form.useForm();
  const [template, setTemplate] = useState(null);
  // const [disabledFetch, setDisableFetch] = useState(true);
  const [disabledReset, setDisabledReset] = useState(true);
  const [disableUserSelection, setDisableUserSelection] = useState(true);
  const [users, setUsers] = useState([]);
  const [projectOptions, setProjectOptions] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [showSummarySwitch, setShowSummarySwitch] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [popconfirmOpen, setPopconfirmOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    templates,
    loading: loadingTemplates,

    removeTemplate,
    saveTemplate,
  } = useTemplateFilter(token);
  const {
    getProjectsForReports,

    loading: projectLoading,
  } = useProjectManagement(token);
  const {
    loadUsersByProjects,

    loading: userLoading,
  } = useUserManagement(token);

  useEffect(() => {
    getProjectsForReports().then((res) => {
      if (res) {
        setProjectOptions(res.data);
      }
    });
  }, [getProjectsForReports, user]);

  useEffect(() => {
    if (isFilterActive) {
      setFilteredProjects(projectOptions.filter((p) => p.active === true));
    } else {
      setFilteredProjects(projectOptions);
    }
  }, [isFilterActive, projectOptions]);

  const handleReset = () => {
    form.resetFields();
    // setDisableFetch(true);
    setDisabledReset(true);
    setSelectedTemplate(null);
    setTemplate(null);
  };

  const handleFinish = () => {
    form.validateFields().then((values) => {
      getRequiredReports(values);
      // .then((res) => {
      //   if (res) {
      //     setDisableFetch(res);
      //   }
      // });
    });
  };

  const onFieldValueChanged = (field) => {
    // const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    const isFilled = allValuesFilled();
    // setDisableFetch(hasErrors || !isFilled);
    setDisabledReset(!isFilled);
    isFilled ? setTemplate(form.getFieldsValue()) : setTemplate(null);
  };

  const allValuesFilled = () => {
    const formValues = form.getFieldsValue();
    const { users, startDate, endDate, projects, sortingOption } = formValues;
    setShowSummarySwitch(sortingOption !== 'unfiltered');
    if (sortingOption === 'unfiltered') {
      form.setFieldValue('isSummary', false);
    }
    return (
      users.length > 0 &&
      startDate !== undefined &&
      endDate !== undefined &&
      projects.length > 0 &&
      sortingOption !== undefined
    );
  };

  const handleLoadingUsersByProjects = async (data) => {
    await loadUsersByProjects(data)
      .then((usersResponse) => {
        if (usersResponse) {
          setUsers(usersResponse);
          setDisableUserSelection(false);
          form.setFieldValue('users', [
            ...form.getFieldValue('users'),
            ...usersResponse.filter((user) => user.is_assigned).map((user) => user.id),
          ]);
        }
      })
      .catch((error) => {
        logger.error(error);
      });
  };

  const handleTemplateChanged = async (value) => {
    setSelectedTemplate(value);
    const loadedTemplate = templates.find((template) => template.id === value);
    if (loadedTemplate) {
      const projectsData = {
        projects: loadedTemplate.projects,
        department_id: user.department_id,
      };
      await handleLoadingUsersByProjects(projectsData);

      form.setFieldsValue(loadedTemplate);
    } else {
      form.resetFields();
    }
  };

  const handleToggleModal = () => {
    setIsModalOpen((state) => !state);
  };

  const handleCancelModal = () => {
    handleToggleModal?.();
  };

  const handleCreateTemplate = (data) => {
    return saveTemplate(data).then((success) => {
      if (success) {
        toast.success('דוח מועדף התווסף בהצלחה');
        handleToggleModal();
        return true;
      } else {
        return false;
      }
    });
  };

  const handleOnDeleteTemplate = () => {
    removeTemplate(selectedTemplate).then((res) => {
      if (res) {
        setSelectedTemplate(null);
        toast.success('הדוח נמחק בהצלחה!');
        setPopconfirmOpen(false);
      }
    });
  };

  const onSelectProjectsChange = () => {
    const projects = form.getFieldValue('projects');
    if (projects) {
      const data = {
        projects: projects,
        department_id: user.department_id,
      };
      isUserPermitted() ? form.setFieldValue('users', []) : form.setFieldValue('users', [user.id]);
      handleLoadingUsersByProjects(data);
    } else {
      setUsers([]);
      setDisableUserSelection(true);
    }
  };

  return (
    <>
      <CustomModal
        modalTitle={'הוספת דוח מועדף'}
        isModalOpen={isModalOpen}
        handleCancel={handleCancelModal}
        width={'fit-content'}
      >
        <ReportTemplateForm
          templateDetails={template}
          isLoading={loadingTemplates}
          onConfirm={handleCreateTemplate}
        />
      </CustomModal>

      <Flex
        vertical
        gap='middle'
      >
        <div className={!isUserPermitted() ? 'collapse' : ''}>
          <Row>
            <Typography.Text>דוח מועדף</Typography.Text>
          </Row>

          <Row gutter={12}>
            <Col span={18}>
              <Select
                showSearch
                allowClear
                style={{ width: '100%' }}
                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                // load favorite reports
                placeholder='רשימת דוחות מועדפים'
                // options={[{ value: 0, label: '----- ללא -----' }]}
                loading={loadingTemplates}
                value={selectedTemplate}
                onChange={handleTemplateChanged}
                options={[
                  { value: 0, label: '----- ללא -----' },
                  ...templates.map((option) => ({ value: option.id, label: option.template_name })),
                ]}
              />
            </Col>
            <Col span={6}>
              <Tooltip title='הוספת דוח מועדף חדש'>
                <Button
                  icon={<PlusOutlined />}
                  type='text'
                  className={template ? '' : 'collapse'}
                  onClick={handleToggleModal}
                />
              </Tooltip>

              <Popconfirm
                className={selectedTemplate ? '' : 'collapse'}
                title='מחיקת דוח מועדף'
                description='האם ברצונך למחוק את הדוח מהרשימה?'
                open={popconfirmOpen}
                onConfirm={handleOnDeleteTemplate}
                okButtonProps={{ loading: loadingTemplates }}
                onCancel={() => setPopconfirmOpen(false)}
              >
                <Button
                  danger
                  icon={<DeleteOutlined />}
                  type='text'
                  onClick={() => setPopconfirmOpen(true)}
                ></Button>
              </Popconfirm>
            </Col>
          </Row>
        </div>

        <Form
          form={form}
          name='requestReportsForm'
          onFinish={handleFinish}
          initialValues={initReportsState}
          onFieldsChange={onFieldValueChanged}
        >
          <Row gutter={10}>
            <Col span={19}>
              <Form.Item name='projects'>
                <Select
                  title='בחר פרוייקט'
                  mode='multiple'
                  allowClear
                  showSearch
                  loading={projectLoading}
                  filterOption={(input, option) => (option?.label ?? '').includes(input)}
                  placeholder='בחר פרויקט'
                  options={filteredProjects.map((project) => ({
                    value: project.id,
                    label: project.project_name,
                  }))}
                  onChange={onSelectProjectsChange}
                />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item>
                <Switch
                  loading={projectLoading}
                  checkedChildren={'פעילים'}
                  unCheckedChildren={'הכל'}
                  value={isFilterActive}
                  onChange={(value) => setIsFilterActive(value)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name='users'
            className={isUserPermitted() ? '' : 'collapse'}
          >
            <Select
              allowClear
              showSearch
              disabled={disableUserSelection}
              filterOption={(input, option) => (option?.label ?? '').includes(input)}
              mode='multiple'
              placeholder='בחר משתמשים'
              loading={userLoading}
              // value={selectedUsers}
              options={users.map((user) => ({
                value: user.id,
                label: `${user.first_name} ${user.last_name}`,
              }))}
            />
          </Form.Item>

          <Form.Item name='sortingOption'>
            <Select
              placeholder='בחר חלוקה'
              options={sortingOptions}
            />
          </Form.Item>

          <Row
            gutter={12}
            justify={'space-between'}
          >
            <Col span={12}>
              <Form.Item
                name='startDate'
                rules={[
                  {
                    required: true,
                    message: 'נא לבחור תאריך התחלה',
                  },
                ]}
              >
                <DatePicker
                  placeholder='תאריך התחלה'
                  className='w-100'
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='endDate'
                rules={[
                  {
                    required: true,
                    message: 'נא לבחור תאריך סיום',
                  },
                ]}
              >
                <DatePicker
                  placeholder='תאריך סיום'
                  className='w-100'
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name='isSummary'
            valuePropName='checked'
            className={showSummarySwitch ? '' : 'collapse'}
          >
            <Switch
              checkedChildren='לסכם'
              unCheckedChildren='לא לסכם'
            />
          </Form.Item>
          <Row gutter={10}>
            <Col>
              <Button
                type='default'
                disabled={disabledReset}
                onClick={handleReset}
              >
                ניקוי
              </Button>
            </Col>
            <Col>
              <Button
                type='primary'
                htmlType='submit'
                // disabled={disabledFetch}
                loading={loading.read}
              >
                הבא דיווחים
              </Button>
            </Col>
          </Row>
        </Form>
      </Flex>
    </>
  );
};

export default ReportsForm;
