import dayjs from 'dayjs';
import { INIT_SELECTED_DATE, LOAD_REPORTS, SELECT_DATE, DELETE_REPORT } from '../actions/types';

const initTimeReports = [];

const initSelectedDate = { current: dayjs(), prev: null };

export const dateReportsReducer = (state = initTimeReports, { type, payload }) => {
  switch (type) {
    case LOAD_REPORTS:
      return payload;
    case DELETE_REPORT:
      return payload;
    default:
      return state;
  }
};

export const selectedDateReducer = (state = initSelectedDate, { type, payload }) => {
  switch (type) {
    case SELECT_DATE:
      return { current: payload, prev: state.current };
    case INIT_SELECTED_DATE:
      return initSelectedDate;
    default:
      return state;
  }
};
