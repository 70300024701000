import { useCallback, useState } from 'react';
import {
  createNewDateEvent,
  getDateEventsByDate,
  updateExistingDateEvent,
  deleteExistingDateEvent,
  getHebrewCalender,
} from '../services/date-events';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { loadDateEvents, resetDateEvents } from '../actions';
import logger from '../logger';
import { handleError } from '../utils/handleErrors';

const useDateEventsManagement = (initialToken) => {
  const dispatch = useDispatch();
  const [dateEvents, setDateEvents] = useState([]);
  const [futureDateEvents, setFutureDateEvents] = useState([]);
  const [hebrewDates, setHebrewDates] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [token, setToken] = useState(initialToken);

  //load all date events of selected date
  const loadDateEventsByDate = useCallback(
    (date, departments, isAllDepartments = false) => {
      setLoading(true);
      getDateEventsByDate({ date, departments, isAllDepartments }, token)
        .then((res) => {
          setDateEvents(res.data);
          dispatch(loadDateEvents(res.data));
        })
        .catch((error) => {
          const parsedError = handleError(error);
          dispatch(resetDateEvents());
          // logger.error(error);
          // toast.error(parsedError.toastMessage);
          setError(parsedError);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [dispatch, token]
  );

  //load all date events by range of dates
  const loadDateEventsByRange = useCallback(
    (department_id, isAllDepartments = false) => {
      const start_date = dayjs().format('YYYY-MM-DD');
      const end_date = dayjs().add(1, 'year').format('YYYY-MM-DD');
      setLoading(true);
      getDateEventsByDate(
        { date: { start_date, end_date }, departments: [department_id], isAllDepartments },
        token
      )
        .then((res) => {
          setFutureDateEvents(res.data);
        })
        .catch((error) => {
          const parsedError = handleError(error);
          // logger.error(error);
          // toast.error(parsedError.toastMessage);
          setError(parsedError);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [token]
  );

  const loadHebrewDateEvents = useCallback((date) => {
    setLoading(true);
    const month = dayjs(date).month() + 1;
    const year = dayjs(date).year();
    getHebrewCalender(month, year)
      .then((res) => {
        setHebrewDates(res.data);
      })
      .catch((error) => {
        const parsedError = handleError(error);
        logger.error(error, true);
        // toast.error(parsedError.toastMessage);
        setError(parsedError);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  //create event-date
  const createDateEvent = (data) => {
    setLoading(true);
    return createNewDateEvent(data, token)
      .then(() => {
        loadDateEventsByDate(data.start_date, data.departments, data.isAllDepartments);
        return true;
      })
      .catch((error) => {
        const parsedError = handleError(error);
        logger.error(error, true);
        // toast.error(parsedError.toastMessage);
        setError(parsedError);
        return false;
      })
      .finally(() => setLoading(false));
  };

  //update event-date
  const updateDateEvent = (data) => {
    const { id, ...restData } = data;
    setLoading(true);
    return updateExistingDateEvent(restData, id, token)
      .then(() => {
        loadDateEventsByDate(data.start_date, data.departments, data.isAllDepartments);
        return true;
      })
      .catch((error) => {
        const parsedError = handleError(error);
        logger.error(error, true);
        // toast.error(parsedError.toastMessage);
        setError(parsedError);
        return false;
      })
      .finally(() => setLoading(false));
  };
  // delete event-date

  const deleteDateEvent = (id, date, departments, isAllDepartments) => {
    const deleteEventDetails = { event_id: id, departments, isAllDepartments };
    setLoading(true);
    return deleteExistingDateEvent(deleteEventDetails, token)
      .then((res) => {
        loadDateEventsByDate(date, departments, isAllDepartments);
        // loadHebrewDateEvents(date);
        return true;
      })
      .catch((error) => {
        const parsedError = handleError(error);
        logger.error(error, true);
        // toast.error(parsedError.toastMessage);
        setError(parsedError);
        return false;
      })
      .finally(() => setLoading(false));
  };

  const clearDateEvents = useCallback(() => {
    setDateEvents([]);
  }, []);

  const setAuthToken = (token) => {
    setToken(token);
  };

  return {
    dateEvents,
    futureDateEvents,
    hebrewDates,
    loading,
    error,
    loadDateEventsByDate,
    loadDateEventsByRange,
    loadHebrewDateEvents,
    createDateEvent,
    updateDateEvent,
    deleteDateEvent,
    clearDateEvents,
    setAuthToken,
  };
};

export default useDateEventsManagement;
