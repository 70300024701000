import React from 'react';
import { Helmet } from 'react-helmet';

const Meta = ({ title = 'KTM', description = 'Korentec Time Management' }) => {
  return (
    <Helmet>
      <title>KTM | {title}</title>
      <meta
        name='description'
        content={description}
      />
    </Helmet>
  );
};

export default Meta;
