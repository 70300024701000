import { Input } from "antd";
import React from "react";
import { BsSearch } from "react-icons/bs";

const LocalSearch = ({ style, keyword, setKeyword }) => {
  const handleSearchChange = (e) => {
    e.preventDefault();
    setKeyword(e.target.value);
  };

  return (
    <Input
      type='search'
      allowClear
      style={style}
      suffix={<BsSearch />}
      placeholder='חיפוש לפי שם'
      value={keyword}
      onChange={handleSearchChange}
    />
  );
};

export default LocalSearch;
