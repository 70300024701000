import { Button, Checkbox, Col, Form, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import logger from '../../logger';

const initUserPreferences = {
  activity_id: null,
  project_id: null,
  scope_hours: null,
};

const MIN_HOURS = 7;
const MAX_HOURS = 9;
const STEP = 0.5;

const UserPreferencesForm = ({
  userPreferences,
  isLoading,
  onConfirm,
  projects = [],
  getActivitiesByProject,
}) => {
  const [form] = Form.useForm();
  const [disabledSave, setDisabledSave] = useState(true);
  const [disabledReset, setDisabledReset] = useState(true);
  const [formValues, setFormValues] = useState(initUserPreferences);
  const [selectScopeOptions, setSelectScopeOptions] = useState([]);

  const [activities, setActivities] = useState([]);
  const [projectOptions, setProjectOptions] = useState([]);
  const [activitiesOptions, setActivitiesOptions] = useState([]);
  const [isGlobal, setIsGlobal] = useState(false);

  const createScopeOptions = () => {
    const result = [];
    for (let i = MAX_HOURS; i >= MIN_HOURS; i -= STEP) {
      result.push({ label: `${i} שעות`, value: i });
    }
    return result;
  };

  useEffect(() => {
    setProjectOptions(
      projects.map((project) => ({
        value: project.id,
        label: project.project_name,
      }))
    );
    setActivitiesOptions(
      activities.map((activity) => ({
        value: activity.id,
        label: activity.activity_name,
      }))
    );
  }, [activities, projects]);

  useEffect(() => {
    if (userPreferences) {
      form.setFieldsValue({
        activity_id: userPreferences.favorite_activity?.id,
        project_id: userPreferences.favorite_project?.id,
        scope_hours: userPreferences.scope_hours,
        isGlobal: userPreferences.scope_hours !== null,
      });
      setFormValues({
        activity_id: userPreferences.favorite_activity?.id,
        project_id: userPreferences.favorite_project?.id,
        scope_hours: userPreferences.scope_hours,
        isGlobal: userPreferences.scope_hours !== null,
      });
      setIsGlobal(userPreferences.scope_hours !== null);
    }
    setSelectScopeOptions(createScopeOptions());
    setDisabledSave(true);
    setDisabledReset(true);
  }, [form, userPreferences]);

  useEffect(() => {
    if (formValues.project_id) {
      getActivitiesByProject(formValues.project_id).then((res) => {
        if (res) setActivities(res.data);
      });
    }
  }, [form, formValues.project_id, projects, getActivitiesByProject]);

  const onFormValuesChange = (prop) => {
    setFormValues({ ...formValues, ...prop });
  };

  const handleReset = () => {
    setIsGlobal(userPreferences?.scope_hours !== null);
    setDisabledSave(true);
    setDisabledReset(true);
  };

  const hasFormChanged = () => {
    const formValues = form.getFieldsValue();
    const initialValues = {
      activity_id: userPreferences?.favorite_activity?.id,
      project_id: userPreferences?.favorite_project?.id,
      scope_hours: userPreferences?.scope_hours,
      isGlobal: userPreferences?.scope_hours !== undefined,
    };

    return Object.keys(formValues).some((key) => formValues[key] !== initialValues[key]);
  };

  const onFieldValueChanged = (_) => {
    const isChanged = hasFormChanged();

    if (_[0].name[0] === 'project_id' && _[0].value !== formValues.project_id) {
      form.setFieldValue('activity_id', null);
      setActivities([]);
    }

    setDisabledSave(!isChanged);
    setDisabledReset(!isChanged);
  };

  const handleUpdate = () => {
    form
      .validateFields()
      .then((values) => {
        let userPreferences = {
          favorite_project_id: values.project_id ?? null,
          favorite_activity_id: values.activity_id ?? null,
          scope_hours: isGlobal ? values.scope_hours : null,
        };

        onConfirm(userPreferences).then((res) => {
          if (res) {
            handleReset();
          }
        });
      })
      .catch((info) => {
        logger.info('Validate Failed:', info);
      });
  };

  const handleCheckboxChange = (e) => {
    setIsGlobal(e.target.checked);
  };

  useEffect(() => {
    if (isGlobal) {
      form.setFieldsValue({ scope_hours: userPreferences?.scope_hours ?? null });
    } else {
      form.setFieldsValue({ scope_hours: null });
    }
  }, [isGlobal, form, userPreferences?.scope_hours]);

  return (
    <Form
      form={form}
      layout='vertical'
      name='userPreferencesForm'
      onFinish={handleUpdate}
      initialValues={initUserPreferences}
      onFieldsChange={onFieldValueChanged}
      onValuesChange={onFormValuesChange}
    >
      <Row gutter={10}>
        <Col
          xs={12}
          sm={12}
          md={12}
        >
          <Form.Item
            name='project_id'
            label='פרויקט מועדף'
          >
            <Select
              options={projectOptions}
              placeholder={'בחירת פרויקט'}
              allowClear
            />
          </Form.Item>
        </Col>
        <Col
          xs={12}
          sm={12}
          md={12}
        >
          <Form.Item
            name='activity_id'
            label='פעילות מועדפת'
          >
            <Select
              allowClear
              options={activitiesOptions}
              placeholder={'בחירת פעילות'}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10}>
        <Col
          xs={12}
          sm={12}
          md={12}
        >
          <Form.Item
            name='isGlobal'
            valuePropName='checked'
          >
            <Checkbox onChange={handleCheckboxChange}>תקן גלובלי?</Checkbox>
          </Form.Item>
        </Col>
        {isGlobal && (
          <Col
            xs={12}
            sm={12}
            md={12}
          >
            <Form.Item name='scope_hours'>
              <Select
                options={selectScopeOptions}
                placeholder={'היקף משרה'}
              />
            </Form.Item>
          </Col>
        )}
      </Row>
      <Row
        gutter={10}
        justify={'end'}
      >
        <Col>
          <Button
            type='default'
            disabled={disabledReset}
            onClick={handleReset}
          >
            איפוס
          </Button>
        </Col>
        <Col>
          <Button
            type='primary'
            htmlType='submit'
            disabled={disabledSave}
            loading={isLoading}
          >
            שמירה
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default UserPreferencesForm;
