import axios from 'axios';
import config from '../config';

export const getAllLocations = async (token) => {
  return await axios.get(`${config.API_URL}/locations`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const createNewLocation = async (locationDetails, token) => {
  return await axios.post(`${config.API_URL}/location`, locationDetails, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateExistingLocation = async (locationDetails, id, token) => {
  return await axios.put(`${config.API_URL}/location/${id}`, locationDetails, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
