import { Button, Card, FloatButton, Input, Space, Table, Typography } from 'antd';
import React, { useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { minutesToTimeString, timeStringToMinutes } from '../../utils';
import ReportsTable from '../tables/ReportsTable';
import dayjs from 'dayjs';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

const SummarizedReportList = ({ summarizedReports, loading, sortingOption }) => {
  const [totalTime, setTotalTime] = useState('');
  const [keyLabel, setKeyLabel] = useState('');
  const [reports, setReports] = useState(summarizedReports);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  useEffect(() => {
    switch (sortingOption) {
      case 'byDate':
        setKeyLabel('תאריך');
        break;
      case 'byUser':
        setKeyLabel('שם');
        break;
      case 'byActivity':
        setKeyLabel('פעילות');
        break;
      default:
        setKeyLabel('');
        break;
    }
  }, [sortingOption]);

  const sortKeys = (a, b) => {
    if (
      dayjs(a.sorted_key, 'YYYY-MM-DD', true).isValid() ||
      dayjs(b.sorted_key, 'YYYY-MM-DD', true).isValid()
    )
      return dayjs(a.sorted_key, 'DD/MM/YYYY').unix() - dayjs(b.sorted_key, 'DD/MM/YYYY').unix();

    return a.sorted_key.localeCompare(b.sorted_key);
  };
  const getColumnSearchProps = (dataIndex, title = '') => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`חיפוש לפי ${title}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{
              width: 90,
            }}
          >
            חיפוש
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size='small'
            style={{
              width: 90,
            }}
          >
            איפוס
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  useEffect(() => {
    if (summarizedReports?.length > 0) {
      const mappedSummerizedReports = summarizedReports.map((report) => {
        const key = dayjs(report.key, 'YYYY-MM-DD', true).isValid()
          ? dayjs(report.key).format('DD/MM/YYYY')
          : report.key;
        return { sorted_key: key, ...report };
      });
      setReports(mappedSummerizedReports);
      // Calculate total minutes
      const totalMinutes = mappedSummerizedReports.reduce(
        (acc, item) => acc + timeStringToMinutes(item.totalDuration),
        0
      );
      // Convert total minutes back to time string
      const totalTimeString = minutesToTimeString(totalMinutes);
      setTotalTime(totalTimeString);
    } else {
      setTotalTime('');
      setKeyLabel('');
    }
  }, [summarizedReports]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    setSearchText('');
    confirm();
  };

  const showSummary = () => (
    <Table.Summary fixed>
      <Table.Summary.Row>
        <Table.Summary.Cell
          index={0}
          align='end'
        >
          <Typography.Text strong>סה"כ:</Typography.Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={1}>
          <Typography.Text strong> {totalTime}</Typography.Text>
        </Table.Summary.Cell>
      </Table.Summary.Row>
    </Table.Summary>
  );

  const columns = [
    {
      title: keyLabel,
      dataIndex: 'sorted_key',
      // width: '40%',
      key: 'sorted_key',
      sorter: sortKeys,
      ...getColumnSearchProps('sorted_key', keyLabel),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'סך חלקי',
      dataIndex: 'totalDuration',
      key: 'totalDuration',
      sorter: (a, b) => {
        return dayjs(a.totalDuration, 'H:MM').unix() - dayjs(b.totalDuration, 'H:MM').unix();
      },
    },
  ];

  return (
    <>
      <FloatButton.Group
        style={{
          zIndex: 1001,
        }}
      >
        <FloatButton.BackTop
          type='primary'
          target={() => document.getElementById('reports-layout')}
        />
      </FloatButton.Group>
      <Card
        className='w-50'
        title='רשימה מצומצמת'
        styles={{
          body: { padding: 0, paddingTop: 1 },
          head: { padding: 10 },
        }}
      >
        <ReportsTable
          onSummary={showSummary}
          columns={columns}
          data={reports}
          loading={loading.read}
        />
      </Card>
    </>
  );
};

export default SummarizedReportList;
