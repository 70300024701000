import axios from 'axios';
import config from '../config';

export const getAllUsers = async (token) => {
  return await axios.get(`${config.API_URL}/users`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const fetchUsersByDepartmentIds = async (departments, token) => {
  return await axios.post(
    `${config.API_URL}/users/departments`,
    { departments },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const getAllUsersByProjects = async (data, token) => {
  return await axios.post(`${config.API_URL}/users/project`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const createNewUser = async (userDetails, token) => {
  return await axios.post(`${config.API_URL}/register`, userDetails, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateExistingUser = async (userDetails, id, token) => {
  // const parsedUser = parseUserDetails(userDetails);
  return await axios.put(`${config.API_URL}/user/${id}`, userDetails, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const assignProjectsToUser = async (projectDetails, id, token) => {
  return await axios.put(`${config.API_URL}/user/${id}/projects`, projectDetails, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getUserReportPermission = async (token) => {
  return await axios.get(`${config.API_URL}/user/report-permission`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// update user personal details
export const getUserDetails = async (token) => {
  return await axios.get(`${config.API_URL}/user/details`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateUserDetails = async (token, userDetails) => {
  return await axios.put(`${config.API_URL}/user/details`, userDetails, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getUserPreferences = async (token) => {
  return await axios.get(`${config.API_URL}/user/preferences`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateUserPreferences = async (token, userPreferences) => {
  return await axios.put(`${config.API_URL}/user/preferences`, userPreferences, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
