import { Font, StyleSheet } from '@react-pdf/renderer';

// Register fonts
Font.register({
  family: 'Rubik',
  fonts: [
    { src: 'https://fonts.gstatic.com/s/rubik/v28/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-WYi1UA.ttf', fontWeight: 300 }, // Regular
    { src: 'https://fonts.gstatic.com/s/rubik/v28/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-NYi1UA.ttf', fontWeight: 500 }, // Medium
    { src: 'https://fonts.gstatic.com/s/rubik/v28/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-4I-1UA.ttf', fontWeight: 700 }, // Bold
  ],
});

// Styles
export const reportStyles = StyleSheet.create({
  page: {
    padding: 30,
    fontFamily: 'Rubik',
  },
  header: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 10,
    direction: 'rtl',
    fontWeight: '500', // Ant Design Medium font weight
    color: '#262626', // Ant Design default text color
  },
  subHeader: {
    fontSize: 10,
    textAlign: 'center',
    marginBottom: 10,
    direction: 'rtl',
    color: '#262626', // Ant Design default text color
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#f0f0f0', // Light grey border similar to Ant Design
    marginBottom: 20,
  },
  tableRow: {
    flexDirection: 'row-reverse', // Set the flex direction to row-reverse
  },
  tableColHeader: {
    width: '14%',
    textAlign: 'center',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#f0f0f0', // Light grey border similar to Ant Design
    backgroundColor: '#fafafa', // Light background similar to Ant Design
    padding: 4,
  },
  tableColSummarizedHeader: {
    width: '50%',
    textAlign: 'right',
    direction: 'rtl',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#f0f0f0', // Light grey border similar to Ant Design
    backgroundColor: '#fafafa', // Light background similar to Ant Design
  },
  tableCol: {
    width: '14%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#f0f0f0', // Light grey border similar to Ant Design
    padding: 8,
  },
  tableColSummarized: {
    width: '100%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#f0f0f0', // Light grey border similar to Ant Design
    padding: 8,
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 10,
    fontWeight: 'bold',
    direction: 'rtl',
  },
  tableCell: {
    fontSize: 8,
    direction: 'rtl',
  },
  tableCellSummarized: {
    width: '100%',
    fontSize: 8,
    textAlign: 'right',
    direction: 'rtl',
    fontWeight: '500', // Ant Design Medium font weight
  },
});
