import { EditOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Space, Table, Input, Typography } from 'antd';
import React, { useRef, useState } from 'react';
import { MdOutlineManageAccounts } from 'react-icons/md';
import TooltipButton from '../../components/buttons/TooltipButton';
import Highlighter from 'react-highlight-words';

const { Text } = Typography;

const RoleTable = ({ roles, loading, handleEditRole, handleToggleModal }) => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const handleRenderPermissionList = (role) => {
    if (!role || !role.permissions || role.permissions.length === 0) {
      return '';
    }

    const permissions = role.permissions.map((permission) => permission.label);
    const renderedResult = permissions.join(' | ');

    return renderedResult;
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    setSearchText('');
    confirm();
  };

  const getColumnSearchProps = (dataIndex, title = '') => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`חיפוש לפי ${title}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{
              width: 90,
            }}
          >
            חיפוש
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size='small'
            style={{
              width: 90,
            }}
          >
            איפוס
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '5%',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'תפקיד',
      dataIndex: 'role_name',
      key: 'role_name',
      ...getColumnSearchProps('role_name', 'שם תפקיד'),
      sorter: (a, b) => a.role_name.localeCompare(b.role_name),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'הרשאות פעילות',
      dataIndex: 'permissions',
      key: 'permissions',
      render: (_, record) => (
        <Space size='middle'>
          <Text>{handleRenderPermissionList(record)}</Text>
        </Space>
      ),
    },
    {
      title: 'פעולות',
      key: 'actions',
      width: '10%',
      render: (_, record) => (
        <Space size='middle'>
          <TooltipButton
            placement='top'
            type='text'
            title='ערוך תפקיד'
            icon={<EditOutlined />}
            onClick={() => handleEditRole(record)}
          ></TooltipButton>
        </Space>
      ),
    },
  ];

  return (
    <Table
      title={() => (
        <div>
          <Button
            type='primary'
            shape='square'
            onClick={handleToggleModal}
            icon={<MdOutlineManageAccounts />}
          >
            הוספה
          </Button>
        </div>
      )}
      columns={columns}
      dataSource={roles}
      loading={loading}
      scroll={{ x: 500 }}
      pagination={{
        pageSize: 8,
        hideOnSinglePage: true,
        position: ['bottomCenter'],
      }}
    />
  );
};

export default RoleTable;
