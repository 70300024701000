import axios from 'axios';
import config from '../config';

export const getAllPermissions = async (token) => {
  return await axios.get(`${config.API_URL}/permissions`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const createNewPermission = async (permissionDetails, token) => {
  return await axios.post(`${config.API_URL}/permission`, permissionDetails, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateExistingPermission = async (permissionDetails, id, token) => {
  return await axios.put(`${config.API_URL}/permission/${id}`, permissionDetails, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
