import { Modal } from 'antd';

const CustomModal = ({
  children,
  isModalOpen,
  modalTitle = null,
  handleCancel,
  width = '',
  footer = null,
}) => {
  return (
    <Modal
      title={modalTitle}
      okText='אשר'
      cancelText='בטל'
      open={isModalOpen}
      onCancel={handleCancel}
      width={width}
      footer={footer}
    >
      {children}
    </Modal>
  );
};

export default CustomModal;
