import { useCallback, useState } from 'react';
import {
  createNewTimeReport,
  deleteReportById,
  getAllReportsByUser,
  getClockTimeReportByDate,
  getMissingReportsByRange,
  getDepartmentReports,
  getReportsByRange,
  updateExistingTimeReport,
} from '../services/timeReport';
import { useDispatch } from 'react-redux';
import { loadReports } from '../actions/timeReportActions';
import logger from '../logger';
import { handleError } from '../utils/handleErrors';

const initLoading = {
  read: false,
  update: false,
  delete: false,
};

const useTimeManagement = (initialToken) => {
  const dispatch = useDispatch();
  const [timeReports, setTimeReports] = useState([]);
  const [loading, setLoading] = useState(initLoading);
  const [error, setError] = useState(null);
  const [token, setToken] = useState(initialToken);

  // load all logged in user reports
  const loadUserReports = useCallback(
    (date) => {
      setLoading((state) => ({ ...state, read: true }));
      return getAllReportsByUser(token, date)
        .then((res) => {
          setTimeReports(res.data);
          dispatch(loadReports(res.data));
          return true;
        })
        .catch((error) => {
          const parsedError = handleError(error);
          logger.error(error);
          // toast.error(parsedError.toastMessage);
          setError(parsedError);
          return false;
        })
        .finally(() => setLoading((state) => ({ ...state, read: false })));
    },
    [dispatch, token]
  );

  const loadReportByUsersAndDateRange = (queryParams) => {
    setLoading((state) => ({ ...state, read: true }));
    return getReportsByRange(queryParams, token)
      .then((res) => {
        // loadUserReports();
        return res;
      })
      .catch((error) => {
        const parsedError = handleError(error);
        logger.error(error, true);
        // toast.error(parsedError.toastMessage);
        setError(parsedError);
        return false;
      })
      .finally(() => setLoading((state) => ({ ...state, read: false })));
  };

  const loadMissingReportsByQueryObject = (queryParams) => {
    setLoading((state) => ({ ...state, read: true }));
    return getMissingReportsByRange(queryParams, token)
      .then((res) => {
        // loadUserReports();
        return res;
      })
      .catch((error) => {
        const parsedError = handleError(error);
        logger.error(error, true);
        // toast.error(parsedError.toastMessage);
        setError(parsedError);
        return false;
      })
      .finally(() => setLoading((state) => ({ ...state, read: false })));
  };

  const loadDepartmentReportsByQueryObject = (queryParams) => {
    setLoading((state) => ({ ...state, read: true }));
    return getDepartmentReports(queryParams, token)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        const parsedError = handleError(error);
        logger.error(error, true);
        // toast.error(parsedError.toastMessage);
        setError(parsedError);
        return false;
      })
      .finally(() => setLoading((state) => ({ ...state, read: false })));
  };

  const createTimeReport = (data) => {
    setLoading((state) => ({ ...state, update: true }));
    return createNewTimeReport(data, token)
      .then(() => {
        // loadUserReports();
        return true;
      })
      .catch((error) => {
        const parsedError = handleError(error);
        logger.error(error, true);
        // toast.error(parsedError.toastMessage);
        setError(parsedError);
        return false;
      })
      .finally(() => setLoading((state) => ({ ...state, update: false })));
  };

  const updateTimeReport = (data) => {
    const { id, ...restData } = data;
    setLoading((state) => ({ ...state, update: true }));

    return updateExistingTimeReport(restData, id, token)
      .then(() => {
        // loadUserReports();
        return true;
      })
      .catch((error) => {
        const parsedError = handleError(error);
        logger.error(error, true);
        // toast.error(parsedError.toastMessage);
        setError(parsedError);
        return false;
      })
      .finally(() => setLoading((state) => ({ ...state, update: false })));
  };

  const deleteTimeReport = (reportId) => {
    setLoading({ ...loading, delete: true });

    return deleteReportById(reportId, token)
      .then(() => {
        // loadUserReports();
        return true;
      })
      .catch((error) => {
        const parsedError = handleError(error);
        logger.error(error, true);
        // toast.error(parsedError.toastMessage);
        setError(parsedError);
        return false;
      })
      .finally(() => setLoading({ ...loading, delete: false })); // tbd remove
  };

  const timeReportFromClock = (date, attId) => {
    const requestedReport = { att_id: attId, current_date: date };
    return getClockTimeReportByDate(requestedReport, token)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {});
  };

  const setAuthToken = (token) => {
    setToken(token);
  };

  return {
    timeReports,
    loading,
    error,
    loadUserReports,
    loadReportByUsersAndDateRange,
    loadMissingReportsByQueryObject,
    loadDepartmentReportsByQueryObject,
    createTimeReport, // TBD continue
    updateTimeReport,
    deleteTimeReport,
    timeReportFromClock,
    setAuthToken,
  };
};

export default useTimeManagement;
