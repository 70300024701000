import './App.css';
import { useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
import { currentUser } from './services/auth';
import { ToastContainer } from 'react-toastify';
import { FloatButton, Layout, Typography } from 'antd';
import { useMediaQuery } from 'react-responsive';
import 'react-toastify/dist/ReactToastify.css';
import 'dayjs/locale/he';

// Components
import Administration from './pages/administration/Administration';
import Login from './pages/login/Login';
import Time from './pages/time/Time.js';
import HeaderNav from './components/nav/HeaderNav';
import dayjs from 'dayjs';
// import Home from './pages/Home/Home'; // TBD until we have dashboard
import ReportManagement from './pages/reports/ReportManagement';
import AdminRoute from './routes/AdminRoute';

import UserDetails from './pages/user/UserDetails';
import { setAuthLoading, setUser } from './actions/authActions';
import { setDesktop, setMobile } from './actions';
import UserRoute from './routes/UserRoute';
import ShowLoading from './components/ShowLoading';
import logger from './logger';
import config from './config';

const { Header, Footer } = Layout;
dayjs.locale('he'); // set locale to Hebrew

function App() {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth);
  const { VERSION } = config;

  useLayoutEffect(() => {
    document.body.setAttribute('dir', 'rtl');
  }, []);

  useEffect(() => {
    dispatch(setAuthLoading(true));
    const token = localStorage.getItem('access_token');
    if (token) {
      currentUser(token)
        .then((res) => {
          dispatch(setUser({ user: res.data, token: token }));
        })
        .catch((error) => {
          logger.error(error);
        })
        .finally(() => {
          dispatch(setAuthLoading(false));
        });
    } else {
      dispatch(setAuthLoading(false));
    }
  }, [dispatch]);

  const isMobile = useMediaQuery({
    query: '(max-width: 1224px)',
  });

  useEffect(() => {
    dispatch(isMobile ? setMobile() : setDesktop());
    console.log(config.ENV);
  }, [dispatch, isMobile]);

  if (loading) {
    return <ShowLoading />;
  }

  return (
    <Layout
      className='site-layout'
      style={{ height: '100vh', overflowY: 'hidden' }}
    >
      <FloatButton.BackTop />
      <Header
        className='p-0'
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 1000,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          height: 60,
        }}
      >
        <HeaderNav />
      </Header>
      <ToastContainer
        rtl
        position='top-left'
        newestOnTop
        stacked
        closeOnClick
      />
      <Layout>
        <Routes>
          <Route
            path='/login'
            element={<Login />}
          />
          <Route
            exact
            path='/'
            element={<UserRoute Component={Time} />}
          />
          <Route
            exact
            path='/time'
            element={<UserRoute Component={Time} />}
          />
          <Route
            exact
            path='/reportManagement'
            element={<UserRoute Component={ReportManagement} />}
          />
          <Route
            exact
            path='/user/details'
            element={<UserRoute Component={UserDetails} />}
          />
          <Route
            exact
            path='/administration'
            element={<AdminRoute Component={Administration} />}
          />
          <Route
            exact
            path='/administration/accounts'
            element={<AdminRoute Component={Administration} />}
          />
          <Route
            exact
            path='/administration/departments'
            element={<AdminRoute Component={Administration} />}
          />
          <Route
            exact
            path='/administration/users'
            element={<AdminRoute Component={Administration} />}
          />
          <Route
            exact
            path='/administration/projects'
            element={<AdminRoute Component={Administration} />}
          />
          <Route
            exact
            path='/administration/activities'
            element={<AdminRoute Component={Administration} />}
          />
          <Route
            exact
            path='/administration/reports'
            element={<AdminRoute Component={Administration} />}
          />
          <Route
            exact
            path='/administration/locations'
            element={<AdminRoute Component={Administration} />}
          />
          <Route
            exact
            path='/administration/roles'
            element={<AdminRoute Component={Administration} />}
          />
          <Route
            exact
            path='/administration/permissions'
            element={<AdminRoute Component={Administration} />}
          />
          <Route
            exact
            path='/administration/date-events'
            element={<AdminRoute Component={Administration} />}
          />
        </Routes>
      </Layout>
      <Footer style={{ textAlign: 'center' }}>
        <Typography.Text>Korentec Time Management ©{dayjs().year()}</Typography.Text>
        {isMobile ? <br /> : ' | '}
        <Typography.Text>
          {config?.ENV === 'development' ? '[DEV]' : config?.ENV === 'test' ? '[PPE]' : ''} v{VERSION}
        </Typography.Text>
      </Footer>
    </Layout>
  );
}

export default App;
