import { Button, Col, Form, Input, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import logger from '../../logger';

const initLocationState = {
  location_name: '',
};

const LocationForm = ({ locationDetails = null, isLoading, onConfirm, name = 'locationForm' }) => {
  const isLocationAvailable = locationDetails !== null;
  const [form] = Form.useForm();
  const [disabledSave, setDisabledSave] = useState(true);
  const [disabledReset, setDisabledReset] = useState(true);

  useEffect(() => {
    if (isLocationAvailable) {
      form.setFieldsValue({
        location_name: locationDetails.location_name,
      });
      setDisabledSave(false);
      setDisabledReset(true);
    } else {
      form.resetFields();
      setDisabledSave(true);
      setDisabledReset(true);
    }
  }, [form, isLocationAvailable, locationDetails]);

  const handleReset = () => {
    if (isLocationAvailable) {
      form.setFieldsValue(locationDetails);
    } else {
      form.resetFields();
      setDisabledSave(true);
    }
    setDisabledReset(true);
  };

  const onFieldValueChanged = (_, allFields) => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    const isFilled = allValuesFilled();
    setDisabledSave(hasErrors || !isFilled);
    setDisabledReset(!isFilled && isLocationAvailable);
  };

  const allValuesFilled = () => {
    const formValues = form.getFieldsValue();
    const { location_name } = formValues;
    return location_name !== undefined;
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        onConfirm({
          id: locationDetails?.id,
          location_name: values.location_name,
        }).then((res) => {
          //TODO:if confirmation not succeeded than do not reset the fields
          if (res) {
            form.resetFields();
            setDisabledSave(true);
            setDisabledReset(true);
          }
        });
      })
      .catch((info) => {
        logger.info('Validate Failed:', info);
      });
  };

  return (
    <Form
      form={form}
      layout='vertical'
      name={name}
      onFinish={handleOk}
      initialValues={initLocationState}
      onFieldsChange={onFieldValueChanged}
    >
      <Row gutter={24}>
        <Col>
          <Form.Item
            name='location_name'
            label='שם המיקום'
            rules={[
              {
                min: 3,
                message: 'מינמום 3 תווים',
              },
              {
                required: true,
                message: 'נא להזין שם המיקום!',
              },
            ]}
          >
            <Input autoComplete='off' />
          </Form.Item>
        </Col>
      </Row>
      <Row
        gutter={10}
        justify={'start'}
      >
        <Col>
          <Button
            type='default'
            disabled={disabledReset}
            onClick={handleReset}
          >
            {isLocationAvailable ? 'איפוס' : 'ניקוי'}
          </Button>
        </Col>
        <Col>
          <Button
            type='primary'
            htmlType='submit'
            disabled={disabledSave}
            loading={isLoading}
          >
            אישור
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default LocationForm;
