import { Button, Col, Form, Input, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import logger from '../../logger';

const initRoleState = {
  role_name: '',
  permissions: [],
};

const RoleForm = ({ roleDetails = null, isLoading, onConfirm, permissions }) => {
  const isRoleAvailable = roleDetails !== null;
  const [form] = Form.useForm();
  const [disabledSave, setDisabledSave] = useState(true);
  const [disabledReset, setDisabledReset] = useState(true);
  const [permissionOptions, setPermissionOptions] = useState([]);

  useEffect(() => {
    if (isRoleAvailable) {
      form.setFieldsValue({
        role_name: roleDetails.role_name,
        permissions: roleDetails.permissions.map((permission) => permission.id),
      });
      setDisabledSave(false);
      setDisabledReset(true);
    } else {
      form.resetFields();
      setDisabledSave(true);
      setDisabledReset(true);
    }
    if (permissions)
      setPermissionOptions(
        permissions.map((permission) => ({ value: permission.id, label: permission.label }))
      );
  }, [form, isRoleAvailable, permissions, roleDetails]);

  const handleReset = () => {
    if (isRoleAvailable) {
      form.setFieldsValue(roleDetails);
    } else {
      form.resetFields();
      setDisabledSave(true);
    }
    setDisabledReset(true);
  };

  const onFieldValueChanged = (_, allFields) => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    const isFilled = allValuesFilled();
    setDisabledSave(hasErrors || !isFilled);
    setDisabledReset(!isFilled && isRoleAvailable);
  };

  const allValuesFilled = () => {
    const formValues = form.getFieldsValue();
    const { role_name, permissions } = formValues;
    return role_name !== undefined && permissions?.length > 0;
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        onConfirm({
          id: roleDetails?.id,
          role_name: values.role_name,
          permissions: values.permissions,
        }).then((res) => {
          //TODO:if confirmation not succeeded than do not reset the fields
          if (res) {
            form.resetFields();
            setDisabledSave(true);
            setDisabledReset(true);
          }
        });
      })
      .catch((info) => {
        logger.info('Validate Failed:', info);
      });
  };

  return (
    <Form
      form={form}
      layout='vertical'
      name='roleForm'
      onFinish={handleOk}
      initialValues={initRoleState}
      onFieldsChange={onFieldValueChanged}
    >
      <Row>
        <Col>
          <Form.Item
            name='role_name'
            label='שם התפקיד'
            rules={[
              {
                min: 3,
                message: 'מינמום 3 תווים',
              },
              {
                required: true,
                message: 'נא להזין שם התפקיד!',
              },
            ]}
          >
            <Input autoComplete='off' />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            name='permissions'
            label='בחר הרשאות עבור התפקיד'
            rules={[{ required: true, message: 'נא לבחור הראשה אחת לפחות' }]}
          >
            <Select
              mode='multiple'
              allowClear
              options={permissionOptions}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row
        gutter={10}
        justify={'start'}
      >
        <Col>
          <Button
            type='default'
            disabled={disabledReset}
            onClick={handleReset}
          >
            {isRoleAvailable ? 'איפוס' : 'ניקוי'}
          </Button>
        </Col>
        <Col>
          <Button
            type='primary'
            htmlType='submit'
            disabled={disabledSave}
            loading={isLoading}
          >
            אישור
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default RoleForm;
