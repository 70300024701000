import { Select, DatePicker, Form, Button, Row, Col, Switch, Flex } from 'antd';
import React, { useEffect, useState } from 'react';
import { getAuthState } from '../../utils';
import { useSelector } from 'react-redux';
import useUserManagement from '../../hooks/useUserManagement';
const initMissingReportsState = {
  users: [],
  startDate: undefined,
  endDate: undefined,
  isByGroup: false,
};

const MissingReportForm = ({ requiredMissingReports, loading }) => {
  const { user, token } = useSelector((state) => getAuthState(state));
  const [form] = Form.useForm();
  // const [disabledFetch, setDisableFetch] = useState(true);
  const [disabledReset, setDisabledReset] = useState(true);
  const [users, setUsers] = useState([]);
  const [isUsersDisabled, setIsUserDisabled] = useState(false);
  const { users: usersList, loading: userLoading } = useUserManagement(token);

  useEffect(() => {
    if (usersList) {
      //Hide the admin user from the list
      setUsers(
        usersList
          .filter((u) => u.id !== 1)
          .sort((a, b) => `${a.first_name} ${a.last_name}`.localeCompare(`${b.first_name} ${b.last_name}`))
      );
    }
  }, [user, usersList]);

  const handleFinish = () => {
    form.validateFields().then((values) => {
      requiredMissingReports(values);
      // .then((res) => {
      //   if (res) {
      //     setDisableFetch(res);
      //   }
      // });
    });
  };

  const handleReset = () => {
    form.resetFields();
    // setDisableFetch(true);
    setDisabledReset(true);
  };

  const onFieldValueChanged = (field) => {
    // const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    const isFilled = allValuesFilled();
    // setDisableFetch(hasErrors || !isFilled);
    setDisabledReset(!isFilled);
  };

  const allValuesFilled = () => {
    const formValues = form.getFieldsValue();
    const { users, startDate, endDate, isByGroup } = formValues;
    return (isByGroup || users?.length > 0) && startDate !== undefined && endDate !== undefined;
  };

  return (
    <>
      <Flex
        vertical
        gap='middle'
      >
        <Form
          form={form}
          name='requestMissingReportsForm'
          onFinish={handleFinish}
          initialValues={initMissingReportsState}
          onFieldsChange={onFieldValueChanged}
        >
          <Row gutter={12}>
            <Col span={18}>
              <Form.Item name='users'>
                <Select
                  allowClear
                  showSearch
                  disabled={isUsersDisabled}
                  filterOption={(input, option) => (option?.label ?? '').includes(input)}
                  mode='multiple'
                  placeholder='בחר משתמשים'
                  loading={userLoading}
                  // value={selectedUsers}
                  options={users.map((user) => ({
                    value: user.id,
                    label: `${user.first_name} ${user.last_name} (${user.department.department_name})`,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name='isByGroup'
                valuePropName='checked'
              >
                <Switch
                  onChange={(checked) => {
                    form.setFieldsValue({ isByGroup: checked });
                    setIsUserDisabled(checked);
                    onFieldValueChanged('isByGroup');
                  }}
                  checkedChildren='כולם'
                  unCheckedChildren='נבחרים'
                />
              </Form.Item>
            </Col>
          </Row>
          <Row
            gutter={12}
            justify={'space-between'}
          >
            <Col span={12}>
              <Form.Item
                name='startDate'
                rules={[
                  {
                    required: true,
                    message: 'נא לבחור תאריך התחלה',
                  },
                ]}
              >
                <DatePicker
                  placeholder='תאריך התחלה'
                  className='w-100'
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='endDate'
                rules={[
                  {
                    required: true,
                    message: 'נא לבחור תאריך סיום',
                  },
                ]}
              >
                <DatePicker
                  placeholder='תאריך סיום'
                  className='w-100'
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col>
              <Button
                type='default'
                disabled={disabledReset}
                onClick={handleReset}
              >
                ניקוי
              </Button>
            </Col>
            <Col>
              <Button
                type='primary'
                htmlType='submit'
                // disabled={disabledFetch}
                loading={loading.read}
              >
                הבא דיווחים
              </Button>
            </Col>
          </Row>
        </Form>
      </Flex>
    </>
  );
};

export default MissingReportForm;
