import { Button, Popconfirm, Popover, Space, Table, Tooltip, Typography } from 'antd';
import React, { useEffect, useState, useRef } from 'react';
import { CopyOutlined, DeleteOutlined, EditOutlined, PaperClipOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { calculateDiffTime } from '../../utils';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);
dayjs.extend(relativeTime);

const DesktopReportItem = ({
  timeReports,
  handleDownload,
  handleEditTimeReport,
  handleDeleteTimeReport,
  handleDuplicateTimeReport,
  loading,
  sendCols,
}) => {
  const [data, setData] = useState([]);
  const tableRef = useRef(null);

  useEffect(() => {
    setData(timeReports.map((reports, index) => ({ ...reports, key: index })));
  }, [timeReports]);

  const parseComments = (comments) => {
    // Split the string into an array of rows
    const rows = comments.split('\n');

    // Map over the array to generate JSX elements for each row
    const content = rows.map((row, index) => (
      <p
        key={index}
        className='m-0'
      >
        {row}
      </p>
    ));
    return content;
  };

  const handleShowAttachedFile = async (item) => {
    // Show/Download file
    await handleDownload(item.file_id);
  };

  const columns = [
    {
      title: 'פרויקט',
      dataIndex: 'project',
      align: 'center',
      key: 'project',
      width: '15%',
      render: (project, record) => {
        return <Typography.Text strong={record.id === 0}>{project.project_name}</Typography.Text>;
      },
    },
    {
      title: 'פעילות',
      dataIndex: 'activity',
      key: 'activity',
      align: 'center',
      render: (_, record) => {
        return (
          <>
            {record.activity.activity_name}
            {record?.file_id && (
              <Tooltip
                title='הצג קובץ מצורף'
                placement='top'
              >
                <Button
                  type='text'
                  icon={<PaperClipOutlined />}
                  onClick={() => handleShowAttachedFile(record)}
                />
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      title: 'מיקום',
      dataIndex: 'location',
      key: 'location',
      width: '10%',
      align: 'center',
      render: (location) => {
        return location.location_name;
      },
    },
    {
      title: 'התחלה',
      dataIndex: 'in_time',
      key: 'in_time',
      width: '10%',
      align: 'center',
      render: (in_time, record) => {
        if (record.id === 0) return '-';
        else return dayjs(in_time).isValid() ? dayjs(in_time).format('HH:mm') : '-';
      },
    },
    {
      title: 'סיום',
      dataIndex: 'out_time',
      key: 'out_time',
      width: '10%',
      align: 'center',
      render: (out_time, record) => {
        if (record.id === 0) return '-';
        else return dayjs(out_time).isValid() ? dayjs(out_time).format('HH:mm') : '-';
      },
    },
    {
      title: 'משך זמן',
      dataIndex: 'out_time',
      key: 'out_time',
      width: '10%',
      align: 'center',
      render: (_, record) => {
        if (record.id === 0) return '-';
        const diff = calculateDiffTime(record.in_time, record.out_time);
        return dayjs(diff).isValid() ? dayjs(diff).format('HH:mm') : '';
      },
    },
    {
      title: 'הערות',
      dataIndex: 'comments',
      key: 'comments',
      align: 'center',
      ellipsis: {
        showTitle: false,
      },
      render: (comments) => {
        return (
          <Popover
            overlayStyle={{
              maxWidth: '15vw',
            }}
            zIndex={40}
            className='pointer'
            content={parseComments(comments)}
            trigger='click'
            placement='rightBottom'
          >
            <Typography.Text style={{ cursor: 'pointer' }}>{comments}</Typography.Text>
          </Popover>
        );
      },
    },
    {
      title: 'פעולות',
      key: 'actions',
      width: '18%',
      align: 'center',
      render: (_, record) =>
        _.id !== 0 ? (
          <Space size='middle'>
            <Tooltip
              title={'ערוך דיווח'}
              placement='top'
            >
              <Button
                type='text'
                icon={<EditOutlined />}
                onClick={() => handleEditTimeReport(record)}
              />
            </Tooltip>
            <Tooltip
              title={'שכפול דיווח'}
              placement='top'
            >
              <Button
                type='text'
                icon={<CopyOutlined className='text-primary' />}
                onClick={() => handleDuplicateTimeReport(record)}
              />
            </Tooltip>
            <Popconfirm
              title='מחיקה'
              description='האם למחוק דיווח?'
              placement='topLeft'
              onConfirm={() => handleDeleteTimeReport(record)}
              okButtonProps={{
                loading: loading.delete,
              }}
            >
              <Button
                type='text'
                icon={<DeleteOutlined className='text-danger' />}
              />
            </Popconfirm>
          </Space>
        ) : (
          record.details
        ),
    },
  ];

  return (
    <Table
      bordered={false}
      size='middle'
      columns={columns}
      dataSource={data}
      loading={loading.item} // on edit or delete
      scroll={{ x: 600 }}
      pagination={{
        pageSize: 8,
        hideOnSinglePage: true,
        position: ['bottomCenter'],
      }}
      ref={tableRef}
    />
  );
};

export default DesktopReportItem;
