import React, { useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import Title from 'antd/es/typography/Title';
import { toast } from 'react-toastify';
import UsersTable from '../tables/UsersTable';
import useUserManagement from '../../hooks/useUserManagement.js';
import useAccountManagement from '../../hooks/useAccountManagement.js';
import { getAuthState } from '../../utils';
import UserForm from '../forms/UserForm';
import CustomModal from '../modals/CustomModal';
import { manageUsersReducer } from '../../utils/reducers';
import { CREATE_USER, UPDATE_USER, UPDATE_USER_PROJECTS } from '../../actions/types';
import useDepartmentManagement from '../../hooks/useDepartmentManagement';
import ManageUserProjects from './ManageUserProjects';
import useRoleManagement from '../../hooks/useRoleManagement';
import useProjectManagement from '../../hooks/useProjectManagement';
import authWrapper from '../../utils/authWrapper';
import Meta from '../Meta';

const initUserAction = {
  title: '',
  manage: null,
  user: null,
  editProjects: false,
};

const ManageUsers = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { token } = useSelector((state) => getAuthState(state));
  const [userManagement, dispatch] = useReducer(manageUsersReducer, initUserAction);

  const { users, loading, createUser, updateUser, assignProjectsUser } = useUserManagement(token);
  const { accounts, loadAccounts } = useAccountManagement(token);
  const { departments } = useDepartmentManagement(token);
  const { roles } = useRoleManagement(token);
  const { projects, loadProjects } = useProjectManagement(token);

  const handleEditUser = (user) => {
    dispatch({ type: UPDATE_USER, payload: { manage: handleUpdate, user } });
    handleToggleModal();
  };

  const handleEditProjects = (user) => {
    dispatch({ type: UPDATE_USER_PROJECTS, payload: { manage: handleAssignProjects, user } });
    handleToggleModal();
  };
  const handleCreateUser = () => {
    dispatch({ type: CREATE_USER, payload: { manage: handleCreate } });
    handleToggleModal();
  };

  const handleCreate = (data) => {
    return createUser(data).then((success) => {
      if (success) {
        toast.success('המשתמש התווסף בהצלחה');
        handleToggleModal();
        return true;
      } else {
        return false;
      }
    });
  };

  const handleUpdate = (data) => {
    return updateUser(data).then((success) => {
      if (success) {
        toast.success('עדכון המשתמש התבצע בהצלחה!');
        handleToggleModal();
        return true;
      } else {
        return false;
      }
    });
  };

  const handleAssignProjects = (data) => {
    return assignProjectsUser(data).then((success) => {
      if (success) {
        toast.success('עדכון השמת הפרויקטים למשתמש הסתיים בהצלחה');
        handleToggleModal();
        return true;
      } else {
        return false;
      }
    });
  };

  const handleToggleModal = () => {
    setIsModalOpen((state) => !state);
  };

  const handleCancelModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    loadAccounts();
  }, [loadAccounts]);

  useEffect(() => {
    loadProjects();
  }, [loadProjects]);

  return (
    <div className='container m-2'>
      <Meta
        title={'ניהול משתמשים'}
        description={'מסך ניהול משתמשים'}
      />
      <Title
        level={1}
        className='text-center'
      >
        ניהול משתמשים
      </Title>
      <CustomModal
        modalTitle={userManagement.title}
        isModalOpen={isModalOpen}
        handleCancel={handleCancelModal}
        width={!userManagement.editProjects ? 'fit-content' : '800px'}
      >
        {!userManagement.editProjects ? (
          <UserForm
            userDetails={userManagement.user}
            isLoading={loading}
            onConfirm={userManagement.manage}
            departments={departments}
            accounts={accounts}
            roles={roles}
          />
        ) : (
          <ManageUserProjects
            userDetails={userManagement.user}
            isLoading={loading}
            projects={projects}
            onConfirm={userManagement.manage}
          />
        )}
      </CustomModal>
      <UsersTable
        users={users.map((user) => ({ ...user, key: user.id }))}
        departments={departments}
        accounts={accounts}
        loading={loading}
        handleCreateUser={handleCreateUser}
        handleEditUser={handleEditUser}
        handleEditProjects={handleEditProjects}
        roles={roles}
      />
    </div>
  );
};

export default authWrapper('users')(ManageUsers);
