import axios from 'axios';
import config from '../config';

export const getAllDepartments = async (token) => {
  return await axios.get(`${config.API_URL}/departments`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const createNewDepartment = async (departmentDetails, token) => {
  return await axios.post(`${config.API_URL}/department`, departmentDetails, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateExistingDepartment = async (departmentDetails, id, token) => {
  return await axios.put(`${config.API_URL}/department/${id}`, departmentDetails, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
