import { Button, Col, Form, Input, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import logger from '../../logger';

const initPermissionState = {
  permission_name: '',
};

const PermissionForm = ({ permissionDetails = null, isLoading, onConfirm }) => {
  const isPermissionAvailable = permissionDetails !== null;
  const [form] = Form.useForm();
  const [disabledSave, setDisabledSave] = useState(true);
  const [disabledReset, setDisabledReset] = useState(true);

  useEffect(() => {
    if (isPermissionAvailable) {
      form.setFieldsValue({
        permission_name: permissionDetails.permission_name,
        label: permissionDetails.label,
      });
      setDisabledSave(false);
      setDisabledReset(true);
    } else {
      form.resetFields();
      setDisabledSave(true);
      setDisabledReset(true);
    }
  }, [form, isPermissionAvailable, permissionDetails]);

  const handleReset = () => {
    if (isPermissionAvailable) {
      form.setFieldsValue(permissionDetails);
    } else {
      form.resetFields();
      setDisabledSave(true);
    }
    setDisabledReset(true);
  };

  const onFieldValueChanged = (_, allFields) => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    const isFilled = allValuesFilled();
    setDisabledSave(hasErrors || !isFilled);
    setDisabledReset(!isFilled && isPermissionAvailable);
  };

  const allValuesFilled = () => {
    const formValues = form.getFieldsValue();
    const { permission_name } = formValues;
    return permission_name !== undefined;
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        onConfirm({
          id: permissionDetails?.id,
          permission_name: values.permission_name,
          label: values.label,
        }).then((res) => {
          //TODO:if confirmation not succeeded than do not reset the fields
          if (res) {
            form.resetFields();
            setDisabledSave(true);
            setDisabledReset(true);
          }
        });
      })
      .catch((info) => {
        logger.info('Validate Failed:', info);
      });
  };

  return (
    <Form
      form={form}
      layout='vertical'
      name='permissionForm'
      onFinish={handleOk}
      initialValues={initPermissionState}
      onFieldsChange={onFieldValueChanged}
    >
      <Row gutter={10}>
        <Col>
          <Form.Item
            name='label'
            label='שם הרשאה'
            rules={[
              {
                min: 3,
                message: 'מינמום 3 תווים',
              },
              {
                required: true,
                message: 'נא להזין שם תצוגה!',
              },
            ]}
          >
            <Input autoComplete='off' />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            name='permission_name'
            label='קוד ההרשאה'
          >
            <Input
              autoComplete='off'
              readOnly
              disabled
            />
          </Form.Item>
        </Col>
      </Row>
      <Row
        gutter={10}
        justify={'start'}
      >
        <Col>
          <Button
            type='default'
            disabled={disabledReset}
            onClick={handleReset}
          >
            {isPermissionAvailable ? 'איפוס' : 'ניקוי'}
          </Button>
        </Col>
        <Col>
          <Button
            type='primary'
            htmlType='submit'
            disabled={disabledSave}
            loading={isLoading}
          >
            אישור
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default PermissionForm;
