import React from 'react';
import { SettingOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getAuthState } from '../../utils';

const AdministrationNav = ({ selectedIndex, handleListItemClick }) => {
  const { user } = useSelector((state) => getAuthState(state));
  const userAllowedTabs = user.administration_tabs;
  const administrationNavItems = [
    {
      key: 'departments',
      label: <Link to='/administration/departments'>ניהול מחלקות</Link>,
      icon: <SettingOutlined />,
    },
    {
      key: 'users',
      label: <Link to='/administration/users'>ניהול משתמשים</Link>,
      icon: <SettingOutlined />,
    },
    {
      key: 'projects',
      label: <Link to='/administration/projects'>ניהול פרויקטים</Link>,
      icon: <SettingOutlined />,
    },
    {
      key: 'activities',
      label: <Link to='/administration/activities'>ניהול פעילות</Link>,
      icon: <SettingOutlined />,
    },
    {
      key: 'locations',
      label: <Link to='/administration/locations'>ניהול מיקומים</Link>,
      icon: <SettingOutlined />,
    },
    {
      key: 'roles',
      label: <Link to='/administration/roles'>ניהול תפקידים</Link>,
      icon: <SettingOutlined />,
    },
    {
      key: 'permissions',
      label: <Link to='/administration/permissions'>ניהול הרשאות</Link>,
      icon: <SettingOutlined />,
    },
    {
      key: 'date-events',
      label: <Link to='/administration/date-events'>ניהול תאריכים</Link>,
      icon: <SettingOutlined />,
    },
  ];

  const allowedNavItems = administrationNavItems.filter((item) => userAllowedTabs?.includes(item.key));

  return (
    <Menu
      // theme="dark"
      mode='inline'
      items={allowedNavItems}
      selectedKeys={[selectedIndex]}
      onSelect={handleListItemClick}
    />
  );
};

export default AdministrationNav;
