import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Content } from 'antd/es/layout/layout';
import LoginForm from '../../components/forms/LoginForm';
import { login } from '../../services/auth';
import { getAuthState } from '../../utils';
import { setUser } from '../../actions/authActions';
import Meta from '../../components/Meta';
import logger from '../../logger';

const Login = () => {
  let dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { user, token } = useSelector((state) => getAuthState(state));
  const [intended, setIntended] = useState(null);

  const redirect = useCallback(() => {
    if (intended && intended.redirectTo !== '/') {
      navigate(intended.redirectTo);
    } else {
      navigate('/time');
    }
  }, [intended, navigate]);

  useEffect(() => {
    const intended = location.state;
    if (intended) setIntended(intended);
    // redirect if user already logged in
    if (user && token) redirect();
  }, [user, token, navigate, location.state, redirect]);

  const handleLogin = (username, password) => {
    login(username?.toLowerCase(), password)
      .then((res) => {
        if (res.status === 200) {
          dispatch(setUser({ user: res.data.userProfile, token: res.data.access_token }));
          localStorage.setItem('access_token', res.data.access_token);
          localStorage.setItem('refresh_token', res.data.refresh_token);
          redirect();
        }
      })
      .catch((error) => {
        // const parsedError = handleError(error);
        logger.error(error, true);
        // toast.error(parsedError?.toastMessage?.length ? parsedError.toastMessage : parsedError.message);
      });
  };

  return (
    <Content className='d-flex justify-content-center align-items-center'>
      <Meta
        title={'התחברות'}
        description={'התחברות לKTM'}
      />
      <LoginForm handleLogin={handleLogin} />
    </Content>
  );
};

export default Login;
