import { Skeleton, Space, Table } from 'antd';
import React from 'react';

const SkeletonTimeReportsTable = ({ rows = 2 }) => {
  const skeletonButton = (size, block) => (
    <Skeleton.Button
      block={block}
      size={size}
      active
    />
  );
  const skeletonInput = (size, block) => (
    <Skeleton.Input
      block={block}
      size={size}
      active
    />
  );

  const columns = [
    {
      title: skeletonInput('small', true),
      // dataIndex: 'project',
      // key: 'project',
      width: '15%',
      render: () => skeletonButton('small', true),
    },
    {
      title: skeletonInput('small', true),
      // dataIndex: 'activity',
      // key: 'activity',
      // width: '12%',
      align: 'center',
      render: () => skeletonButton('small', true),
    },
    {
      title: skeletonInput('small', true),
      // dataIndex: 'location',
      // key: 'location',
      width: '10%',
      align: 'center',
      render: () => skeletonButton('small', true),
    },
    {
      title: skeletonInput('small', true),
      // dataIndex: 'in_time',
      // key: 'in_time',
      width: '10%',
      align: 'center',
      render: () => skeletonButton('small', true),
    },
    {
      title: skeletonInput('small', true),
      // dataIndex: 'out_time',
      // key: 'out_time',
      width: '10%',
      align: 'center',
      render: () => skeletonButton('small', true),
    },
    {
      title: skeletonInput('small', true),
      // dataIndex: 'out_time',
      // key: 'out_time',
      width: '10%',
      align: 'center',
      render: () => skeletonButton('small', true),
    },
    {
      title: skeletonInput('small', true),
      // dataIndex: 'comments',
      // key: 'comments',
      align: 'center',
      ellipsis: {
        showTitle: false,
      },
      render: () => skeletonButton('small', true),
    },
    {
      title: skeletonInput('small', true),
      key: 'actions',
      width: '18%',
      align: 'center',
      render: (_, record) => (
        <Space size='middle'>
          <Skeleton.Avatar
            size='small'
            active
            shape='square'
          />
          <Skeleton.Avatar
            size='small'
            active
            shape='square'
          />
          <Skeleton.Avatar
            size='small'
            active
            shape='square'
          />
        </Space>
      ),
    },
  ];

  // if (tableColumns)
  //   columns = tableColumns.map((col) => ({
  //     ...col,
  //     title: (
  //       <Skeleton.Button
  //         block
  //         size='small'
  //         active
  //       />
  //     ),
  //     render: () => (
  //       <Skeleton.Button
  //         size='small'
  //         block
  //         active
  //       />
  //     ),
  //   }));

  return (
    <Table
      columns={columns}
      dataSource={Array.from({ length: rows }, (_, index) => ({ key: index }))}
      pagination={false}
      scroll={{ x: 600 }}
    />
  );
};

export default SkeletonTimeReportsTable;
