import axios from 'axios';
import config from '../config';

export const getAllAccounts = async (token) => {
  return await axios.get(`${config.API_URL}/accounts`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const createNewAccount = async (accountDetails, token) => {
  return await axios.post(`${config.API_URL}/account`, accountDetails, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateExistingAccount = async (accountDetails, id, token) => {
  return await axios.put(`${config.API_URL}/account/${id}`, accountDetails, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
