import { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Title from 'antd/es/typography/Title';
import useDepartmentManagement from '../../hooks/useDepartmentManagement';
import useAccountManagement from '../../hooks/useAccountManagement';
import { getAuthState } from '../../utils';
import CustomModal from '../modals/CustomModal';
import DepartmentForm from '../forms/DepartmentForm';
import authWrapper from '../../utils/authWrapper';
import { Card, Col, Row, Typography, List, Button, Tooltip } from 'antd';
import TooltipButton from '../buttons/TooltipButton';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import LocalSearch from '../forms/LocalSearch';
import { useEffect } from 'react';
import Meta from '../Meta';

const { Text } = Typography;

const ManageDepartments = () => {
  const { token } = useSelector((state) => getAuthState(state));
  const [editedDepartment, setEditedDepartment] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [keyword, setKeyword] = useState('');
  const { departments, loading, createDepartment, updateDepartment } = useDepartmentManagement(token);
  const { accounts, loadAccounts } = useAccountManagement(token);

  const handleEditDepartment = (department) => {
    setEditedDepartment(department);
    handleToggleModal();
  };

  const handleCreate = (data) => {
    return createDepartment(data).then((success) => {
      if (success) {
        toast.success('המחלקה נוצרה בהצלחה');
        setEditedDepartment(null);
        handleToggleModal();
        return true;
      } else {
        return false;
      }
    });
  };

  const handleUpdate = (data) => {
    return updateDepartment(data).then((success) => {
      if (success) {
        toast.success('עדכון המחלקה התבצע בהצלחה!');
        setEditedDepartment(null);
        handleToggleModal();
        return true;
      } else {
        return false;
      }
    });
  };

  const handleToggleModal = () => {
    setIsModalOpen((state) => !state);
  };

  useEffect(() => {
    loadAccounts();
  }, [loadAccounts]);

  const handleCancelModal = () => {
    setEditedDepartment(null);
    handleToggleModal?.();
  };

  const searchFilter = (keyword) => (department) => {
    return department.department_name.includes(keyword.length > 1 ? keyword : '');
  };

  return (
    <div className='container w-75 m-2'>
      <Meta
        title={'ניהול מחלקות'}
        description={'מסך ניהול מחלקות'}
      />
      <Title
        level={1}
        className='text-center'
      >
        ניהול מחלקות
      </Title>
      <Card className='p-1'>
        <Row
          justify={'center'}
          gutter={12}
        >
          <Col span={9}>
            <LocalSearch
              keyword={keyword}
              setKeyword={setKeyword}
            />
          </Col>
          <Col span={1}>
            <Tooltip title='הוספת מחלקה חדשה'>
              <Button
                icon={<PlusOutlined />}
                type='text'
                onClick={handleEditDepartment}
              />
            </Tooltip>
          </Col>
        </Row>
        <Row justify={'center'}>
          <Col span={10}>
            <List
              pagination={{
                position: 'bottom',
                pageSize: 8,
                defaultCurrent: 1,
                className: 'text-center',
                hideOnSinglePage: true,
              }}
              dataSource={departments.filter(searchFilter(keyword))}
              renderItem={(department) => (
                <List.Item
                  key={department.id}
                  actions={[
                    <TooltipButton
                      placement='top'
                      type='text'
                      title='ערוך מחלקה'
                      icon={<EditOutlined />}
                      onClick={() => handleEditDepartment(department)}
                    ></TooltipButton>,
                  ]}
                >
                  <List.Item.Meta title={<Text>{department.department_name}</Text>} />
                </List.Item>
              )}
            />
          </Col>
        </Row>
      </Card>
      <CustomModal
        modalTitle={editedDepartment ? 'ערוך מחלקה' : 'צור מחלקה חדשה'}
        isModalOpen={isModalOpen}
        handleCancel={handleCancelModal}
        width={'fit-content'}
      >
        <DepartmentForm
          departmentDetails={editedDepartment}
          isLoading={loading}
          accounts={accounts}
          onConfirm={editedDepartment ? handleUpdate : handleCreate}
        />
      </CustomModal>
    </div>
  );
};

export default authWrapper('departments')(ManageDepartments);
