import { Card, List, Skeleton } from 'antd';
import React from 'react';
import { calculateTotalTime, getPageLayoutState, getAuthState } from '../../utils';
import dayjs from 'dayjs';
import SkeletonTimeReportsTable from '../tables/SkeletonTimeReportsTable';
import MobileReportItem from '../reportItems/MobileReportItem';
import DesktopReportItem from '../reportItems/DesktopReportItem';
import { useSelector } from 'react-redux';
import useUpload from '../../hooks/useUpload';
import logger from '../../logger';

const TimeReportsList = ({ timeReports, loading, handleEdit, handleDelete, handleDuplicate }) => {
  const { token } = useSelector((state) => getAuthState(state));
  const { downloadFileById } = useUpload(token);

  const { isMobile } = useSelector((state) => getPageLayoutState(state));
  const parseTotalTime = (reports) => {
    const totalTime = calculateTotalTime(reports);
    if (dayjs(totalTime).isValid()) return `סה"כ ${dayjs.duration(totalTime, 'minutes').format('HH:mm')}`;
    else return '';
  };

  const handleDownload = (file_id) => {
    if (file_id === undefined) return null;
    return downloadFileById(file_id).catch((error) => {
      logger.error(error);
    });
  };

  return loading.read ? (
    <List
      dataSource={[1, 2]}
      itemLayout='vertical'
      className='p-3'
      renderItem={(item, i) => (
        <List.Item
          key={i}
          className={i === 0 && 'pt-0'}
        >
          <Card
            title={<Skeleton.Input active />}
            extra={<Skeleton.Input active />}
            style={{ body: { padding: 0 }, head: { padding: 10 } }}
          >
            <SkeletonTimeReportsTable></SkeletonTimeReportsTable>
          </Card>
        </List.Item>
      )}
    />
  ) : (
    <List
      className='px-3'
      dataSource={timeReports}
      itemLayout='vertical'
      loading={loading.read}
      renderItem={({ date, reports }, i) => {
        return (
          <List.Item
            key={date}
            className={i === 0 && 'pt-0'}
          >
            <Card
              title={dayjs(date).format('[יום] dddd, DD.MM.YYYY')}
              extra={parseTotalTime(reports)}
              style={{ body: { padding: 1 }, head: { padding: 10 } }}
            >
              {isMobile ? (
                <MobileReportItem
                  handleDownload={handleDownload}
                  handleEditTimeReport={handleEdit}
                  handleDeleteTimeReport={handleDelete}
                  handleDuplicateTimeReport={handleDuplicate}
                  loading={loading}
                  timeReports={reports}
                />
              ) : (
                <DesktopReportItem
                  handleDownload={handleDownload}
                  handleEditTimeReport={handleEdit}
                  handleDeleteTimeReport={handleDelete}
                  handleDuplicateTimeReport={handleDuplicate}
                  loading={loading}
                  timeReports={reports}
                />
              )}
            </Card>
          </List.Item>
        );
      }}
    />
  );
};

export default TimeReportsList;
