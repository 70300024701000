import axios from 'axios';
import config from '../config';

export const getAllTemplates = async (token) => {
  return await axios.get(`${config.API_URL}/template`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const createNewTemplate = async (templateDetails, token) => {
  return await axios.post(`${config.API_URL}/template`, templateDetails, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteTemplate = async (templateId, token) => {
  return await axios.delete(`${config.API_URL}/template/${templateId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
