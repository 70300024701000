import React from 'react';
import { Typography } from 'antd';
import './ShowLoading.css';

const ShowLoading = ({ showText = false }) => {
  return (
    <div className='loading-container'>
      <div className='loader-animation'>
        <div className='loader' />
        {showText && <Typography.Text className='loader-text'>טוען...</Typography.Text>}
      </div>
    </div>
  );
};

export default ShowLoading;
