import { useState } from 'react';
import { uploadFile, downloadFile, deleteFile } from '../services/upload';
import logger from '../logger';
import { handleError } from '../utils/handleErrors';

const useUpload = (initialToken) => {
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [token, setToken] = useState(initialToken);

  //saving the file
  const uploadFiles = (data) => {
    setUploading(true);
    return uploadFile(data, token)
      .then((res) => {
        const file = res?.data;
        const file_id = file?.id;
        return file_id;
      })
      .catch((error) => {
        const parsedError = handleError(error);
        logger.error(error, true);
        // toast.error(parsedError.toastMessage);
        setError(parsedError);
      })
      .finally(() => setUploading(false));
  };

  //saving the file
  const deletingFile = (file_id) => {
    setUploading(true);
    return deleteFile(file_id, token)
      .then(() => {
        return true;
      })
      .catch((error) => {
        const parsedError = handleError(error);
        logger.error(error, true);
        // toast.error(parsedError.toastMessage);
        setError(parsedError);
        return false;
      })
      .finally(() => setUploading(false));
  };

  const downloadFileById = (file_id) => {
    return downloadFile(file_id, token)
      .then((res) => {
        const { file_url, filename } = res.data;
        // Use presigned URL directly to trigger download
        const link = document.createElement('a');
        link.href = file_url;
        link.download = filename;
        link.click();
      })
      .catch((error) => {
        const parsedError = handleError(error);
        logger.error(error, true);
        // toast.error(parsedError.toastMessage);
        setError(parsedError);
        throw error;
      });
  };

  const setAuthToken = (token) => {
    setToken(token);
  };

  return { uploading, error, uploadFiles, deletingFile, downloadFileById, setAuthToken };
};

export default useUpload;
