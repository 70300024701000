import { useCallback, useEffect, useState } from 'react';
import {
  createNewUser,
  getAllUsers,
  updateExistingUser,
  assignProjectsToUser,
  getAllUsersByProjects,
  getUserReportPermission,
} from '../services/users';
import logger from '../logger';
import { handleError } from '../utils/handleErrors';

const useUserManagement = (initialToken, loadAllUsers = false) => {
  const [users, setUsers] = useState([]);
  const [userReportPermission, setUserReportPermission] = useState('user');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [token, setToken] = useState(initialToken);

  // load all users
  const loadUsers = useCallback(() => {
    setLoading(true);
    getAllUsers(token)
      .then((res) => {
        const data = res.data;
        const users = Array.isArray(data) ? data : [data];
        setUsers(users);
      })
      .catch((error) => {
        const parsedError = handleError(error);
        logger.error(error, true);
        // toast.error(parsedError.toastMessage);
        setError(parsedError);
      })
      .finally(() => setLoading(false));
  }, [token]);

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  const loadUsersByProjects = useCallback(
    (projectsData) => {
      setLoading(true);
      return getAllUsersByProjects(projectsData, token)
        .then((res) => {
          const data = res.data;
          const users = Array.isArray(data) ? data : [data];
          return users;
        })
        .catch((error) => {
          const parsedError = handleError(error);
          logger.error(error, true);
          // toast.error(parsedError.toastMessage);
          setError(parsedError);
          return false;
        })
        .finally(() => setLoading(false));
    },
    [token]
  );

  const createUser = (data) => {
    setLoading(true);
    return createNewUser(data, token)
      .then(() => {
        loadUsers();
        return true;
      })
      .catch((error) => {
        const parsedError = handleError(error);
        logger.error(error, true);
        // toast.error(parsedError.toastMessage);
        setError(parsedError);
        return false;
      })
      .finally(() => setLoading(false));
  };

  const updateUser = (data) => {
    const { id, ...restData } = data;
    setLoading(true);
    return updateExistingUser(restData, id, token)
      .then(() => {
        loadUsers();
        return true;
      })
      .catch((error) => {
        const parsedError = handleError(error);
        logger.error(error, true);
        // toast.error(parsedError.toastMessage);
        setError(parsedError);
        return false;
      })
      .finally(() => setLoading(false));
  };

  const assignProjectsUser = (data) => {
    const { id, ...restData } = data;
    setLoading(true);
    return assignProjectsToUser(restData, id, token)
      .then(() => {
        loadUsers();
        return true;
      })
      .catch((error) => {
        const parsedError = handleError(error);
        logger.error(error, true);
        // toast.error(parsedError.toastMessage);
        setError(parsedError);
        return false;
      })
      .finally(() => setLoading(false));
  };

  // Memoize the function to avoid recreating it on every render
  const getCurrentUserReportPermission = useCallback(() => {
    setLoading(true);
    getUserReportPermission(token)
      .then((res) => {
        const data = res.data;
        const userPermission = data.permission;
        setUserReportPermission(userPermission);
      })
      .catch((error) => {
        const parsedError = handleError(error);
        logger.error(error, true);
        // toast.error(parsedError.toastMessage);
        setError(parsedError);
      })
      .finally(() => setLoading(false));
  }, [token]); // Include dependencies if needed, e.g., `token`

  const setAuthToken = (token) => {
    setToken(token);
  };

  return {
    users,
    loading,
    userReportPermission,
    error,
    loadUsersByProjects,
    assignProjectsUser,
    getCurrentUserReportPermission,
    createUser,
    updateUser,
    setAuthToken,
  };
};

export default useUserManagement;
