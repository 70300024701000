import { Card, Col, Row, Layout, Button, Tabs, ConfigProvider, Empty } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { RiFileExcel2Line } from 'react-icons/ri';

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { calculateDiffTime, getAuthState } from '../../utils';
import {
  downloadDepartmentReportsToCSV,
  downloadMissingReportsToCSV,
  downloadProjectReportsToCSV,
} from '../../utils/csvExporterHelper';
import useTimeManagement from '../../hooks/useTimeManagement';
import dayjs from 'dayjs';

import ReportsForm from '../../components/forms/ReportsForm';
import MissingReportForm from '../../components/forms/MissingReportForm';
import DepartmentReportsForm from '../../components/forms/DepartmentReportsForm';
import ReportsList from '../../components/lists/ReportsList';
import SummarizedReportList from '../../components/lists/SummarizedReportList';
import { useCallback } from 'react';
import Meta from '../../components/Meta';
import logger from '../../logger';
import ProcessAndDownloadReportButton from '../../components/documentGeneration/reportDocPDF';
import MissingReportList from '../../components/lists/MissingReportsList';
import useUserManagement from '../../hooks/useUserManagement';

const ReportManagement = () => {
  const { user, token } = useSelector((state) => getAuthState(state));
  const [tabsItems, setTabsItems] = useState([]);
  const [reports, setReports] = useState(null);
  const [missingReports, setMissingReports] = useState(null);
  const [departmentReports, setDepartmentReports] = useState(null);
  const [selectedSortingOption, setSelectedSortingOption] = useState('unfiltered');
  const { userReportPermission, getCurrentUserReportPermission } = useUserManagement(token);
  const {
    loadReportByUsersAndDateRange,
    loadMissingReportsByQueryObject,
    loadDepartmentReportsByQueryObject,
    loading: timeReportLoading,
  } = useTimeManagement(token);

  const formatDate = (date) => date.startOf('day').format('DD/MM/YYYY HH:mm');
  const mappingReportsToTable = (reportItems, sortingOption) => {
    setSelectedSortingOption(sortingOption);

    return reportItems.map(({ key, reports, totalDuration, summary }) => {
      const mappedReports = reports
        ? reports.map(({ in_time, out_time, user, activity, comments, file, project }) => ({
            reportDate: dayjs(in_time).format('DD/MM/YYYY'),
            name: `${user?.first_name} ${user?.last_name}`,
            projectName: project.project_name,
            activity: activity.activity_name,
            start_time: dayjs(in_time).format('HH:mm'),
            end_time: dayjs(out_time).format('HH:mm'),
            totalDuration: calculateDiffTime(in_time, out_time).format('H:mm'),
            description: comments,
            file,
          }))
        : [];

      return { key, reports: mappedReports, totalDuration, summary };
    });
  };

  const renderToReportsTable = useCallback(
    (mappedReports, project_name, isSummarized = false, paginationSettings, dateEvents = []) => {
      return isSummarized ? (
        <SummarizedReportList
          project_name={project_name}
          summarizedReports={mappedReports}
          loading={timeReportLoading}
          sortingOption={selectedSortingOption}
        />
      ) : (
        <ReportsList
          project_name={project_name}
          reports={mappedReports}
          loading={timeReportLoading}
          paginationSettings={paginationSettings}
          dateEvents={dateEvents}
        />
      );
    },
    [selectedSortingOption, timeReportLoading]
  );

  const renderToMissingReportTable = useCallback(
    (missingReports) => {
      return (
        <MissingReportList
          missingReports={missingReports}
          loading={timeReportLoading}
        />
      );
    },
    [timeReportLoading]
  );

  const isUserPermitted = useCallback(
    (all = false) => {
      if (all) return userReportPermission.toLowerCase() === 'all-reports';
      else
        return (
          userReportPermission.toLowerCase() === 'department-reports' ||
          userReportPermission.toLowerCase() === 'all-reports'
        );
    },
    [userReportPermission]
  );

  const getRequiredReports = useCallback(
    (values) => {
      const { users, startDate: start_date, endDate: end_date, projects, sortingOption, isSummary } = values;

      const startDate = formatDate(start_date);
      const endDate = formatDate(end_date);

      const usersToLoad = users.length > 0 ? users : [user.id];

      return loadReportByUsersAndDateRange({
        users: usersToLoad,
        startDate,
        endDate,
        projects,
        sortingOption,
        isSummary,
      })
        .then((res) => {
          if (res) {
            const requestedReports = { reportItems: res.data, sortingOption };
            setMissingReports(null);
            setDepartmentReports(null);
            setReports(requestedReports);
            return true;
          }
        })
        .catch((info) => {
          logger.info(info);
          return false;
        });
    },
    [loadReportByUsersAndDateRange, user?.id]
  );

  const getMissingReports = useCallback(
    (values) => {
      const { users, startDate: start_date, endDate: end_date, isByGroup } = values;

      const startDate = formatDate(start_date);
      const endDate = formatDate(end_date);

      const usersToLoad = users.length > 0 ? users : [user.id];

      return loadMissingReportsByQueryObject({
        users: usersToLoad,
        startDate,
        endDate,
        isByGroup,
      })
        .then((res) => {
          if (res) {
            setMissingReports(res.data);
            setDepartmentReports(null);
            setReports(null);
            return true;
          }
        })
        .catch((info) => {
          logger.info(info);
          return false;
        });
    },
    [loadMissingReportsByQueryObject, user?.id]
  );

  const getDepartmentReports = useCallback(
    (values) => {
      const { departments, rangeDates, sortingOption, isFilterByUsers, users } = values;

      const startDate = formatDate(rangeDates[0]);
      const endDate = formatDate(rangeDates[1]);

      return loadDepartmentReportsByQueryObject({
        departments,
        startDate,
        endDate,
        sortingOption,
        users: isFilterByUsers ? users : [],
      })
        .then((res) => {
          if (res) {
            const requestedReports = { reportItems: res.data, sortingOption };
            setDepartmentReports(requestedReports);
            setMissingReports(null);
            setReports(null);
            return true;
          }
        })
        .catch((info) => {
          logger.info(info);
          return false;
        });
    },
    [loadDepartmentReportsByQueryObject]
  );

  const renderReports = useCallback(() => {
    if ((reports && reports.reportItems) || missingReports)
      return (
        <Card styles={{ body: { padding: 10 } }}>
          <Tabs
            type='card'
            tabBarExtraContent={
              <ConfigProvider
                theme={{
                  components: {
                    Button: {
                      defaultHoverBorderColor: 'forestgreen',
                      defaultHoverColor: 'forestgreen',
                    },
                  },
                }}
              >
                <Row gutter={10}>
                  <Col>
                    <Button
                      onClick={() => downloadProjectReportsToCSV(reports)}
                      icon={<RiFileExcel2Line style={{ marginBottom: 1, color: 'darkgreen' }} />}
                    >
                      ייצוא כל הפרויקטים
                    </Button>
                  </Col>

                  <Col>
                    <ProcessAndDownloadReportButton
                      filename={`דוחות_${dayjs().format('YYYY-MM-DD')}.pdf`}
                      reports={reports}
                      sortingOption={selectedSortingOption}
                    />
                  </Col>
                </Row>
              </ConfigProvider>
            }
            items={tabsItems}
          />
        </Card>
      );
    return (
      <Empty
        className='mt-4'
        image={Empty.PRESENTED_IMAGE_DEFAULT}
        description='לא נמצאו דוחות'
      />
    );
  }, [reports, tabsItems, missingReports, selectedSortingOption]);

  const renderMissingReports = useCallback(() => {
    if (missingReports)
      return (
        <Card styles={{ body: { padding: 10 } }}>
          <Tabs
            type='card'
            tabBarExtraContent={
              <ConfigProvider
                theme={{
                  components: {
                    Button: {
                      defaultHoverBorderColor: 'forestgreen',
                      defaultHoverColor: 'forestgreen',
                    },
                  },
                }}
              >
                <Row gutter={10}>
                  <Col>
                    <Button
                      onClick={() => downloadMissingReportsToCSV(missingReports)}
                      icon={<RiFileExcel2Line style={{ marginBottom: 1, color: 'darkgreen' }} />}
                    >
                      ייצא דוח חוסרים
                    </Button>
                  </Col>
                </Row>
              </ConfigProvider>
            }
            items={tabsItems}
          />
        </Card>
      );
    return (
      <Empty
        className='mt-4'
        image={Empty.PRESENTED_IMAGE_DEFAULT}
        description='לא נמצאו דוחות'
      />
    );
  }, [missingReports, tabsItems]);

  const renderDepartmentReports = useCallback(() => {
    if (departmentReports && departmentReports.reportItems?.length)
      return (
        <Card styles={{ body: { padding: 10 } }}>
          <Tabs
            type='card'
            tabBarExtraContent={
              <ConfigProvider
                theme={{
                  components: {
                    Button: {
                      defaultHoverBorderColor: 'forestgreen',
                      defaultHoverColor: 'forestgreen',
                    },
                  },
                }}
              >
                <Row gutter={10}>
                  <Col>
                    <Button
                      onClick={() => downloadDepartmentReportsToCSV(departmentReports)}
                      icon={<RiFileExcel2Line style={{ marginBottom: 1, color: 'darkgreen' }} />}
                    >
                      ייצוא כל המחלקות
                    </Button>
                  </Col>

                  <Col>
                    <ProcessAndDownloadReportButton
                      filename={`דוחות_מחלקה_${dayjs().format('YYYY-MM-DD')}.pdf`}
                      reports={departmentReports}
                      sortingOption={selectedSortingOption}
                    />
                  </Col>
                </Row>
              </ConfigProvider>
            }
            items={tabsItems}
          />
        </Card>
      );
    return (
      <Empty
        className='mt-4'
        image={Empty.PRESENTED_IMAGE_DEFAULT}
        description='לא נמצאו דוחות'
      />
    );
  }, [tabsItems, selectedSortingOption, departmentReports]);

  const renderReportTabForms = useCallback(() => {
    const baseTabs = [
      {
        label: 'לפי פרויקט',
        key: 'reports',
        children: (
          <ReportsForm
            isUserPermitted={isUserPermitted}
            loading={timeReportLoading}
            getRequiredReports={getRequiredReports}
          />
        ),
      },
    ];

    const departmentReportsTab = isUserPermitted(true) && {
      label: 'לפי מחלקה',
      key: 'departments',
      children: (
        <DepartmentReportsForm
          loading={timeReportLoading}
          getDepartmentReports={getDepartmentReports}
        />
      ),
    };

    const missingReportsTab = isUserPermitted() && {
      label: 'דוחות חוסרים',
      key: 'missing',
      children: (
        <MissingReportForm
          loading={timeReportLoading}
          requiredMissingReports={getMissingReports}
        />
      ),
    };

    return isUserPermitted() ? [...baseTabs, departmentReportsTab, missingReportsTab] : baseTabs;
  }, [isUserPermitted, timeReportLoading, getRequiredReports, getMissingReports, getDepartmentReports]);

  useEffect(() => {
    if (reports && reports.reportItems) {
      const parsedProjects = reports.reportItems.map((report, i) => {
        const mappedReports = mappingReportsToTable(report.serialized_reports, reports.sortingOption);
        return {
          label: report.project_name,
          key: i,
          children: renderToReportsTable(mappedReports, report.project_name, report.is_summarized),
        };
      });
      setTabsItems(parsedProjects);
    } else if (missingReports?.length > 0) {
      setTabsItems([
        {
          label: 'דוחות חוסרים',
          key: 'missingReports',
          children: renderToMissingReportTable(missingReports),
        },
      ]);
    } else if (departmentReports && departmentReports.reportItems) {
      const parsedDepartments = departmentReports.reportItems.map((report, i) => {
        const mappedReports = mappingReportsToTable(
          report.serialized_reports,
          departmentReports.sortingOption
        );
        return {
          label: report.department_name,
          key: i,
          children: renderToReportsTable(
            mappedReports,
            report.department_name,
            report.is_summarized,
            {
              pageSize: 10,
            },
            report.date_events
          ),
        };
      });
      setTabsItems(parsedDepartments);
    }
  }, [reports, renderToReportsTable, missingReports, renderToMissingReportTable, departmentReports]);

  useEffect(() => {
    getCurrentUserReportPermission();
  }, [getCurrentUserReportPermission, user]);

  return (
    <Layout>
      <Meta
        title={'דוחות'}
        description={'יצירת דוחות'}
      />
      <Content
        style={{ overflowX: 'hidden' }}
        id='reports-layout'
      >
        <Row
          justify={'center'}
          className='mt-3'
          gutter={12}
        >
          <Col span={6}>
            <Card
              title='יצירת דוח'
              className='ms-2'
            >
              <Tabs items={renderReportTabForms()} />
            </Card>
          </Col>
          <Col span={18}>
            {departmentReports && renderDepartmentReports()}
            {reports && renderReports()}
            {missingReports && renderMissingReports()}
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default ReportManagement;
