import { useCallback, useEffect, useState } from 'react';
import { getAllPermissions, updateExistingPermission } from '../services/permissions.js';
import logger from '../logger.js';
import { handleError } from '../utils/handleErrors.js';

const usePermissionManagement = (initialToken) => {
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [token, setToken] = useState(initialToken);

  // load all permissions
  const loadPermissions = useCallback(() => {
    setLoading(true);
    getAllPermissions(token)
      .then((res) => {
        if (!res) throw new Error('Could not recieve data');
        setPermissions(res?.data.map((permission) => ({ ...permission, key: permission.id })));
      })
      .catch((error) => {
        const parsedError = handleError(error);
        logger.error(error, true);
        // toast.error(parsedError.toastMessage);
        setError(parsedError);
      })
      .finally(() => setLoading(false));
  }, [token]);

  useEffect(() => {
    loadPermissions();
  }, [loadPermissions]);

  const updatePermission = (data) => {
    const { id, ...restData } = data;
    const permissionName = data.permission_name;
    setLoading(true);
    return updateExistingPermission(restData, id, token)
      .then(() => {
        loadPermissions();
        return true;
      })
      .catch((error) => {
        const parsedError = handleError(error, permissionName);
        logger.error(error, true);
        // toast.error(parsedError.toastMessage);
        setError(parsedError);
        return false;
      })
      .finally(() => setLoading(false));
  };

  const setAuthToken = (token) => {
    setToken(token);
  };

  return {
    permissions,
    loading,
    error,
    updatePermission,
    setAuthToken,
  };
};

export default usePermissionManagement;
