import { EditOutlined, SearchOutlined, UserAddOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table } from 'antd';
import React, { useRef, useState } from 'react';
import { FaBan, FaCheck } from 'react-icons/fa6';
import TooltipButton from '../../components/buttons/TooltipButton';
import Highlighter from 'react-highlight-words';
import dayjs from 'dayjs';

const ProjectsTable = ({ projects, loading, handleToggleModal, handleEditProject }) => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    setSearchText('');
    confirm();
  };

  const getColumnSearchProps = (dataIndex, title = '') => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`חיפוש לפי ${title}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{
              width: 90,
            }}
          >
            חיפוש
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size='small'
            style={{
              width: 90,
            }}
          >
            איפוס
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: 'קוד',
      dataIndex: 'code',
      key: 'code',
      width: '5%',
      ...getColumnSearchProps('code', 'קוד'),
      sorter: (a, b) => a.code - b.code,
      sortDirections: ['ascend', 'descend'],
      defaultSortOrder: 'ascend',
    },
    {
      title: 'פרויקט',
      dataIndex: 'project_name',
      key: 'project_name',
      width: '10%',
      ...getColumnSearchProps('project_name', 'פרויקט'),
      sorter: (a, b) => a.project_name.localeCompare(b.project_name),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'מנהל',
      dataIndex: 'manager_id',
      key: 'manager',
      width: '8%',
      render: (id, project) => {
        const { first_name, last_name } = project.manager;
        return `${first_name} ${last_name}`;
      },
    },
    {
      title: 'תאריך התחלה',
      dataIndex: 'start_date',
      key: 'start_date',
      width: '10%',
      render: (date) => {
        return dayjs(date).format('DD/MM/YYYY');
      },
    },
    {
      title: 'תאריך סיום',
      dataIndex: 'end_date',
      key: 'end_date',
      width: '10%',
      render: (date) => {
        return dayjs(date).format('DD/MM/YYYY');
      },
    },
    {
      title: 'תיאור',
      dataIndex: 'description',
      key: 'description',
      width: '15%',
    },
    {
      title: 'פעיל?',
      dataIndex: 'active',
      key: 'active',
      width: '5%',
      filters: [
        {
          text: 'פעיל',
          value: true,
        },
        {
          text: 'לא פעיל',
          value: false,
        },
      ],
      onFilter: (value, record) => {
        return record.active === value;
      },
      render: (isActive) => (
        <div className='d-flex justify-content-center'>
          {isActive === true ? <FaCheck className='text-success' /> : <FaBan className='text-danger' />}
        </div>
      ),
    },
    {
      title: 'פעולות',
      key: 'actions',
      width: '10%',
      render: (_, record) => (
        <Space size='middle'>
          <TooltipButton
            placement='top'
            type='text'
            title='ערוך'
            icon={<EditOutlined />}
            onClick={() => handleEditProject(record)}
          ></TooltipButton>
        </Space>
      ),
    },
  ];

  return (
    <Table
      title={() => (
        <Button
          type='primary'
          shape='square'
          onClick={handleToggleModal}
          icon={<UserAddOutlined />}
        >
          הוספה
        </Button>
      )}
      columns={columns}
      dataSource={projects}
      loading={loading}
      scroll={{ x: 500 }}
      pagination={{
        pageSize: 8,
        hideOnSinglePage: true,
        position: ['bottomCenter'],
      }}
    />
  );
};

export default ProjectsTable;
