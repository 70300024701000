import {
  INIT_DATE_EVENTS,
  LAYOUT_DESKTOP,
  LAYOUT_MOBILE,
  LOAD_DATE_EVENTS,
  UPDATE_USER_DETAILS,
} from './types';

export const updateUserDetailsAction = (userDetails) => ({
  type: UPDATE_USER_DETAILS,
  payload: {
    ...userDetails,
  },
});

export const loadDateEvents = (dateEvents) => ({
  type: LOAD_DATE_EVENTS,
  payload: dateEvents,
});

export const resetDateEvents = () => ({
  type: INIT_DATE_EVENTS,
});

export const setMobile = () => ({
  type: LAYOUT_MOBILE,
  // payload: null,
});

export const setDesktop = () => ({
  type: LAYOUT_DESKTOP,
  // payload: null,
});
