import {
  DUPLICATE_REPORT,
  EDIT_REPORT,
  RESET_REPORT,
  SELECT_DATE,
  LOAD_REPORTS,
  INIT_SELECTED_DATE,
} from './types';

export const loadReports = (report) => ({
  type: LOAD_REPORTS,
  payload: report,
});

export const editReport = (report) => ({
  type: EDIT_REPORT,
  payload: report,
});

export const duplicateReport = (report) => ({
  type: DUPLICATE_REPORT,
  payload: report,
});

export const resetReport = () => ({
  type: RESET_REPORT,
  payload: null,
});

export const selectDate = (date) => ({
  type: SELECT_DATE,
  payload: date,
});

export const resetDate = () => ({
  type: INIT_SELECTED_DATE,
  payload: null,
});
