import { combineReducers } from 'redux';
import { manageTimeReportReducer } from './manageTimeReportReducer';
import { selectedDateReducer, dateReportsReducer } from './timeReportsReducer';
import { dateEventsReducer } from './dateEventsReducer';
import { pageLayoutReducer } from './pageLayoutReducer';
import authReducer from './authReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  managedReport: manageTimeReportReducer,
  selectedDate: selectedDateReducer,
  dateReports: dateReportsReducer,
  dateEvents: dateEventsReducer,
  pageLayout: pageLayoutReducer,
});

export default rootReducer;
