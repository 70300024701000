import {
  Badge,
  Calendar,
  Row,
  Col,
  List,
  Card,
  Button,
  Flex,
  Space,
  Select,
  Typography,
  Radio,
  Collapse,
  Divider,
  ConfigProvider,
  Switch,
} from 'antd';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getAuthState } from '../../utils';
import Title from 'antd/es/typography/Title';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import useDateEventsManagement from '../../hooks/useDateEventsManagement';
import '../../custom-styles/dateEventStyle.css';
import { CaretRightOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import TooltipButton from '../buttons/TooltipButton';
import { MdAdd } from 'react-icons/md';
import DateEventForm from '../forms/DateEventForm';
import CustomModal from '../modals/CustomModal';
import useDepartmentManagement from '../../hooks/useDepartmentManagement';
import authWrapper from '../../utils/authWrapper';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';
import Meta from '../Meta';

const ManageDateEvents = () => {
  const { token } = useSelector((state) => getAuthState(state));
  const [date, setDate] = useState(dayjs());
  const [previousMonth, setPreviousMonth] = useState(null);
  const [previousYear, setPreviousYear] = useState(null);
  const [previousDepartments, setPreviousDepratments] = useState(null);
  const [editedDateEvent, setEditedDateEvent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectAllDepartments, setSelectAllDepartments] = useState(false);
  const [selectedDateEvents, setSelectedDateEvents] = useState([]);
  const [depertmentOptions, setDepartmentOptions] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [monthNavTexts, setMonthNavTexts] = useState({ prev: '', next: '' });

  const {
    dateEvents,
    hebrewDates,
    loading,
    loadDateEventsByDate,
    loadHebrewDateEvents,
    createDateEvent,
    updateDateEvent,
    deleteDateEvent,
    clearDateEvents,
  } = useDateEventsManagement(token);
  const { departments } = useDepartmentManagement(token);

  const disableTodayButton = () => dayjs().isSame(date, 'month');

  useEffect(() => {
    const currentMonth = dayjs(date).month();
    const currentYear = dayjs(date).year();
    if (
      currentMonth !== previousMonth ||
      currentYear !== previousYear ||
      selectedDepartments !== previousDepartments
    ) {
      loadHebrewDateEvents(dayjs(date).format('YYYY-MM-DD'));
      setPreviousMonth(currentMonth);
      setPreviousYear(currentYear);
      // setPreviousDepratments(selectedDepartments);
    }
    if (selectedDepartments.length > 0) {
      loadDateEventsByDate(dayjs(date).format('YYYY-MM-DD'), selectedDepartments, selectAllDepartments);
    } else if (selectedDepartments.length === 0 && !selectAllDepartments) {
      clearDateEvents();
    }
    if (date) {
      setMonthNavTexts({
        next: dayjs(date).add(1, 'month').format('MMMM'),
        prev: dayjs(date).subtract(1, 'month').format('MMMM'),
      });
    }
  }, [
    date,
    loadDateEventsByDate,
    loadHebrewDateEvents,
    clearDateEvents,
    previousDepartments,
    previousMonth,
    previousYear,
    selectedDepartments,
    selectAllDepartments,
  ]);

  useEffect(() => {
    if (dateEvents) {
      const dateToGet = dayjs(date).format('YYYY-MM-DD').toString();
      const targetElement = dateEvents?.find((date_event) => date_event.date === dateToGet);
      const events = targetElement?.events;
      setSelectedDateEvents(events || []);
    }

    if (hebrewDates) {
      const dateToGet = dayjs(date).format('YYYY-MM-DD').toString();
      const thisMonthHebrewEvents = hebrewDates?.items
        ?.filter((item) => item.date === dateToGet)
        .map((item) => ({
          event_name: item.hebrew,
          start_date: dayjs(item.date).format('YYYY-MM-DD').toString(),
          end_date: dayjs(item.date).format('YYYY-MM-DD').toString(),
          event_type: undefined,
          is_from_api: true,
        }));

      setSelectedDateEvents((prevDateEvents) => {
        // Filtering out events with the same event_name
        const filteredEvents = thisMonthHebrewEvents.filter(
          (newEvent) => !prevDateEvents.some((prevEvent) => prevEvent.event_name === newEvent.event_name)
        );

        return [...prevDateEvents, ...filteredEvents];
      });
    }
  }, [date, dateEvents, hebrewDates]);

  useEffect(() => {
    if (departments) {
      const departmentOptions = departments.map((item) => {
        return { value: item.id, label: item.department_name };
      });

      setDepartmentOptions(departmentOptions);
      // handleSelectDepartment(0);
    }
  }, [departments]);

  const handleEditDateEvent = (dateEvent) => {
    setEditedDateEvent(dateEvent);
    handleToggleModal();
  };

  const handleCreate = (data) => {
    createDateEvent(data).then((success) => {
      if (success) {
        toast.success('האירוע התווסף בהצלחה');
        setEditedDateEvent(null);
        handleToggleModal();
        return true;
      } else {
        return false;
      }
    });
  };

  const handleUpdate = (data) => {
    updateDateEvent(data).then((success) => {
      if (success) {
        toast.success('עדכון האירוע התבצע בהצלחה!');
        setEditedDateEvent(null);
        handleToggleModal();
        return true;
      } else {
        return false;
      }
    });
  };

  const handleDelete = (data) => {
    //delete
    deleteDateEvent(data.id, data.start_date, selectedDepartments, selectAllDepartments).then((success) => {
      if (success) {
        toast.success('המחיקה התבצעה בהצלחה!');
        setEditedDateEvent(null);
      } else {
      }
    });
  };

  const handleToggleModal = () => {
    setIsModalOpen((state) => !state);
  };

  const handleCancelModal = () => {
    setEditedDateEvent(null);
    handleToggleModal?.();
  };

  const handleOnSelectDate = (newDate) => {
    setDate(dayjs(newDate));
  };

  const handleSelectDepartment = (departments) => {
    setSelectedDepartments(departments);
  };

  const handleSelectAllDepartments = (selectAll) => {
    setSelectAllDepartments(selectAll);
    if (selectAll) {
      setPreviousDepratments(selectedDepartments);
      setSelectedDepartments(departments.map((d) => d.id));
    } else {
      setSelectedDepartments(previousDepartments);
    }
  };

  const renderStatusType = (event_type) => {
    switch (event_type) {
      case 0:
        return 'success'; // no vacation
      case 1:
        return 'warning'; // half day
      case 2:
        return 'error'; // full day
      default:
        return 'default'; // not saved
    }
  };

  // Function to compare items for equality (adjust based on item structure)
  const dateEventAreEqual = (event1, event2) => {
    return event1.event_name === event2.event_name;
  };

  const dateCellRender = (value) => {
    const formattedDate = dayjs(value).format('YYYY-MM-DD');
    const targetElement = dateEvents?.find((date_event) => date_event.date === formattedDate);

    const thisMonthHebrewEvents = hebrewDates?.items
      ?.filter((hebrewEvent) => hebrewEvent.date === formattedDate)
      .map((item) => ({
        event_name: item.hebrew,
        start_date: formattedDate,
        end_date: formattedDate,
        event_type: undefined,
        is_from_api: true,
      }));

    const events =
      targetElement?.events?.concat(
        thisMonthHebrewEvents?.filter(
          (event) => !targetElement.events.some((existingEvent) => dateEventAreEqual(event, existingEvent))
        )
      ) ?? thisMonthHebrewEvents;

    return (
      events && (
        <ul className='events'>
          {events.map((event, index) => (
            <li key={index}>
              <Space>
                <Badge
                  status={renderStatusType(event?.event_type)}
                  text={event?.event_name}
                ></Badge>
              </Space>
            </li>
          ))}
        </ul>
      )
    );
  };

  const parseModalTitle = () => {
    if (selectAllDepartments) {
      return editedDateEvent ? 'עדכון אירוע לכל המחלקות' : 'יצירת אירוע חדש לכל המחלקות';
    }

    const departmentsStringList = departments
      .filter((department) => selectedDepartments.includes(department.id))
      .map((department) => department.department_name)
      .join(', ');

    return editedDateEvent ? (
      <div>
        עדכון אירוע: <br /> {departmentsStringList}
      </div>
    ) : (
      <div>
        יצירת אירוע חדש ל: <br /> {departmentsStringList}
      </div>
    );
  };

  const cellRender = (current, info) => {
    return info.type === 'date' ? dateCellRender(current) : '';
  };

  const headerRender = ({ value, type, onChange, onTypeChange }) => {
    const start = 0;
    const end = 12;
    const monthOptions = [];
    let current = value.clone();
    const localeData = value.localeData();
    const months = [];
    for (let i = 0; i < 12; i++) {
      current = current.month(i);
      months.push(localeData.monthsShort(current));
    }
    for (let i = start; i < end; i++) {
      monthOptions.push(
        <Select.Option
          key={i}
          value={i}
        >
          {months[i]}
        </Select.Option>
      );
    }
    const year = value.year();
    const month = value.month();
    const options = [];
    for (let i = dayjs().year(); i <= dayjs().year() + 1; i += 1) {
      options.push(
        <Select.Option
          key={i}
          value={i}
        >
          {i}
        </Select.Option>
      );
    }

    return (
      <div className='p-1'>
        <Row
          gutter={[0, 10]}
          align={'middle'}
        >
          <Col span={18}>
            <Typography.Title level={5}>בחר מחלקה</Typography.Title>
            <Space>
              <Select
                style={{ minWidth: 200, maxWidth: 450 }}
                placeholder='בחר מחלקה'
                options={depertmentOptions}
                onChange={handleSelectDepartment}
                value={selectedDepartments}
                mode='multiple'
                allowClear
                onClear={() => handleSelectDepartment([])}
                disabled={selectAllDepartments}
              />
              <Switch
                value={selectAllDepartments}
                onChange={handleSelectAllDepartments}
              />
              כל המחלקות
            </Space>
          </Col>
          <Col span={24}>
            <Row gutter={[0, 5]}>
              <Col span={24}>
                <Row gutter={[10, 12]}>
                  <Col>
                    <Radio.Group
                      size='small'
                      onChange={(e) => onTypeChange(e.target.value)}
                      value={type}
                    >
                      <Radio.Button value='month'>חודש</Radio.Button>
                      <Radio.Button value='year'>שנה</Radio.Button>
                    </Radio.Group>
                  </Col>
                  <Col>
                    <Select
                      size='small'
                      style={{
                        width: 70,
                      }}
                      value={year}
                      onChange={(newYear) => {
                        const now = value.clone().year(newYear);
                        onChange(now);
                      }}
                    >
                      {options}
                    </Select>
                  </Col>
                  <Col>
                    <Select
                      size='small'
                      style={{
                        width: 70,
                      }}
                      value={month}
                      onChange={(newMonth) => {
                        const now = value.clone().month(newMonth);
                        onChange(now);
                      }}
                    >
                      {monthOptions}
                    </Select>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                {/* Nav buttons */}
                <Row
                  gutter={10}
                  justify={'center'}
                >
                  <Col>
                    <Button
                      styles={{ icon: { margin: -5 } }}
                      icon={<IoIosArrowForward />}
                      shape='round'
                      size='small'
                      type='default'
                      onClick={() => handleOnSelectDate(dayjs(date).subtract(1, 'month'))}
                    >
                      {monthNavTexts.prev}
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      size='small'
                      shape='round'
                      type='primary'
                      onClick={() => handleOnSelectDate(dayjs())}
                      disabled={disableTodayButton()}
                    >
                      החודש
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      styles={{ icon: { margin: -5 } }}
                      icon={<IoIosArrowBack />}
                      shape='round'
                      iconPosition='end'
                      size='small'
                      type='default'
                      onClick={() => handleOnSelectDate(dayjs(date).add(1, 'month'))}
                    >
                      {monthNavTexts.next}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <div>
      <Meta
        title={'ניהול תאריכים'}
        description={'מסך ניהול תאריכים'}
      />
      <Title
        level={1}
        className='text-center'
      >
        ניהול תאריכים
      </Title>

      <CustomModal
        modalTitle={parseModalTitle()}
        isModalOpen={isModalOpen}
        handleCancel={handleCancelModal}
        width={'500px'}
      >
        <DateEventForm
          dateEventDetails={editedDateEvent}
          isLoading={loading}
          date={date}
          onConfirm={editedDateEvent && editedDateEvent.id !== undefined ? handleUpdate : handleCreate}
          selectedDepartments={selectedDepartments}
          selectAllDepartments={selectAllDepartments}
        />
      </CustomModal>

      <Flex style={{ overflowX: 'hidden' }}>
        <Row gutter={10}>
          <Col span={16}>
            <Card className='ms-2'>
              {depertmentOptions.length > 0 && (
                <Calendar
                  loading={loading}
                  value={date}
                  cellRender={cellRender}
                  headerRender={headerRender}
                  onSelect={handleOnSelectDate}
                />
              )}
            </Card>
          </Col>

          <Col span={8}>
            <Card
              // loading={loading}
              title={
                <Flex justify='space-between'>
                  <Title level={4}>{dayjs(date).format('[יום] dddd, DD.MM.YYYY')}</Title>
                  <Button
                    type='primary'
                    shape='square'
                    icon={<MdAdd />}
                    onClick={handleToggleModal}
                    disabled={selectedDepartments.length === 0}
                  >
                    הוספה
                  </Button>
                </Flex>
              }
              style={{ body: { padding: 0 }, head: { padding: 10 } }}
            >
              {selectedDepartments.length < 1 ? (
                <List
                  loading={loading}
                  itemLayout='horizontal'
                  dataSource={selectedDateEvents}
                  renderItem={(item) => (
                    <List.Item
                      key={item.id}
                      className='ps-2 pt-0'
                      actions={[
                        <TooltipButton
                          placement='top'
                          type='text'
                          title='ערוך'
                          onClick={() => handleEditDateEvent(item)}
                          icon={<EditOutlined />}
                        ></TooltipButton>,
                        <TooltipButton
                          placement='top'
                          type='text'
                          title='מחיקה'
                          disabled={item.is_from_api}
                          icon={<DeleteOutlined className='text-danger' />}
                          onClick={() => handleDelete(item)}
                        ></TooltipButton>,
                      ]}
                    >
                      <List.Item.Meta
                        title={item.is_from_api ? `${item.event_name} (*לא שמור)` : item.event_name}
                      />
                    </List.Item>
                  )}
                />
              ) : (
                <ConfigProvider
                  theme={{
                    components: {
                      Collapse: {
                        headerPadding: 0,
                        contentPadding: '0 20px !important',
                      },
                    },
                  }}
                >
                  <Collapse
                    ghost
                    expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? -90 : 0} />}
                    items={selectedDateEvents.map((event) => ({
                      key: event.id,
                      label: (
                        <Typography.Text strong>
                          {event.is_from_api ? `${event.event_name} (*לא שמור)` : event.event_name}
                        </Typography.Text>
                      ),
                      children: event.departments?.map((department) => (
                        <div key={department.id}>{department.department_name}</div>
                      )),

                      extra: (
                        <>
                          <TooltipButton
                            placement='top'
                            type='text'
                            title='ערוך'
                            onClick={() => handleEditDateEvent(event)}
                            icon={<EditOutlined />}
                          ></TooltipButton>
                          <Divider type='vertical' />
                          <TooltipButton
                            placement='top'
                            type='text'
                            title='מחיקה'
                            disabled={event.is_from_api}
                            icon={<DeleteOutlined className='text-danger' />}
                            onClick={() => handleDelete(event)}
                          ></TooltipButton>
                        </>
                      ),
                    }))}
                  />
                </ConfigProvider>
              )}
            </Card>
          </Col>
        </Row>
      </Flex>
    </div>
  );
};

export default authWrapper('date-events')(ManageDateEvents);
