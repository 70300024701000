import React from 'react';
import { Col, FloatButton, Layout, Row, Segmented } from 'antd';
import CustomModal from '../modals/CustomModal';
import { Content } from 'antd/es/layout/layout';
import TimeReportInlineForm from '../forms/TimeReportInlineForm';
import TimeReportGridForm from '../forms/TimeReportGridForm';
import TimeReportsList from '../lists/TimeReportsList';
import TimeCalendar from '../calendars/TimeCalendar';
import { useSelector } from 'react-redux';
import { getManagedReportState } from '../../utils';
import { useState } from 'react';

const reportsViewOptions = [
  {
    label: 'חודש',
    value: 'month',
  },
  {
    label: 'שבוע',
    value: 'week',
  },
  {
    label: 'יום',
    value: 'day',
  },
];

const DesktopTimeLayout = ({
  documentMode,
  setDocumentMode,
  createReport,
  handleUpload,
  editModalOpen,
  projects,
  locations,
  getActivitiesByProject,
  handleDuplicate,
  loading,
  onCloseEditModal,
  updateReport,
  dateEvents,
  calendarLoading,
  timeReports,
  disabledDate,
  filteredTimeReports,
  handleEdit,
  handleDelete,
  getUserTimeReportFromClock,
  userPreferences,
  selectedDate,
}) => {
  const managedReport = useSelector((state) => getManagedReportState(state));
  const [reportsView, setReportsView] = useState('week');
  const modalTitle = managedReport.project_id
    ? managedReport.id
      ? 'עריכת דיווח'
      : 'שיכפול דיווח'
    : 'דיווח חדש';

  return (
    <Layout>
      <FloatButton.BackTop
        // visibilityHeight={400}
        style={{
          zIndex: 1001,
        }}
        type='primary'
        target={() => document.getElementById('time-layout')}
      />
      <Row
        className='m-1 mb-0 p-2 card'
        style={{ zIndex: 1000 }}
      >
        <TimeReportInlineForm
          layout={'inline'}
          documentMode={documentMode}
          setDocumentMode={setDocumentMode}
          onFinish={createReport}
          onUpdateFiles={handleUpload}
          hidden={editModalOpen}
          projects={projects}
          locations={locations}
          disabledDate={disabledDate}
          getActivities={getActivitiesByProject}
          getUserTimeReportFromClock={getUserTimeReportFromClock}
          userPreferences={userPreferences}
          selectedDate={selectedDate}
          loading={loading}
        />

        <CustomModal
          modalTitle={modalTitle}
          isModalOpen={editModalOpen}
          handleCancel={onCloseEditModal}
          width={'500px'}
        >
          <TimeReportGridForm
            // layout={'horizontal'}
            documentMode={documentMode}
            setDocumentMode={setDocumentMode}
            onFinish={updateReport}
            onUpdateFiles={handleUpload}
            disabledDate={disabledDate}
            projects={projects}
            locations={locations}
            getActivities={getActivitiesByProject}
            getUserTimeReportFromClock={getUserTimeReportFromClock}
            userPreferences={userPreferences}
            loading={loading}
          />
        </CustomModal>
      </Row>
      <Content
        style={{ overflow: 'auto' }}
        id='time-layout'
      >
        <Row justify={'end'}>
          <Col span={7} />
          <Col span={17}>
            <Segmented
              className='px-3'
              size='middle'
              options={reportsViewOptions}
              value={reportsView}
              onChange={setReportsView}
            />
          </Col>
        </Row>
        <Row
          justify={'space-between'}
          // className='mt-3'
        >
          <Col
            // xl={6}
            // lg={8}
            // md={10}
            // sm={24}
            // xs={24}
            span={7}
            // className='ms-4'
          >
            <Row
              justify={'center'}
              style={{ overflow: 'auto' }}
            >
              <Col
                span={22}
                className=''
              >
                {/* <Affix
              offsetTop={15}
              target={() => container}
            > */}
                <TimeCalendar
                  dateEvents={dateEvents}
                  loading={calendarLoading}
                />
                {/* </Affix> */}
              </Col>
            </Row>
          </Col>
          <Col span={17}>
            {timeReports && (
              <TimeReportsList
                timeReports={filteredTimeReports(reportsView)}
                dateEvents={dateEvents}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                handleDuplicate={handleDuplicate}
                loading={loading}
              />
            )}
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default DesktopTimeLayout;
