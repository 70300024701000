import { Col, DatePicker, Form, Input, Row, Button, Select } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
// import logger from '../../logger';

const initDateEventState = {
  date_event_name: '',
  startDate: undefined,
  endDate: undefined,
  date_event_type: 0,
};

const eventTypeOptions = [
  { value: 0, label: 'יום עבודה רגיל' },
  { value: 1, label: 'חצי יום חופש' },
  { value: 2, label: 'יום חופש' },
];

const DateEventForm = ({
  dateEventDetails,
  isLoading,
  date,
  onConfirm,
  selectedDepartments,
  selectAllDepartments,
}) => {
  const isDateEventDetailsAvailable = dateEventDetails !== null;
  const [form] = Form.useForm();
  const [disabledSave, setDisabledSave] = useState(true);
  const [disabledReset, setDisabledReset] = useState(true);

  useEffect(() => {
    if (isDateEventDetailsAvailable) {
      form.setFieldsValue({
        date_event_name: dateEventDetails.event_name,
        startDate: dayjs(dateEventDetails.start_date),
        endDate: dayjs(dateEventDetails.end_date),
        date_event_type: dateEventDetails.event_type,
      });
      setDisabledSave(false);
      setDisabledReset(true);
    } else {
      form.resetFields();
      form.setFieldValue('startDate', dayjs(date));
      form.setFieldValue('endDate', dayjs(date));
      setDisabledSave(true);
      setDisabledReset(true);
    }
  }, [form, isDateEventDetailsAvailable, dateEventDetails, date]);

  const onFieldValueChanged = (_, allFields) => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    const isFilled = allValuesFilled();
    setDisabledSave(hasErrors || !isFilled);
    setDisabledReset(!isFilled && isDateEventDetailsAvailable);
  };

  const allValuesFilled = () => {
    const formValues = form.getFieldsValue();
    const { date_event_name, startDate, endDate, date_event_type } = formValues;
    return (
      date_event_name !== undefined &&
      startDate !== undefined &&
      endDate !== undefined &&
      date_event_type !== undefined
    );
  };

  const handleReset = () => {
    if (isDateEventDetailsAvailable) {
      form.setFieldsValue(dateEventDetails);
    } else {
      form.resetFields();
      setDisabledSave(true);
    }
    setDisabledReset(true);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        onConfirm({
          event_name: values.date_event_name,
          start_date: values.startDate.format('DD/MM/YYYY'),
          end_date: values.endDate.format('DD/MM/YYYY'),
          event_type: values.date_event_type,
          departments: selectedDepartments,
          id: dateEventDetails?.id,
          isAllDepartments: selectAllDepartments,
        }).then((res) => {
          //TODO:if confirmation not succeeded than do not reset the fields
          if (res) {
            form.resetFields();
            setDisabledSave(true);
            setDisabledReset(true);
          }
        });
      })
      .catch((info) => {
        // logger.info('Validate Failed: ' + info);
      });
  };

  return (
    <Form
      form={form}
      layout='vertical'
      name='dateEventForm'
      onFinish={handleOk}
      initialValues={initDateEventState}
      onFieldsChange={onFieldValueChanged}
    >
      <Row>
        <Col span={24}>
          <Form.Item
            name='date_event_name'
            label='שם האירוע'
            rules={[
              {
                min: 3,
                message: 'מינימום 3 תווים',
              },
              {
                required: true,
                message: 'נא להזין שם אירוע!',
              },
            ]}
          >
            <Input autoComplete='off' />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={12}>
        <Col
          xs={24}
          sm={12}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item
            name='startDate'
            label='תאריך התחלה'
            rules={[
              {
                required: true,
                message: 'נא לבחור תאריך התחלה',
              },
            ]}
          >
            <DatePicker className='w-100' />
          </Form.Item>
        </Col>
        <Col
          xs={24}
          sm={12}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item
            name='endDate'
            label='תאריך סיום'
            rules={[
              {
                required: true,
                message: 'נא לבחור תאריך סיום',
              },
            ]}
          >
            <DatePicker className='w-100' />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            name='date_event_type'
            label='בחר סוג'
            rules={[
              {
                required: true,
                message: 'נא לבחור סוג!',
              },
            ]}
          >
            <Select
              style={{ width: '100%' }}
              placeholder='בחר סוג...'
              virtual={false}
              options={eventTypeOptions}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row
        gutter={10}
        justify='end'
      >
        <Col>
          <Button
            type='default'
            disabled={disabledReset}
            onClick={handleReset}
          >
            {isDateEventDetailsAvailable ? 'איפוס' : 'ניקוי'}
          </Button>
        </Col>
        <Col>
          <Button
            type='primary'
            htmlType='submit'
            disabled={disabledSave || selectedDepartments.length === 0}
            loading={isLoading}
          >
            אישור
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default DateEventForm;
