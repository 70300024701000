import axios from 'axios';
import config from '../config';

export const getAllProjects = async (token) => {
  return await axios.get(`${config.API_URL}/projects`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const createNewProject = async (projectDetails, token) => {
  return await axios.post(`${config.API_URL}/project`, projectDetails, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateExistingProject = async (projectDetails, id, token) => {
  return await axios.put(`${config.API_URL}/project/${id}`, projectDetails, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getProjectsByCurrentUser = async (token) => {
  return await axios.get(`${config.API_URL}/projects/user-projects`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getProjectsForReporting = async (token) => {
  return await axios.get(`${config.API_URL}/projects/reports-projects`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
