import Title from 'antd/es/typography/Title';
import React, { useState } from 'react';
import RoleTable from '../tables/RoleTable';
import { useSelector } from 'react-redux';
import { getAuthState } from '../../utils';
import useRoleManagement from '../../hooks/useRoleManagement';
import { toast } from 'react-toastify';
import CustomModal from '../modals/CustomModal';
import RoleForm from '../forms/RoleForm';
import usePermissionManagement from '../../hooks/usePermissionManagement';
import authWrapper from '../../utils/authWrapper';
import Meta from '../Meta';

const ManageRoles = () => {
  const { token } = useSelector((state) => getAuthState(state));
  const [editedRole, setEditedRole] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { roles, loading, createRole, updateRole } = useRoleManagement(token);
  const { permissions } = usePermissionManagement(token);

  const handleEditRole = (roles) => {
    setEditedRole(roles);
    handleToggleModal();
  };

  const handleCreateRole = (data) => {
    return createRole(data).then((success) => {
      if (success) {
        toast.success('התפקיד התווספה בהצלחה');
        setEditedRole(null);
        handleToggleModal();
        return true;
      } else {
        return false;
      }
    });
  };

  const handleUpdate = (data) => {
    return updateRole(data).then((success) => {
      if (success) {
        toast.success('עדכון התפקיד התבצע בהצלחה!');
        setEditedRole(null);
        handleToggleModal();
        return true;
      } else {
        return false;
      }
    });
  };

  const handleToggleModal = () => {
    setIsModalOpen((state) => !state);
  };

  const handleCancelModal = () => {
    setEditedRole(null);
    handleToggleModal?.();
  };

  return (
    <div className='container m-2'>
      <Meta
        title={'ניהול תפקידים'}
        description={'מסך ניהול תפקידים'}
      />
      <Title
        level={1}
        className='text-center'
      >
        ניהול תפקידים
      </Title>
      <CustomModal
        modalTitle={editedRole ? 'עדכון התפקיד' : 'יצירת תפקיד חדש'}
        isModalOpen={isModalOpen}
        handleCancel={handleCancelModal}
        width={'500px'}
      >
        <RoleForm
          roleDetails={editedRole}
          isLoading={loading}
          onConfirm={editedRole ? handleUpdate : handleCreateRole}
          permissions={permissions}
        />
      </CustomModal>
      <RoleTable
        roles={roles.map((roles) => ({ ...roles, key: roles.id }))}
        loading={loading}
        handleEditRole={handleEditRole}
        handleToggleModal={handleToggleModal}
        permissions={permissions}
      />
    </div>
  );
};

export default authWrapper('roles')(ManageRoles);
