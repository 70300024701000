import dayjs from 'dayjs';
import { convertTimeToDecimal, minutesToTimeString } from '.';
import {
  formatReportItem,
  escapeSpecialChars,
  calculateTotalMinutes,
  detailedReportHeaders,
  getSummarizedHeaders,
  missingReportsHeaders,
} from './reportsHelpers';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';

// Create CSV content from headers and data
const createCSVContent = (headers, data) => {
  const headerRow = headers.map((header) => header.label || header.key).join(',');
  const dataRows = data
    .map((row) => headers.map((header) => escapeSpecialChars(`${row[header.key] ?? ''}`)).join(','))
    .join('\n');
  return `${headerRow}\n${dataRows}`;
};

const prepareReportsCSVData = (name, serialized_reports, is_summarized, sortingOption) => {
  const summarizedHeaders = getSummarizedHeaders(sortingOption);
  const mappedReports = !is_summarized
    ? serialized_reports.flatMap(({ reports }) => reports.map((report) => formatReportItem(report)))
    : serialized_reports.map(({ key, totalDuration }) => ({
        sorted_key: key,
        totalDuration,
      }));
  const totalMinutes = calculateTotalMinutes(serialized_reports);
  const totalTimeString = minutesToTimeString(totalMinutes);
  const footerData = [
    {
      reportDate: 'סה"כ',
      totalDuration: totalTimeString,
      totalDurationDecimal: convertTimeToDecimal(totalTimeString),
    },
  ];

  const csvContent = `\uFEFF${createCSVContent(is_summarized ? summarizedHeaders : detailedReportHeaders, [
    ...mappedReports,
    ...footerData,
  ])}`;
  const filename = `${dayjs().format('YYYY-MM-DD')}-${is_summarized ? 'מסוכם' : 'מפורט'} - ${name}.csv`;
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
  saveAs(blob, filename);
};

const prepareMissingReportsCSVData = (missingReports) => {
  const csvContent = `\uFEFF${createCSVContent(missingReportsHeaders, [...missingReports])}`;
  const filename = `${dayjs().format('YYYY-MM-DD')}-דוח חוסרים.csv`;
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
  saveAs(blob, filename);
};

export const downloadProjectReportsToCSV = (reports) => {
  if (!reports || !reports.reportItems) return;

  try {
    reports.reportItems.forEach(({ project_name, serialized_reports, is_summarized }) => {
      prepareReportsCSVData(project_name, serialized_reports, is_summarized, reports.sortingOption);
    });
  } catch (error) {
    toast.error('קרתה שגיאה בעת יצירת קובץ הCSV');
    console.error('Error generating CSV report:', error);
  }
};

export const downloadMissingReportsToCSV = (missingReports) => {
  if (!missingReports) return;

  try {
    prepareMissingReportsCSVData(missingReports);
  } catch (error) {
    toast.error('קרתה שגיאה בעת יצירת קובץ הCSV');
    console.error('Error generating CSV report:', error);
  }
};

export const downloadDepartmentReportsToCSV = (reports) => {
  if (!reports || !reports.reportItems) return;

  try {
    reports.reportItems.forEach(({ project_name, department_name, serialized_reports, is_summarized }) => {
      prepareReportsCSVData(
        department_name ? department_name : project_name,
        serialized_reports,
        is_summarized,
        reports.sortingOption
      );
    });
  } catch (error) {
    toast.error('קרתה שגיאה בעת יצירת קובץ הCSV');
    console.error('Error generating CSV report:', error);
  }
};
