import Title from 'antd/es/typography/Title';
import React, { useEffect, useState } from 'react';
import ProjectsTable from '../tables/ProjectsTable';
import { useSelector } from 'react-redux';
import { getAuthState } from '../../utils';
import useProjectManagement from '../../hooks/useProjectManagement';
import { toast } from 'react-toastify';
import CustomModal from '../modals/CustomModal';
import ProjectForm from '../forms/ProjectForm';
import useUserManagement from '../../hooks/useUserManagement';
import authWrapper from '../../utils/authWrapper';
import Meta from '../Meta';

const ManageProjects = () => {
  const { token } = useSelector((state) => getAuthState(state));
  const [editedProject, setEditedProject] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { projects, loading, loadProjects, createProject, updateProject } = useProjectManagement(token);
  const { users } = useUserManagement(token);

  const handleEditProject = (account) => {
    setEditedProject(account);
    handleToggleModal();
  };

  const handleCreate = (data) => {
    createProject(data).then((success) => {
      if (success) {
        toast.success('הפרויקט התווסף בהצלחה');
        setEditedProject(null);
        handleToggleModal();
      } else {
      }
    });
  };

  const handleUpdate = (data) => {
    updateProject(data).then((success) => {
      if (success) {
        toast.success('עדכון הפרויקט התבצע בהצלחה!');
        setEditedProject(null);
        handleToggleModal();
      } else {
      }
    });
  };

  const handleToggleModal = () => {
    setIsModalOpen((state) => !state);
  };

  useEffect(() => {
    loadProjects();
  }, [loadProjects]);

  const handleCancelModal = () => {
    setEditedProject(null);
    handleToggleModal?.();
  };

  return (
    <div className='container m-2'>
      <Meta
        title={'ניהול פרויקטים'}
        description={'מסך ניהול פרויקטים'}
      />
      <Title
        level={1}
        className='text-center'
      >
        ניהול פרויקטים
      </Title>
      <CustomModal
        modalTitle={editedProject ? 'עדכון פרויקט' : 'יצירת פרויקט חדש'}
        isModalOpen={isModalOpen}
        handleCancel={handleCancelModal}
        width={'500px'}
      >
        <ProjectForm
          projectDetails={editedProject}
          isLoading={loading}
          onConfirm={editedProject ? handleUpdate : handleCreate}
          users={users.filter((user) => user.active)}
        />
      </CustomModal>
      <ProjectsTable
        projects={projects.map((project) => ({ ...project, key: project.id }))}
        loading={loading}
        handleEditProject={handleEditProject}
        handleToggleModal={handleToggleModal}
      />
    </div>
  );
};

export default authWrapper('projects')(ManageProjects);
