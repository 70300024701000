import React from 'react';
import authWrapper from '../../utils/authWrapper';
import Title from 'antd/es/typography/Title';
import Meta from '../Meta';

const AdministrationMain = () => {
  return (
    <div className='container m-2'>
      <Meta
        title={'ניהול'}
        description={'מסך ניהול ראשי'}
      />
      <Title
        level={1}
        className='text-center'
      >
        ניהול KTM
      </Title>
    </div>
  );
};

export default authWrapper('administration')(AdministrationMain);
