import { EditOutlined, SearchOutlined, SwapOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { FaBan, FaCheck } from 'react-icons/fa6';
import TooltipButton from '../../components/buttons/TooltipButton';
import Highlighter from 'react-highlight-words';
import { IoDocumentAttachOutline } from 'react-icons/io5';
import { DocumentModeDictionary } from '../../utils/handleDocumentMode';

const ActivityTable = ({ activities, loading, handleEditActivity, handleToggleModal, projects }) => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [projectOptions, setProjectOptions] = useState([]);

  useEffect(() => {
    if (projects?.length > 0) {
      setProjectOptions(
        projects.map((project) => ({
          value: project.id,
          text: project.project_name,
        }))
      );
    }
  }, [projects]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    setSearchText('');
    confirm();
  };

  const renderAttachmentIcon = (document_mode) => {
    switch (DocumentModeDictionary[document_mode]) {
      case DocumentModeDictionary.OPTIONAL:
        return <IoDocumentAttachOutline className='text-warning' />;
      case DocumentModeDictionary.MANDATORY:
        return <IoDocumentAttachOutline className='text-danger' />;
      default:
        return;
    }
  };

  const getColumnSearchProps = (dataIndex, title = '') => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`חיפוש לפי ${title}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{
              width: 90,
            }}
          >
            חיפוש
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size='small'
            style={{
              width: 90,
            }}
          >
            איפוס
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: 'קוד',
      dataIndex: 'code',
      key: 'code',
      width: '5%',
      ...getColumnSearchProps('code', 'קוד'),
      sorter: (a, b) => a.code - b.code,
      sortDirections: ['ascend', 'descend'],
      defaultSortOrder: 'ascend',
    },
    {
      title: 'פעילות',
      dataIndex: 'activity_name',
      key: 'activity_name',
      ...getColumnSearchProps('activity_name', 'שם פעילות'),
      render: (_, record) => (
        <div>
          <Space>
            {record.activity_name}
            {renderAttachmentIcon(record.document_mode)}
          </Space>
        </div>
      ),
      sorter: (a, b) => a.activity_name.localeCompare(b.activity_name),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'פרויקט',
      dataIndex: 'project_name',
      key: 'project_name',
      filters: projectOptions,
      render: (_, record) => record.project.project_name,
      onFilter: (value, record) => {
        return record.project.id === value;
      },
    },
    {
      title: 'פעיל?',
      dataIndex: 'active',
      key: 'active',
      width: '5%',
      filters: [
        {
          text: 'פעיל',
          value: true,
        },
        {
          text: 'לא פעיל',
          value: false,
        },
      ],
      onFilter: (value, record) => {
        return record.active === value;
      },
      render: (isActive) => (
        <div className='d-flex justify-content-center'>
          {isActive === true ? <FaCheck className='text-success' /> : <FaBan className='text-danger' />}
        </div>
      ),
    },
    {
      title: 'פעולות',
      key: 'actions',
      width: '10%',
      render: (_, record) => (
        <Space size='middle'>
          <TooltipButton
            placement='top'
            type='text'
            title='ערוך פעילות'
            icon={<EditOutlined />}
            onClick={() => handleEditActivity(record)}
          ></TooltipButton>
        </Space>
      ),
    },
  ];

  return (
    <Table
      title={() => (
        <div>
          <Button
            type='primary'
            shape='square'
            onClick={handleToggleModal}
            icon={<SwapOutlined />}
          >
            הוספה
          </Button>
        </div>
      )}
      columns={columns}
      dataSource={activities}
      loading={loading}
      scroll={{ x: 500 }}
      pagination={{
        pageSize: 8,
        hideOnSinglePage: true,
        position: ['bottomCenter'],
      }}
    />
  );
};

export default ActivityTable;
