import { useCallback, useState } from 'react';
import { createNewAccount, getAllAccounts, updateExistingAccount } from '../services/accounts';
import logger from '../logger';
import { handleError } from '../utils/handleErrors';

const useAccountManagement = (initialToken) => {
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [token, setToken] = useState(initialToken);

  // load all accounts
  const loadAccounts = useCallback(() => {
    setLoading(true);
    getAllAccounts(token)
      .then((res) => {
        setAccounts(res.data);
      })
      .catch((error) => {
        const parsedError = handleError(error);
        logger.error(error, true);
        // toast.error(parsedError.toastMessage);
        setError(parsedError);
      })
      .finally(() => setLoading(false));
  }, [token]);

  const createAccount = (data) => {
    setLoading(true);
    return createNewAccount(data, token)
      .then(() => {
        loadAccounts();
        return true;
      })
      .catch((error) => {
        const parsedError = handleError(error);
        logger.error(error, true);
        // toast.error(parsedError.toastMessage);
        setError(parsedError);
        return false;
      })
      .finally(() => setLoading(false));
  };

  const updateAccount = (data) => {
    const { id, ...restData } = data;
    setLoading(true);

    return updateExistingAccount(restData, id, token)
      .then(() => {
        loadAccounts();
        return true;
      })
      .catch((error) => {
        const parsedError = handleError(error);
        logger.error(error, true);
        // toast.error(parsedError.toastMessage);
        setError(parsedError);
        return false;
      })
      .finally(() => setLoading(false));
  };

  const setAuthToken = (token) => {
    setToken(token);
  };

  return {
    accounts,
    loading,
    error,
    loadAccounts,
    createAccount,
    updateAccount,
    setAuthToken,
  };
};

export default useAccountManagement;
