import axios from 'axios';
import config from '../config';

export const getAllActivities = async (token) => {
  return await axios.get(`${config.API_URL}/activities`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const createNewActivity = async (activityDetails, token) => {
  return await axios.post(`${config.API_URL}/activity`, activityDetails, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateExistingActivity = async (activityDetails, id, token) => {
  return await axios.put(`${config.API_URL}/activity/${id}`, activityDetails, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getActivitiesByProjectId = async (projectId, token) => {
  return await axios.get(`${config.API_URL}/activities/project/${projectId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
