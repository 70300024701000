import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Layout } from 'antd';
import AdministrationNav from '../../components/nav/AdministrationNav';
import AdministrationMain from '../../components/administration/AdministrationMain';
import ManageUsers from '../../components/administration/ManageUsers';
import ManageProjects from '../../components/administration/ManageProjects';
import ManageDepartments from '../../components/administration/ManageDepartments';
import ManageActivities from '../../components/administration/ManageActivities';
import ManageLocations from '../../components/administration/ManageLocations';
import ManageRoles from '../../components/administration/ManageRoles';
import ManagePermissions from '../../components/administration/ManagePermissions';
import ManageDateEvents from '../../components/administration/ManageDateEvents';
// import ManageAccounts from '../../components/administration/ManageAccounts';

const { Sider, Content } = Layout;

const Administration = () => {
  const [selectedIndex, setSelectedIndex] = useState('');
  const location = useLocation();

  const handleListItemClick = (e) => {
    setSelectedIndex(e.key);
  };

  const handleNavigation = (selectedIndex) => {
    switch (selectedIndex) {
      // case 'accounts':
      //   return <ManageAccounts />;
      case 'departments':
        return <ManageDepartments />;
      case 'users':
        return <ManageUsers />;
      case 'projects':
        return <ManageProjects />;
      case 'activities':
        return <ManageActivities />;
      case 'locations':
        return <ManageLocations />;
      case 'roles':
        return <ManageRoles />;
      case 'permissions':
        return <ManagePermissions />;
      case 'date-events':
        return <ManageDateEvents />;
      default:
        return <AdministrationMain />;
    }
  };

  useEffect(() => {
    setSelectedIndex(location?.pathname.split('administration/')[1]);
  }, [location?.pathname]);

  return (
    <Layout>
      <Sider
        // style={{ maxHeight: "5vh" }}
        theme='light'
        breakpoint='lg'
        // collapsedWidth="60"
        collapsedWidth='0'
        // width={215}
      >
        <AdministrationNav
          selectedIndex={selectedIndex}
          handleListItemClick={handleListItemClick}
        />
      </Sider>
      <Content style={{ overflow: 'auto' }}>{handleNavigation(selectedIndex)}</Content>
    </Layout>
  );
};

export default Administration;
