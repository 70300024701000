import { INIT_DATE_EVENTS, LOAD_DATE_EVENTS } from '../actions/types';

const initDateEvents = [];

export const dateEventsReducer = (state = initDateEvents, { type, payload }) => {
  switch (type) {
    case LOAD_DATE_EVENTS:
      return payload;
    case INIT_DATE_EVENTS:
      return initDateEvents;
    default:
      return state;
  }
};
