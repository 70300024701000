import log from 'loglevel';
import { toast } from 'react-toastify';
import { handleError } from './utils/handleErrors';

const env = process.env.NODE_ENV || 'development';
const logLevel = env === 'production' ? 'warn' : process.env.REACT_APP_LOG_LEVEL || 'debug';

const logger = log;
logger.setLevel(logLevel);

const originalFactory = logger.methodFactory;

logger.methodFactory = (methodName, logLevel, loggerName) => {
  const rawMethod = originalFactory(methodName, logLevel, loggerName);
  return (message, showToast = false) => {
    const timestamp = new Date().toISOString();
    const formattedMessage = message instanceof Error ? `${message.message}\n${message.stack}` : message;

    rawMethod(`[${timestamp}] [${methodName.toUpperCase()}]`, formattedMessage);

    if (showToast) {
      if (methodName === 'error') {
        const parsedError = handleError(message);
        if (toast.isActive(parsedError.errorCode)) {
          toast.update(parsedError.errorCode, {
            render: parsedError.toastMessage,
            type: 'error',
          });
        } else {
          toast.error(parsedError.toastMessage, {
            toastId: parsedError.errorCode,
            autoClose: 8000,
          });
        }
      } else if (methodName === 'info') {
        toast.info(formattedMessage, {
          autoClose: 5000,
        });
      }
    }
  };
};

logger.setLevel(logLevel);

export default logger;
