import React, { useEffect, useState } from 'react';
import { Button, Result } from 'antd';
import { useSelector } from 'react-redux';
import { getAuthState } from '../utils';

const AdminRoute = ({ Component, ...rest }) => {
  const { user } = useSelector((state) => getAuthState(state));
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    setIsAdmin(user ? user.is_admin : false);
  }, [user]);

  if (isAdmin) {
    return <Component {...rest} />;
  } else {
    return (
      user &&
      !user.is_admin && (
        <Result
          status='403'
          title='403'
          subTitle='אין לך הרשאה לגשת לדף זה.'
          extra={
            <Button
              type='primary'
              onClick={() => (window.location.href = '/')}
            >
              למסך הראשי
            </Button>
          }
        />
      )
    );
  }
};

export default AdminRoute;
