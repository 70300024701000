import React from 'react';
import { ConfigProvider, DatePicker } from 'antd';
import { styled } from 'styled-components';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;

const StyledRangePicker = styled(RangePicker)`
  .ant-picker-range-separator {
    transform: scaleX(-1);
  }
`;

const lastWeekStart = dayjs().subtract(1, 'week').startOf('week').add(0, 'day');
const lastWeekEnd = dayjs().subtract(1, 'week').endOf('week').subtract(2, 'day');
const todayDay = dayjs().day();
const lastWeekPreset = {
  label: 'שבוע שעבר',
  value:
    todayDay === 5 || todayDay === 6
      ? [dayjs().startOf('week'), dayjs().endOf('week').subtract(2, 'day')]
      : [lastWeekStart, lastWeekEnd],
};
const currentMonthPreset = {
  label: 'החודש הנוכחי',
  value: [dayjs().startOf('month'), dayjs()],
};
const previousMonthPreset = {
  label: 'חודש שעבר',
  value: [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
};

const presets = [
  {
    label: 'היום',
    value: [dayjs(), dayjs()],
  },
  lastWeekPreset,
  currentMonthPreset,
  previousMonthPreset,
];

const RTLRangePicker = ({ className, format, onChange, placeholder, showPresets }) => {
  return (
    <ConfigProvider direction='ltr'>
      <StyledRangePicker
        presets={showPresets ? presets : null}
        className={className}
        format='DD/MM/YYYY'
        onChange={onChange}
        placeholder={placeholder}
      />
    </ConfigProvider>
  );
};

export default RTLRangePicker;
