import axios from 'axios';
import config from '../config';

// export const getDateEventsByDate = async (selectedDate, token) => {
//   return await axios.get(`${config.API_URL}/date-events/by-date`, selectedDate, {
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   });
// };

export const getHebrewCalender = async (month, year) => {
  return await axios.get(
    `https://www.hebcal.com/hebcal?v=1&cfg=json&maj=on&min=on&mod=on&year=${year ?? 'now'}&month=${
      month ?? 'x'
    }&mf=on`
  );
};

export const getDateEventsByDate = async (request, token) => {
  return await axios.post(`${config.API_URL}/date-events/by-request`, request, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const createNewDateEvent = async (eventDetails, token) => {
  return await axios.post(`${config.API_URL}/date-event`, eventDetails, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateExistingDateEvent = async (eventDetails, id, token) => {
  return await axios.put(`${config.API_URL}/date-event/${id}`, eventDetails, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteExistingDateEvent = async (deleteEventDetails, token) => {
  return await axios.delete(`${config.API_URL}/date-event`, {
    data: deleteEventDetails,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
