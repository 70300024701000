import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button, Result, Spin } from 'antd';
import { checkUserPermissions } from '../services/auth';
import logger from '../logger';

const authWrapper = (requiredResource) => (WrappedComponent) => {
  return (props) => {
    const { token } = useSelector((state) => state.auth);
    const [authorized, setAuthorized] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      if (token) {
        checkUserPermissions(token, requiredResource)
          .then((res) => {
            if (res.data?.authorized && res.status === 200) {
              setAuthorized(true);
            } else {
              setAuthorized(false);
            }
            setLoading(false);
          })
          .catch((error) => {
            logger.error(error);
            setAuthorized(false);
            setLoading(false);
          });
      }
    }, [token]);

    if (loading) {
      return (
        <div className=' p-5 text-center'>
          <Spin />
        </div>
      );
    }

    if (authorized) {
      return <WrappedComponent {...props} />;
    } else {
      return (
        <Result
          status='403'
          title='403'
          subTitle='אין לך הרשאה לגשת לדף זה.'
          extra={
            <Button
              type='primary'
              onClick={() => (window.location.href = '/')}
            >
              למסך הראשי
            </Button>
          }
        />
      );
    }
  };
};

export default authWrapper;
