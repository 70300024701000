import { useCallback, useEffect, useState } from 'react';
import { createNewDepartment, getAllDepartments, updateExistingDepartment } from '../services/departments.js';
import logger from '../logger.js';
import { handleError } from '../utils/handleErrors.js';
import { fetchUsersByDepartmentIds } from '../services/users.js';

const useDepartmentManagement = (initialToken) => {
  const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [token, setToken] = useState(initialToken);

  // load all departments
  const loadDepartments = useCallback(() => {
    setLoading(true);
    getAllDepartments(token)
      .then((res) => {
        setDepartments(res.data);
      })
      .catch((error) => {
        const parsedError = handleError(error);
        logger.error(error, true);
        // toast.error(parsedError.toastMessage);
        setError(parsedError);
      })
      .finally(() => setLoading(false));
  }, [token]);

  useEffect(() => {
    loadDepartments();
  }, [loadDepartments]);

  const createDepartment = (data) => {
    setLoading(true);
    const departmentName = data.department_name;
    return createNewDepartment(data, token)
      .then(() => {
        loadDepartments();
        return true;
      })
      .catch((error) => {
        const parsedError = handleError(error, departmentName);
        logger.error(error, true);
        // toast.error(parsedError.toastMessage);
        setError(parsedError);
        return false;
      })
      .finally(() => setLoading(false));
  };

  const updateDepartment = (data) => {
    const { id, ...restData } = data;
    setLoading(true);
    const departmentName = data.department_name;
    return updateExistingDepartment(restData, id, token)
      .then(() => {
        loadDepartments();
        return true;
      })
      .catch((error) => {
        const parsedError = handleError(error, departmentName);
        logger.error(error, true);
        // toast.error(parsedError.toastMessage);
        setError(parsedError);
        return false;
      })
      .finally(() => setLoading(false));
  };

  const getUsersBySelectedDepartments = useCallback(
    (departments) => {
      setLoading(true);
      return fetchUsersByDepartmentIds(departments, token)
        .then((res) => {
          const data = res.data;
          const users = Array.isArray(data) ? data : [data];
          return users;
        })
        .catch((error) => {
          const parsedError = handleError(error);
          logger.error(error, true);
          // toast.error(parsedError.toastMessage);
          setError(parsedError);
          return false;
        })
        .finally(() => setLoading(false));
    },
    [token]
  );

  const setAuthToken = (token) => {
    setToken(token);
  };

  return {
    departments,
    loading,
    error,
    createDepartment,
    updateDepartment,
    getUsersBySelectedDepartments,
    setAuthToken,
  };
};

export default useDepartmentManagement;
