export const errorCodes = {
  1: 'חשבון קיים',
  2: 'פעילויות לא נמצאו!',
  3: 'פעילות לא נמצאה!',
  4: (name) => `פעילות עם השם '${name}' כבר קיימת בפרויקט זה`,
  5: 'לא ניתן לשנות תפקיד מנהל!',
  6: 'אירועי תאריך לא נמצאו!',
  7: 'מחלקה לא נמצאה!',
  8: (name) => `מחלקה עם השם '${name}' כבר קיימת`,
  9: 'מחלקות לא נמצאו!',
  10: 'כתובת האימייל תפוסה',
  11: 'התרחשה שגיאה בשרת', // General error (Exception)
  12: 'קובץ לא נמצא!',
  13: 'טווח שעות לא תקין!',
  14: 'שם משתמש/סיסמה לא תקינים',
  15: 'מיקום לא נמצא!',
  16: (name) => `מיקום עם השם '${name}' כבר קיים`,
  17: 'מיקומים לא נמצאו!',
  18: 'הרשאה נדחתה!',
  19: 'הרשאה לא נמצאה!',
  20: (name) => `הרשאה עם השם '${name}' כבר קיימת`,
  21: 'הרשאות לא נמצאו!',
  22: 'פרויקט לא נמצא',
  23: (name) => `פרויקט עם השם '${name}' כבר קיים`,
  24: 'פרויקטים לא נמצאו',
  25: 'דוח לא נמצא!',
  26: 'דוחות לא נמצאו!',
  27: 'תפקיד לא נמצא!',
  28: (name) => `תפקיד עם השם '${name}' כבר קיים`,
  29: 'תפקידים לא נמצאו!',
  30: 'שגיאה בשדות שהוזנו',
  31: 'דו"ח מועדף לא נמצא',
  32: (name) => `דו"ח מועדף עם השם '${name}' כבר קיים`,
  33: 'דו"ח מועדף לא נמצא',
  34: 'טווח התאריכים הזה לא חוקי!',
  35: 'טווח הזמן של הדוח הזה לא חוקי',
  36: 'טווח הזמן של הדוח הזה חופף עם קיים',
  37: 'שם משתמש תפוס',
  38: 'משתמש לא נמצא!',
  39: 'משתמשים לא נמצאו!',
  40: 'אירוע תאריך עם פרטים זהים כבר קיים',
  41: 'חשבון לא נמצא!',
  42: 'חשבונות לא נמצאו!',
  43: 'אירוע התאריך אינו גלובלי ולכן לא ניתן למחוק אותו',
  44: 'נתונים לא חוקיים למשיכת דוחות שעון',
  45: 'דוחות שעון לא נמצאו!',
  46: 'ATT ID תפוס',
  47: 'לא ניתן למצוא פרוייקטים המשוייכים למשתמש המחובר',
  48: 'התאריך שהוזן לא תקין',
  49: 'לא ניתן למצוא העדפות למשתמש זה',
  50: 'משתמש לא פעיל, נא לפנות למנהל',
};

const isFunction = (error) => {
  return typeof error === 'function';
};

export const handleError = (requestError, name = undefined) => {
  const statusCode = requestError?.response?.status ?? 500;
  const errorMessage = requestError?.response?.data.message ?? 'General error occurd';
  const errorCode = requestError?.response?.data.error_code ?? 11;

  const error = {
    message: errorMessage,
    status: statusCode,
    errorCode,
    toastMessage: isFunction(errorCodes[errorCode]) ? errorCodes[errorCode](name) : errorCodes[errorCode],
  };

  return error;
};
