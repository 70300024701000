import axios from 'axios';
import config from '../config';

export const getAllRoles = async (token) => {
  return await axios.get(`${config.API_URL}/roles`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const createNewRole = async (roleDetails, token) => {
  return await axios.post(`${config.API_URL}/role`, roleDetails, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateExistingRole = async (roleDetails, id, token) => {
  return await axios.put(`${config.API_URL}/role/${id}`, roleDetails, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
