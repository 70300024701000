import React from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, Popconfirm, message } from 'antd';
import Title from 'antd/es/typography/Title';
// import { Link } from 'react-router-dom';
import { RiLockPasswordLine } from 'react-icons/ri';

const LoginForm = ({ handleLogin }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const handleSubmit = ({ username, password }) => {
    handleLogin(username, password);
  };

  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'כל הכבוד, גאים בך!',
      duration: 5,
    });
  };

  const cancel = () => {
    messageApi.open({
      type: 'error',
      content: 'לא נורא, תפנו לתמיכה',
      duration: 5,
    });
  };

  return (
    <div
      className='w-100'
      style={{ maxWidth: '300px' }}
    >
      <Title
        level={1}
        className='text-center'
      >
        התחברות
      </Title>
      <Form
        name='login_form'
        initialValues={{
          remember: true,
        }}
        onFinish={handleSubmit}
      >
        <Form.Item
          name='username'
          rules={[
            {
              required: true,
              message: 'נא להזין שם משתמש!',
            },
          ]}
        >
          <Input
            prefix={<UserOutlined className='site-form-item-icon' />}
            placeholder='שם משתמש'
            allowClear
          />
        </Form.Item>
        <Form.Item
          name='password'
          rules={[
            {
              required: true,
              message: 'נא להזין סיסמה!',
            },
          ]}
        >
          <Input.Password
            prefix={<LockOutlined className='site-form-item-icon' />}
            placeholder='סיסמה'
          />
        </Form.Item>

        <Form.Item>
          <Button
            type='primary'
            block
            htmlType='submit'
            className='login-form-button'
          >
            התחברות
          </Button>
          <Popconfirm
            placement='topRight'
            icon={<RiLockPasswordLine />}
            description={<div className='p-2'>נסו להיזכר חזק יותר</div>}
            okText='נזכרתי'
            cancelText={'קשה לי'}
            onConfirm={success}
            onCancel={cancel}
          >
            <Button
              type='link'
              className='ps-1'
              // to='/forgot-password'
            >
              שכחתי סיסמה
            </Button>
          </Popconfirm>
        </Form.Item>
      </Form>
      {contextHolder}
    </div>
  );
};

export default LoginForm;
