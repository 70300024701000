import React from 'react';
import { Upload, message, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const UploadDocument = ({ onChange, fileList, name }) => {
  const props = {
    name,
    multiple: false,
    fileList,
    beforeUpload: (file) => {
      const fileExtenstion = file.name.split('.')?.slice(-1)[0];
      const isValidFile =
        fileExtenstion === 'docx' ||
        fileExtenstion === 'doc' ||
        fileExtenstion === 'pdf' ||
        fileExtenstion === 'png' ||
        fileExtenstion === 'jpeg';
      if (!isValidFile) {
        message.error(`${file.name} לא בפורמט המתאים`);
        // return false;
      }
      return isValidFile || Upload.LIST_IGNORE;
    },
    onChange,
    maxCount: 1,
    customRequest: (option) => {
      const { onSuccess } = option;
      onSuccess('The file has been added');
    },
    listType: 'text',
  };
  return (
    <Upload
      {...props}
      className='upload-list-inline'
    >
      <Button
        type='primary'
        icon={<UploadOutlined />}
      >
        בחר קובץ
      </Button>
    </Upload>
  );
};

export default UploadDocument;
