import { Popconfirm, Card, Button, List, Row, Col, Typography, Collapse } from 'antd';
import React, { useEffect, useState } from 'react';
import { CopyOutlined, DeleteOutlined, EditOutlined, PaperClipOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';
import { calculateDiffTime } from '../../utils';
import duration from 'dayjs/plugin/duration';
dayjs.extend(duration);

dayjs.extend(utc);
dayjs.extend(relativeTime);

const MobileReportItem = ({
  timeReports,
  handleDownload,
  handleEditTimeReport,
  handleDeleteTimeReport,
  handleDuplicateTimeReport,
  loading,
}) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(timeReports.map((reports, index) => ({ ...reports, key: index })));
  }, [timeReports]);

  const handleShowAttachedFile = async (item) => {
    //Show/Download file
    await handleDownload(item.file_id);
  };

  const parseComments = (comments) => {
    // Split the string into an array of rows
    const rows = comments.split('\n');

    // Map over the array to generate JSX elements for each row
    const content = rows.map((row, index) => (
      <p
        key={index}
        className='m-0'
      >
        {row}
      </p>
    ));

    return content;
  };
  const renderActions = (item) => {
    const actionItems = [
      <Button
        type='text'
        icon={<EditOutlined />}
        onClick={() => handleEditTimeReport(item)}
      />,
      <Button
        type='text'
        icon={<CopyOutlined className='text-primary' />}
        onClick={() => handleDuplicateTimeReport(item)}
      />,
      <Popconfirm
        title='מחיקת דיווח'
        description='האם למחוק דיווח?'
        placement='topLeft'
        onConfirm={() => handleDeleteTimeReport(item)}
        okButtonProps={{
          loading: loading.delete,
        }}
      >
        <Button
          type='text'
          icon={<DeleteOutlined className='text-danger' />}
        />
      </Popconfirm>,
    ];

    if (item?.file_id) {
      actionItems.push(
        <Button
          type='text'
          // className={item?.file_id ? '' : 'collapse'}
          icon={<PaperClipOutlined />}
          onClick={() => handleShowAttachedFile(item)}
        />
      );
    }
    return actionItems;
  };

  const renderItemCard = (item) => {
    const id = item?.id;
    const project_name = item?.project.project_name;
    const activity_name = item?.activity.activity_name;
    const location_name = item?.location.location_name;
    const in_time_format = dayjs(item.in_time).format('HH:mm');
    const out_time_format = dayjs(item.out_time).format('HH:mm');
    const time_diff = calculateDiffTime(item.in_time, item.out_time).format('H:mm');
    return (
      <List.Item
        className='p-0'
        key={id}
      >
        <Card
          bordered={false}
          actions={id && [...renderActions(item)]}
        >
          <Row justify={'space-between'}>
            <Col>
              <Typography.Title level={5}>{`${project_name}`}</Typography.Title>
            </Col>
          </Row>
          <Row justify={'space-between'}>
            {id !== 0 ? (
              <>
                <Col>
                  <Typography.Text>{`${activity_name} - ${location_name}`}</Typography.Text>
                </Col>
                <Col>
                  <Typography.Text>{`${in_time_format} - ${out_time_format}`}</Typography.Text>
                  <br />
                  <Typography.Text strong>{`(${time_diff} סה"כ)`}</Typography.Text>
                </Col>
              </>
            ) : (
              <>
                <Col>
                  <Typography.Text>{`${activity_name}`}</Typography.Text>
                </Col>
                <Typography.Text strong>{item.details}</Typography.Text>
              </>
            )}
          </Row>
          {item.comments && id !== 0 && (
            <List.Item.Meta
              className='mt-1'
              description={
                <Col span={24}>
                  <Collapse
                    accordion
                    size='small'
                    items={[
                      {
                        key: 1,
                        label: 'פירוט',
                        children: <Typography.Paragraph>{parseComments(item.comments)}</Typography.Paragraph>,
                      },
                    ]}
                  ></Collapse>
                </Col>
              }
            />
          )}
        </Card>
        {id === 0 && <List.Item.Meta description={parseComments(item.comments)} />}
      </List.Item>
    );
  };

  return (
    <List
      className='m-0'
      // className='mx-3 my-2'
      dataSource={data}
      itemLayout='vertical'
      renderItem={(item, index) => renderItemCard(item)}
    />
  );
};

export default MobileReportItem;
