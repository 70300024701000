import { Button, Checkbox, Col, DatePicker, Form, Input, InputNumber, Row, Select } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import logger from '../../logger';

const initProjectState = {
  project_name: '',
  code: undefined,
  description: '',
  range: undefined,
  manager_id: undefined,
  active: true,
};

const ProjectForm = ({ projectDetails, isLoading, onConfirm, users }) => {
  const isProjectDetailsAvailable = projectDetails !== null;
  const [form] = Form.useForm();
  const [disabledSave, setDisabledSave] = useState(true);
  const [disabledReset, setDisabledReset] = useState(true);
  const [managersOptions, setManagersOptions] = useState([]);

  useEffect(() => {
    if (isProjectDetailsAvailable) {
      form.setFieldsValue({
        project_name: projectDetails.project_name,
        active: projectDetails.active,
        code: projectDetails.code,
        description: projectDetails.description ? projectDetails.description : '',
        manager_id: projectDetails.manager.id,
        startDate: dayjs(projectDetails.start_date),
      });
      setDisabledSave(false);
      setDisabledReset(true);
    } else {
      form.resetFields();
      setDisabledSave(true);
      setDisabledReset(true);
    }
    if (users)
      setManagersOptions(
        users.map((user) => ({ value: user.id, label: `${user.first_name} ${user.last_name}` }))
      );
  }, [form, isProjectDetailsAvailable, projectDetails, users]);

  const handleReset = () => {
    if (isProjectDetailsAvailable) {
      form.setFieldsValue(projectDetails);
    } else {
      form.resetFields();
      setDisabledSave(true);
    }
    setDisabledReset(true);
  };

  const onFieldValueChanged = (_, allFields) => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    const isFilled = allValuesFilled();
    setDisabledSave(hasErrors || !isFilled);
    setDisabledReset(!isFilled && isProjectDetailsAvailable);
  };

  const allValuesFilled = () => {
    const formValues = form.getFieldsValue();
    const { project_name, code, description, startDate, manager_id } = formValues;
    return (
      project_name !== undefined &&
      code !== undefined &&
      description !== undefined &&
      startDate !== undefined &&
      manager_id !== undefined
    );
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        onConfirm({
          id: projectDetails?.id,
          project_name: values.project_name,
          active: values.active,
          code: values.code,
          description: values.description,
          start_date: values.startDate.format('DD/MM/YYYY'),
          manager_id: values.manager_id,
        }).then((res) => {
          //TODO:if confirmation not succeeded than do not reset the fields
          if (res) {
            form.resetFields();
            setDisabledSave(true);
            setDisabledReset(true);
          }
        });
      })
      .catch((info) => {
        logger.info('Validate Failed:', info);
      });
  };

  return (
    <Form
      form={form}
      layout='vertical'
      name='projectForm'
      onFinish={handleOk}
      initialValues={initProjectState}
      onFieldsChange={onFieldValueChanged}
    >
      <Row gutter={12}>
        <Col span={10}>
          <Form.Item
            name='project_name'
            label='שם פרויקט'
            rules={[
              {
                min: 3,
                message: 'מינימום 3 תווים',
              },
              {
                required: true,
                message: 'נא להזין שם פרויקט!',
              },
            ]}
          >
            <Input autoComplete='off' />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item
            name='manager_id'
            label='מנהל'
            rules={[
              {
                required: true,
                message: 'נא לבחור מנהל!',
              },
            ]}
          >
            {/* TBD: add Autocomplete with departments sorting */}
            <Select
              showSearch
              placeholder='בחר מנהל'
              optionFilterProp='children'
              filterOption={(input, option) => (option?.label ?? '').includes(input)}
              filterSort={(optionA, optionB) => (optionA?.label ?? '').localeCompare(optionB?.label ?? '')}
              virtual={false}
              options={managersOptions}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          <Form.Item
            name='code'
            label='קוד'
            rules={[
              {
                required: true,
                message: 'נא להזין קוד!',
              },
            ]}
          >
            <InputNumber min={1} />
          </Form.Item>
        </Col>
        <Col span={9}>
          <Form.Item
            label='תאריך התחלה'
            name='startDate'
            rules={[
              {
                required: true,
                message: 'נא לבחור תאריך התחלה',
              },
            ]}
          >
            <DatePicker />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10}>
        <Col span={24}>
          <Form.Item
            name='description'
            label='תיאור'
            rules={[]}
          >
            <Input.TextArea
              showCount
              maxLength={80}
              autoSize={{
                minRows: 2,
                maxRows: 3,
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10}>
        <Col>
          <Form.Item
            name='active'
            valuePropName='checked'
          >
            <Checkbox>האם הפרויקט פעיל?</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row
        gutter={10}
        justify={'end'}
      >
        <Col>
          <Button
            type='default'
            disabled={disabledReset}
            onClick={handleReset}
          >
            {isProjectDetailsAvailable ? 'איפוס' : 'ניקוי'}
          </Button>
        </Col>
        <Col>
          <Button
            type='primary'
            htmlType='submit'
            disabled={disabledSave}
            loading={isLoading}
          >
            אישור
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ProjectForm;
