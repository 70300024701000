export const SET_USER = 'SET_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const AUTH_LOADING = 'AUTH_LOADING';

export const setUser = (user) => ({
  type: SET_USER,
  payload: user,
});

export const logoutUser = () => ({
  type: LOGOUT_USER,
});

export const setAuthLoading = (loading) => ({
  type: AUTH_LOADING,
  payload: loading,
});
