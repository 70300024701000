import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Content } from 'antd/es/layout/layout';
import { getAuthState } from '../../utils';
import UserDetailsForm from '../../components/forms/UserDetailsForm';
import Title from 'antd/es/typography/Title';
import { styled } from 'styled-components';
import { Card, Tabs } from 'antd';
import Meta from '../../components/Meta';
import useUserPersonalDetails from '../../hooks/useUserPersonalDetails';
import useProjectManagement from '../../hooks/useProjectManagement';
import useActivityManagement from '../../hooks/useActivityManagement';
import UserPreferencesForm from '../../components/forms/UserPreferencesForm';

const ResponsiveCard = styled(Card)`
  overflow: auto;
  max-height: 590px; /* Adjust this value as needed */
  min-height: 430px;
  min-width: 390px;
`;

const UserDetails = () => {
  const { token, user } = useSelector((state) => getAuthState(state));
  const {
    userDetails,
    userPreferences,
    getUser: getUserDetails,
    updateUser,
    getPreferences,
    updatePreferences,
    loading,
  } = useUserPersonalDetails(token);

  const { getUserProjects } = useProjectManagement(token);
  const [projects, setProjects] = useState([]);
  const { getActivitiesByProject } = useActivityManagement(token);

  useEffect(() => {
    getUserProjects(user.id).then((res) => {
      if (res) setProjects(res.data);
    });
  }, [getUserProjects, user?.id]);

  useEffect(() => {
    getUserDetails();
    getPreferences();
  }, [getUserDetails, getPreferences]);

  const tabItems = [
    {
      label: `פרטים אישיים`,
      key: '1',
      children: (
        <>
          <Title
            level={3}
            className='text-center'
          >
            פרטים אישיים
          </Title>
          <UserDetailsForm
            userDetails={userDetails}
            isLoading={loading}
            onConfirm={updateUser}
          />
        </>
      ),
    },
    {
      label: `העדפות`,
      key: '2',
      children: (
        <>
          <Title
            level={3}
            className='text-center'
          >
            העדפות
          </Title>
          <UserPreferencesForm
            userDetails={userDetails}
            isLoading={loading}
            userPreferences={userPreferences}
            onConfirm={updatePreferences}
            projects={projects}
            getUserProjects={getUserProjects}
            getActivitiesByProject={getActivitiesByProject}
          />
        </>
      ),
    },
  ];

  return (
    <Content className='d-flex justify-content-center align-items-center'>
      <Meta
        title={'הפרטים שלי'}
        description={'מסך עריכת פרטי משתמש'}
      />
      <ResponsiveCard styles={{ body: { padding: 10 } }}>
        <Tabs
          defaultActiveKey='1'
          type='card'
          items={tabItems}
        />
      </ResponsiveCard>
    </Content>
  );
};

export default UserDetails;
