import { LAYOUT_DESKTOP, LAYOUT_MOBILE } from '../actions/types';

const initState = { isMobile: false };

export const pageLayoutReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case LAYOUT_MOBILE:
      return { ...state, isMobile: true };
    case LAYOUT_DESKTOP:
      return { ...state, isMobile: false };
    default:
      return state;
  }
};
