import { Button, Checkbox, Col, Form, Input, InputNumber, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import logger from '../../logger';
import { DocumentModeDictionary } from '../../utils/handleDocumentMode';

const initActivityState = {
  activity_name: '',
  project_id: undefined,
  active: true,
  document_mode: DocumentModeDictionary.NO_NEED,
};

const documentModeOptions = [
  { value: DocumentModeDictionary.NO_NEED, label: 'אין צורך' },
  { value: DocumentModeDictionary.OPTIONAL, label: 'אופציונאלי' },
  { value: DocumentModeDictionary.MANDATORY, label: 'חובה' },
];

const ActivityForm = ({ activityDetails, isLoading, onConfirm, projects }) => {
  const isActivityAvailable = activityDetails !== null;
  const [form] = Form.useForm();
  const [disabledSave, setDisabledSave] = useState(true);
  const [disabledReset, setDisabledReset] = useState(true);
  const [projectOptions, setProjectOptions] = useState([]);

  useEffect(() => {
    if (isActivityAvailable) {
      form.setFieldsValue({
        activity_name: activityDetails.activity_name,
        project_id: activityDetails.project.id,
        code: activityDetails.code,
        active: activityDetails.active,
        document_mode: DocumentModeDictionary[activityDetails.document_mode],
      });
      setDisabledSave(false);
      setDisabledReset(true);
    } else {
      form.resetFields();
      setDisabledSave(true);
      setDisabledReset(true);
    }
    if (projects) {
      setProjectOptions(
        projects
          .filter((project) => project.active)
          .map((project) => ({ value: project.id, label: `${project.code}. ${project.project_name}` }))
      );
    }
  }, [form, isActivityAvailable, activityDetails, projects]);

  const handleReset = () => {
    if (isActivityAvailable) {
      form.setFieldsValue(activityDetails);
    } else {
      form.resetFields();
      setDisabledSave(true);
    }
    setDisabledReset(true);
  };

  const onFieldValueChanged = (_, allFields) => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    const isFilled = allValuesFilled();
    setDisabledSave(hasErrors || !isFilled);
    setDisabledReset(!isFilled && isActivityAvailable);
  };

  const allValuesFilled = () => {
    const formValues = form.getFieldsValue();
    const { activity_name, project_id, code } = formValues;
    return activity_name !== undefined && project_id !== undefined && code !== undefined;
  };

  const handleOk = () => {
    form.validateFields().then((values) => {
      onConfirm({
        id: activityDetails?.id,
        activity_name: values.activity_name,
        code: values.code,
        active: values.active,
        project_id: values.project_id,
        document_mode: values.document_mode,
      })
        .then((res) => {
          //TODO:if confirmation not succeeded than do not reset the fields
          if (res) {
            form.resetFields();
            setDisabledSave(true);
            setDisabledReset(true);
          }
        })
        .catch((info) => {
          logger.info('Validate Failed:', info);
        });
    });
  };

  return (
    <Form
      form={form}
      layout='vertical'
      name='activityForm'
      onFinish={handleOk}
      initialValues={initActivityState}
      onFieldsChange={onFieldValueChanged}
    >
      <Row gutter={10}>
        <Col span={12}>
          <Form.Item
            name='activity_name'
            label='שם הפעילות'
            rules={[
              {
                min: 2,
                message: 'מינמום 2 תווים',
              },
              {
                required: true,
                message: 'נא להזין שם פעילות!',
              },
            ]}
          >
            <Input autoComplete='off' />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name='code'
            label='קוד'
            rules={[
              {
                required: true,
                message: 'נא להזין קוד!',
              },
            ]}
          >
            <InputNumber
              style={{ width: '100%' }}
              min={1}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10}>
        <Col span={12}>
          <Form.Item
            name='project_id'
            label='בחר פרויקט'
            rules={[
              {
                required: true,
                message: 'שייך פרויקט',
              },
            ]}
          >
            <Select
              allowClear
              showSearch
              options={projectOptions}
              optionFilterProp='children'
              filterOption={(input, option) => (option?.label ?? '').includes(input)}
              filterSort={(optionA, optionB) => (optionA?.label ?? '').localeCompare(optionB?.label ?? '')}
              placeholder='בחר פרויקט'
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name='document_mode'
            label='האם דרוש קובץ?'
          >
            <Select options={documentModeOptions}></Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={10}>
        <Col>
          <Form.Item
            name='active'
            valuePropName='checked'
          >
            <Checkbox>האם הפעילות פעילה?</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row
        gutter={10}
        justify={'end'}
      >
        <Col>
          <Button
            type='default'
            disabled={disabledReset}
            onClick={handleReset}
          >
            {isActivityAvailable ? 'איפוס' : 'ניקוי'}
          </Button>
        </Col>
        <Col>
          <Button
            type='primary'
            htmlType='submit'
            disabled={disabledSave}
            loading={isLoading}
          >
            אישור
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ActivityForm;
