import { useCallback, useState, useEffect } from 'react';
import { createNewTemplate, deleteTemplate, getAllTemplates } from '../services/templateFilters';
import logger from '../logger';
import { handleError } from '../utils/handleErrors';

const useTemplateFilter = (initialToken) => {
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [token, setToken] = useState(initialToken);

  //load all templates
  const loadTemplates = useCallback(() => {
    setLoading(true);
    getAllTemplates(token)
      .then((res) => {
        const data = res.data;
        const receivedTemplates = data.map((template) => ({
          id: template.id,
          template_name: template.template_name,
          projects: template.selected_project_ids,
          users: template.selected_user_ids,
          sortingOption: template.sorting_option,
          label: template.template_name,
        }));
        setTemplates(receivedTemplates);
        return true;
      })
      .catch((error) => {
        const parsedError = handleError(error);
        logger.error(error);
        setError(parsedError);
        return false;
      })
      .finally(() => setLoading(false));
  }, [token]);

  useEffect(() => {
    loadTemplates();
  }, [loadTemplates]);

  const saveTemplate = (data) => {
    setLoading(true);
    const templateName = data.template_name;
    return createNewTemplate(data, token)
      .then(() => {
        loadTemplates();
        return true;
      })
      .catch((error) => {
        const parsedError = handleError(error, templateName);
        logger.error(error, true);
        // toast.error(parsedError.toastMessage);
        setError(parsedError);
        return false;
      })
      .finally(() => setLoading(false));
  };

  const removeTemplate = (id) => {
    setLoading(true);
    return deleteTemplate(id, token)
      .then(() => {
        loadTemplates();
        return true;
      })
      .catch((error) => {
        const parsedError = handleError(error);
        logger.error(error, true);
        // toast.error(parsedError.toastMessage);
        setError(parsedError);
        return false;
      })
      .finally(() => setLoading(false));
  };

  const setAuthToken = (token) => {
    setToken(token);
  };

  return {
    templates,
    loading,
    error,
    loadTemplates,
    saveTemplate,
    removeTemplate,
    setAuthToken,
  };
};

export default useTemplateFilter;
