import { Button, Tooltip } from 'antd';

const TooltipButton = ({ placement, title, type, icon, onClick, isDanger, className, disabled = false }) => {
  return (
    <Tooltip
      placement={placement}
      disabled={disabled}
      title={!disabled && title}
    >
      <Button
        type={type}
        icon={icon}
        onClick={onClick}
        danger={isDanger}
        className={className}
        disabled={disabled}
      />
    </Tooltip>
  );
};

export default TooltipButton;
