// USER
export const CREATE_USER = 'CREATE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_PROJECTS = 'UPDATE_USER_PROJECTS';
export const UPDATE_USER_DETAILS = 'UPDATE_USER_DETAILS';

//TIME REPORT
export const CREATE_REPORT = 'CREATE_REPORT';
export const RESET_REPORT = 'RESET_REPORT';
export const EDIT_REPORT = 'EDIT_REPORT';
export const DUPLICATE_REPORT = 'DUPLICATE_REPORT';
export const DELETE_REPORT = 'DELETE_REPORT';
export const SELECT_DATE = 'SELECT_DATE';
export const LOAD_REPORTS = 'LOAD_REPORTS';
export const INIT_SELECTED_DATE = 'INIT_SELECTED_DATE';
export const INIT_TIME_REPORTS = 'INIT_TIME_REPORTS';

// ADMINISTRATION
export const LOAD_DATE_EVENTS = 'LOAD_DATE_EVENTS';
export const INIT_DATE_EVENTS = 'INIT_DATE_EVENTS';

// LAYOUT
export const LAYOUT_DESKTOP = 'LAYOUT_DESKTOP';
export const LAYOUT_MOBILE = 'LAYOUT_MOBILE';
