import React, { useState } from 'react';
import { Card, Descriptions, Divider, FloatButton, List, Tag, Typography } from 'antd';
import ReportsItem from '../../pages/reports/ReportsItem';

import dayjs from 'dayjs';
import { convertTimeToDecimal } from '../../utils';

const TypesOfDate = {
  0: 'יום עבודה רגיל',
  1: 'עבודה חלקית',
  2: 'משרד סגור – ע"ח קורן טק',
};

const ReportsList = ({ reports, loading, paginationSettings, dateEvents }) => {
  const [showDecimal, setShowDecimal] = useState(true);

  const parseTotalTime = (totalTime, showDecimal = true) => {
    if (showDecimal) return convertTimeToDecimal(totalTime);
    return totalTime;
  };

  const toggleShowDecimal = () => {
    setShowDecimal((state) => !state);
  };

  const renderDateEventsDescription = () => {
    const mappedEvents = dateEvents
      .filter((de) => de.event_type > 0)
      .map((de) => ({
        key: de.id,
        label: dayjs(de.start_date).format('DD/MM/YYYY'),
        children: (
          <span>
            {de.event_name}{' '}
            <Divider
              type='vertical'
              className='m-1'
            />
            <Tag
              color={de.event_type === 1 ? 'warning' : 'error'}
              key={de.event_type}
            >
              {TypesOfDate[de.event_type]}
            </Tag>
          </span>
        ),
      }));
    if (mappedEvents.length)
      return (
        <>
          <Descriptions
            className='p-1'
            title='אירועים ומועדים'
            size='small'
            items={mappedEvents}
            column={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 3, xxl: 3 }}
          />
          <Divider
            orientation='left'
            variant='dashed'
          >
            דיווחים
          </Divider>
        </>
      );
  };

  return (
    <>
      <FloatButton.Group
        style={{
          zIndex: 1001,
        }}
      >
        <FloatButton.BackTop
          type='primary'
          target={() => document.getElementById('reports-layout')}
        />
      </FloatButton.Group>
      {renderDateEventsDescription()}

      <List
        dataSource={reports}
        itemLayout='vertical'
        style={{
          // maxHeight: '800px',
          overflow: 'auto',
        }}
        loading={loading.read}
        renderItem={({ key, reports, summary, totalDuration }, i) => {
          return (
            <List.Item
              key={key}
              className={i === 0 && 'pt-0'}
            >
              <Card
                style={{ head: { padding: 10 }, body: { padding: 0, paddingTop: 1 } }}
                title={
                  key !== 'unfiltered'
                    ? dayjs(key).isValid()
                      ? dayjs(key).format('DD/MM/YYYY')
                      : key
                    : 'לא מסונן'
                }
                extra={
                  <Typography.Text onClick={toggleShowDecimal}>
                    סה"כ זמן מדווח {parseTotalTime(totalDuration, showDecimal)}
                  </Typography.Text>
                }
              >
                <ReportsItem
                  loading={loading}
                  reports={reports}
                  summary={summary}
                  paginationSettings={paginationSettings}
                />
              </Card>
            </List.Item>
          );
        }}
      />
    </>
  );
};

export default ReportsList;
