import axios from 'axios';
import config from '../config';

export const uploadFile = async (fileDetails, token) => {
  return await axios.post(`${config.API_URL}/upload`, fileDetails, {
    headers: {
      Authorization: `Bearer ${token}`,
      ContentType: 'multipart/form-data',
    },
  });
};

export const deleteFile = async (file_id, token) => {
  // return await axios.delete(`${config.API_URL}/delete/${file_id}`, {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // });
};

export const downloadFile = async (file_id, token) => {
  return await axios.get(`${config.API_URL}/download/${file_id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
