import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ShowLoading from '../components/ShowLoading';
import { getAuthState } from '../utils';

const UserRoute = ({ Component }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, token, isAuthenticated, loading } = useSelector((state) => getAuthState(state));

  useEffect(() => {
    if (!user || !token) {
      navigate('/login', { state: { redirectTo: location.pathname } });
    }
  }, [user, token, navigate, location.pathname]);

  if (loading) {
    return <ShowLoading />;
  }

  return isAuthenticated ? <Component /> : null;
};

export default UserRoute;
