import './index.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './reducers';
import App from './App';
import { ConfigProvider } from 'antd';
// import heIL from 'antd/locale/he_IL';
import heIL from 'antd/es/locale/he_IL';
import he from 'antd/es/date-picker/locale/he_IL';
import dayjs from 'dayjs';
import heLocale from 'dayjs/locale/he';
dayjs.locale(heLocale);

const hebrewLocale = {
  ...he,
  lang: {
    ...he.lang,
    fieldDateFormat: 'DD/MM/YYYY',
    fieldDateTimeFormat: 'DD/MM/YYYY HH:mm:ss',
    yearFormat: 'YYYY',
    cellYearFormat: 'YYYY',
  },
};

const globalHebrewLocale = {
  ...heIL,
  DatePicker: {
    ...heIL.DatePicker,
    lang: hebrewLocale.lang,
  },
};

const store = createStore(rootReducer, composeWithDevTools());

// import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <Router>
      <ConfigProvider
        direction='rtl'
        locale={globalHebrewLocale}
      >
        <App />
      </ConfigProvider>
    </Router>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
