import Title from 'antd/es/typography/Title';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getAuthState } from '../../utils';
import useLocationManagement from '../../hooks/useLocationManagement';
import { toast } from 'react-toastify';
import CustomModal from '../modals/CustomModal';
import LocationForm from '../forms/LocationForm';
import { Button, Card, Col, List, Row, Tooltip, Typography } from 'antd';
import LocalSearch from '../forms/LocalSearch';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import TooltipButton from '../buttons/TooltipButton';
import authWrapper from '../../utils/authWrapper';
import Meta from '../Meta';

const { Text } = Typography;

const ManageLocations = () => {
  const { token } = useSelector((state) => getAuthState(state));
  const [editedLocation, setEditedLocation] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { locations, loading, createLocation, updateLocation } = useLocationManagement(token);
  const [keyword, setKeyword] = useState('');

  const handleEditLocation = (location) => {
    setEditedLocation(location);
    handleToggleModal();
  };

  const handleCreate = (data) => {
    return createLocation(data).then((success) => {
      if (success) {
        toast.success('המיקום התווסף בהצלחה');
        setEditedLocation(null);
        return true;
      } else {
        return false;
      }
    });
  };

  const handleUpdate = (data) => {
    return updateLocation(data).then((success) => {
      if (success) {
        toast.success(` עדכון המיקום בוצע בהצלחה`);
        setEditedLocation(null);
        handleToggleModal();
        return true;
      } else {
        return false;
      }
    });
  };

  const handleToggleModal = () => {
    setIsModalOpen((state) => !state);
  };

  const handleCancelModal = () => {
    setEditedLocation(null);
    handleToggleModal?.();
  };

  const searchFilter = (keyword) => (location) => location.location_name.includes(keyword);

  return (
    <div className='container w-75 m-2'>
      <Meta
        title={'ניהול מיקומים'}
        description={'מסך ניהול מיקומים'}
      />
      <Title
        level={1}
        className='text-center'
      >
        ניהול מיקומים
      </Title>
      <Card className='p-1'>
        <Row
          justify={'center'}
          gutter={12}
        >
          <Col span={9}>
            <LocalSearch
              keyword={keyword}
              setKeyword={setKeyword}
            />
          </Col>
          <Col span={1}>
            <Tooltip title='הוספת מחלקה חדשה'>
              <Button
                icon={<PlusOutlined />}
                type='text'
                onClick={() => handleToggleModal()}
              />
            </Tooltip>
          </Col>
        </Row>
        <Row justify={'center'}>
          <Col span={10}>
            <List
              pagination={{
                position: 'bottom',
                pageSize: 8,
                defaultCurrent: 1,
                className: 'text-center',
                hideOnSinglePage: true,
              }}
              dataSource={locations.filter(searchFilter(keyword))}
              renderItem={(location) => (
                <List.Item
                  key={location._id}
                  actions={[
                    <TooltipButton
                      placement='top'
                      type='text'
                      title='ערוך מיקום'
                      icon={<EditOutlined />}
                      onClick={() => handleEditLocation(location)}
                    ></TooltipButton>,
                  ]}
                >
                  <List.Item.Meta title={<Text>{location.location_name}</Text>} />
                </List.Item>
              )}
            />
          </Col>
        </Row>
      </Card>
      <CustomModal
        modalTitle={editedLocation !== null ? 'עדכן מיקום' : 'צור מיקום'}
        isModalOpen={isModalOpen}
        handleCancel={handleCancelModal}
        width={'fit-content'}
      >
        <LocationForm
          name={'updateLocation'}
          locationDetails={editedLocation}
          isLoading={loading}
          onConfirm={editedLocation ? handleUpdate : handleCreate}
        />
      </CustomModal>
    </div>
  );
};

export default authWrapper('locations')(ManageLocations);
