import Title from 'antd/es/typography/Title';
import React, { useEffect, useState } from 'react';
import ActivityTable from '../tables/ActivityTable';
import { useSelector } from 'react-redux';
import { getAuthState } from '../../utils';
import useActivityManagement from '../../hooks/useActivityManagement';
import { toast } from 'react-toastify';
import CustomModal from '../modals/CustomModal';
import ActivityForm from '../forms/ActivityForm';
import userProjectManagement from '../../hooks/useProjectManagement';
import authWrapper from '../../utils/authWrapper';
import Meta from '../Meta';

const ManageActivities = () => {
  const { token } = useSelector((state) => getAuthState(state));
  const [editedActivity, setEditedActivity] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { activities, loading, loadActivities, createActivity, updateActivity } =
    useActivityManagement(token);
  const { projects, loadProjects } = userProjectManagement(token);

  const handleEditActivity = (activity) => {
    setEditedActivity(activity);
    handleToggleModal();
  };

  const handleCreateActivity = (data) => {
    return createActivity(data).then((success) => {
      if (success) {
        toast.success('הפעילות התווספה בהצלחה');
        setEditedActivity(null);
        handleToggleModal();
        return true;
      } else {
        return false;
      }
    });
  };

  const handleUpdate = (data) => {
    return updateActivity(data).then((success) => {
      if (success) {
        toast.success('עדכון הפעילות התבצע בהצלחה!');
        setEditedActivity(null);
        handleToggleModal();
        return true;
      } else {
        return false;
      }
    });
  };

  const handleToggleModal = () => {
    setIsModalOpen((state) => !state);
  };

  const handleCancelModal = () => {
    setEditedActivity(null);
    handleToggleModal?.();
  };

  useEffect(() => {
    loadProjects();
    loadActivities();
  }, [loadActivities, loadProjects]);

  return (
    <div className='container m-2'>
      <Meta
        title={'ניהול פעילויות'}
        description={'מסך ניהול פעילויות'}
      />
      <Title
        level={1}
        className='text-center'
      >
        ניהול פעילויות
      </Title>
      <CustomModal
        modalTitle={editedActivity ? 'עדכון הפעילות' : 'יצירת פעילות חדשה'}
        isModalOpen={isModalOpen}
        handleCancel={handleCancelModal}
        width={'500px'}
      >
        <ActivityForm
          activityDetails={editedActivity}
          isLoading={loading}
          onConfirm={editedActivity ? handleUpdate : handleCreateActivity}
          projects={projects}
        />
      </CustomModal>
      <ActivityTable
        activities={activities.map((activity) => ({ ...activity, key: activity.id }))}
        loading={loading}
        handleEditActivity={handleEditActivity}
        handleToggleModal={handleToggleModal}
        projects={projects}
      />
    </div>
  );
};

export default authWrapper('activities')(ManageActivities);
