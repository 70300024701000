import { Button, Col, Row, Transfer } from 'antd';
import Title from 'antd/es/typography/Title';
import React, { useEffect, useState } from 'react';
import { areArraysEqual } from '../../utils';

const ManageUserProjects = ({ userDetails, isLoading, projects, onConfirm }) => {
  const isUserDetailsAvailable = userDetails !== null;
  const [disabledSave, setDisabledSave] = useState(true);
  const [disabledReset, setDisabledReset] = useState(true);
  const [targetKeys, setTargetKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);

  const mappedProjects = projects?.map((project) => ({
    key: project.id,
    title: `${project.code}.${project.project_name}`,
  }));

  useEffect(() => {
    if (isUserDetailsAvailable) {
      setTargetKeys(userDetails.projects.map((project) => project.id));
    }
    setDisabledSave(true);
    setDisabledReset(true);
  }, [isUserDetailsAvailable, userDetails]);

  const onChange = (nextTargetKeys, direction, moveKeys) => {
    setDisabledReset(false);
    setDisabledSave(false);
    setTargetKeys(nextTargetKeys);
  };

  const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  useEffect(() => {
    if (isUserDetailsAvailable) {
      const userProjects = userDetails.projects.map((p) => p.id);
      const disable = areArraysEqual(userProjects, targetKeys);
      setDisabledReset(disable);
      setDisabledSave(disable);
    }
  }, [isUserDetailsAvailable, targetKeys, userDetails.projects]);

  const onSave = () => {
    //save projects assignment
    var projects = targetKeys;
    onConfirm({ projects, id: userDetails?.id }).then((res) => {
      if (res) {
        setDisabledSave(true);
        setDisabledReset(true);
      }
    });
  };

  const onReset = () => {
    //redo assigned projects
    if (isUserDetailsAvailable) {
      setTargetKeys(userDetails.projects.map((project) => project.id));
      setDisabledSave(true);
      setDisabledReset(true);
    }
  };

  return (
    <>
      <Row>
        <Col>
          <Transfer
            dataSource={mappedProjects}
            selectAllLabels={[
              <Title
                className='text-center'
                level={4}
              >
                פרויקטים פעילים
              </Title>,
              <Title
                className='text-center'
                level={4}
              >
                נבחרו
              </Title>,
            ]}
            targetKeys={targetKeys}
            selectedKeys={selectedKeys}
            showSelectAll={false}
            showSearch={true}
            oneWay={true}
            onChange={onChange}
            onSelectChange={onSelectChange}
            listStyle={{
              width: 500,
              height: 400,
            }}
            render={(item) => item.title}
          />
        </Col>
      </Row>
      <Row
        className='mt-2'
        gutter={10}
        justify={'end'}
      >
        <Col>
          <Button
            type='default'
            disabled={disabledReset}
            onClick={onReset}
          >
            {isUserDetailsAvailable ? 'איפוס' : 'ניקוי'}
          </Button>
        </Col>
        <Col>
          <Button
            type='primary'
            htmlType='submit'
            disabled={disabledSave}
            onClick={onSave}
            loading={isLoading}
          >
            אישור
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default ManageUserProjects;
