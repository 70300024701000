import axios from 'axios';
import config from '../config';

export const login = async (username, password) => {
  return await axios.post(`${config.API_URL}/login`, { username, password });
};

// auth check for admin
export const logout = async (token) => {
  return await axios.post(
    `${config.API_URL}/logout`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const currentUser = async (token) => {
  return await axios.get(`${config.API_URL}/current-user`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// auth check for admin
export const checkUserPermissions = async (token, resource) => {
  return await axios.post(
    `${config.API_URL}/check-user-permissions`,
    { resource },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
