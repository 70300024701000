import React, { useEffect, useState } from 'react';
import { TimePicker as AntdTimePicker } from 'antd';
import { isMobile } from 'react-device-detect';
import dayjs from 'dayjs';

const TimePickerComponent = ({ value, onChange, placeholder }) => {
  const [time, setTime] = useState(null);

  useEffect(() => {
    if (value) {
      setTime(dayjs(value));
    }
  }, [value]);

  const handleChange = (newTime) => {
    setTime(newTime);
    if (onChange) {
      onChange(newTime);
    }
  };

  const handleNativeChange = (event) => {
    const newTime = dayjs(event.target.value, 'HH:mm');
    handleChange(newTime.isValid() ? newTime : null);
  };

  const handleDesktopInputChange = (event) => {
    const eventValue = event.target.value;
    let inputValue = eventValue ? eventValue.replace(/\D/g, '') : ''; // Remove non-numeric characters
    if (!eventValue) return;

    if (inputValue.length > 4) {
      inputValue = inputValue.slice(0, 4);
    }

    let formattedTime;
    if (inputValue.length <= 2) {
      formattedTime = inputValue.padStart(2, '0') + ':00';
    } else {
      formattedTime = inputValue.slice(0, -2).padStart(2, '0') + ':' + inputValue.slice(-2);
    }

    const newTime = dayjs(formattedTime, 'HH:mm');

    // Validate if the formatted time is valid
    if (newTime.isValid() && formattedTime.length === 5) {
      handleChange(newTime);
    } else {
      // Invalid time, do not update the state
      event.target.value = time ? time.format('HH:mm') : '';
    }
  };

  return isMobile ? (
    <div
      className='w-100'
      style={{ position: 'relative' }}
    >
      <input
        type='time'
        value={time ? time.format('HH:mm') : ''}
        onChange={handleNativeChange}
        style={{
          width: '100%',
          position: 'absolute',
          opacity: 0,
          zIndex: 2,
        }}
        step='60' // Ensure only hours and minutes are shown
      />
      <AntdTimePicker
        className='w-100'
        value={time}
        format={'HH:mm'}
        open={false}
        inputReadOnly
      />
    </div>
  ) : (
    <AntdTimePicker
      className='w-100'
      value={time}
      needConfirm={false}
      placeholder={placeholder}
      format={'HH:mm'}
      onChange={handleChange}
      minuteStep={1}
      inputReadOnly={false}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          handleDesktopInputChange(e);
        }
      }}
      onBlur={handleDesktopInputChange}
    />
  );
};

export default TimePickerComponent;
