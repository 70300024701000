import { Select, Form, Button, Row, Col, Flex, Checkbox } from 'antd';
import React, { useEffect, useState } from 'react';
import { getAuthState } from '../../utils';
import { useSelector } from 'react-redux';
import useDepartmentManagement from '../../hooks/useDepartmentManagement';

import RTLRangePicker from './RTLRangePicker';

const initDepartmentReportsState = {
  users: [],
  rangeDates: [],
  isByGroup: false,
  sortingOption: 'unfiltered',
};

const sortingOptions = [
  { value: 'unfiltered', label: 'ללא חלוקה' },
  { value: 'byDate', label: 'חלוקה לפי תאריך' },
  { value: 'byActivity', label: 'חלוקה לפי פעילות' },
  { value: 'byUser', label: 'חלוקה לפי משתמש' },
];

const DepartmentReportsForm = ({ getDepartmentReports, loading }) => {
  const { user, token } = useSelector((state) => getAuthState(state));
  const [form] = Form.useForm();
  const [disabledReset, setDisabledReset] = useState(true);
  const [users, setUsers] = useState([]);
  const [disableUserSelection, setDisableUserSelection] = useState(true);
  const {
    departments,
    loading: departmentsLoading,
    getUsersBySelectedDepartments,
  } = useDepartmentManagement(token);

  const clearFieldError = (field) => {
    form.setFields([
      {
        name: field,
        errors: [],
      },
    ]);
  };

  useEffect(() => {}, [user, departments]);

  const handleFinish = () => {
    form.validateFields().then((values) => {
      getDepartmentReports(values);
    });
  };

  const handleReset = () => {
    form.resetFields();
    setDisabledReset(true);
  };

  const onFieldValueChanged = (field) => {
    setDisabledReset(false);
  };

  const onSelectDepartmentsChange = () => {
    const departments = form.getFieldValue('departments');
    if (departments && departments.length) {
      form.setFieldValue('users', []);
      getUsersBySelectedDepartments(departments).then((users) => {
        if (users) setUsers(users);
      });
    } else {
      setUsers([]);
      setDisableUserSelection(true);
    }
  };

  return (
    <>
      <Flex
        vertical
        gap='middle'
      >
        <Form
          form={form}
          name='requestMissingReportsForm'
          onFinish={handleFinish}
          validateTrigger='onSubmit'
          initialValues={initDepartmentReportsState}
          onFieldsChange={onFieldValueChanged}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                className='mb-2'
                name='departments'
                rules={[
                  {
                    required: true,
                    message: 'נא לבחור לפחות מחלקה אחת',
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  filterOption={(input, option) => (option?.label ?? '').includes(input)}
                  mode='multiple'
                  placeholder='בחר מחלקות'
                  loading={departmentsLoading}
                  options={departments.map((department) => ({
                    value: department.id,
                    label: department.department_name,
                  }))}
                  onChange={() => {
                    clearFieldError('departments');
                    onSelectDepartmentsChange();
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Form.Item
              name='isFilterByUsers'
              valuePropName='checked'
              className='mb-1'
            >
              <Checkbox
                disabled={!form.getFieldValue('departments')?.length > 0}
                onChange={({ target }) => {
                  const { checked } = target;
                  form.setFieldsValue({ isFilterByUsers: checked });
                  setDisableUserSelection(!checked);
                  onFieldValueChanged('isFilterByUsers');
                  clearFieldError('isFilterByUsers');
                }}
              >
                סינון לפי משתמש
              </Checkbox>
            </Form.Item>

            <Col span={24}>
              <Form.Item name='users'>
                <Select
                  allowClear
                  showSearch
                  disabled={disableUserSelection}
                  filterOption={(input, option) => (option?.label ?? '').includes(input)}
                  mode='multiple'
                  placeholder='בחר משתמשים'
                  loading={departmentsLoading}
                  options={users.map((user) => ({
                    value: user.id,
                    label: `${user.first_name} ${user.last_name}`,
                  }))}
                  onChange={() => clearFieldError('users')}
                />
              </Form.Item>{' '}
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item name='sortingOption'>
                <Select
                  placeholder='בחר חלוקה'
                  options={sortingOptions}
                  onChange={() => clearFieldError('sortingOption')}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row
            gutter={12}
            justify={'space-between'}
          >
            <Col span={24}>
              <Form.Item
                name='rangeDates'
                rules={[
                  {
                    required: true,
                    message: 'נא לבחור טווח תאריכים',
                  },
                ]}
              >
                <RTLRangePicker
                  showPresets
                  className='w-100'
                  onChange={() => clearFieldError('rangeDates')}
                  placeholder={['תאריך התחלה', 'תאריך סיום']}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col>
              <Button
                type='default'
                disabled={disabledReset}
                onClick={handleReset}
              >
                ניקוי
              </Button>
            </Col>
            <Col>
              <Button
                type='primary'
                htmlType='submit'
                loading={loading.read}
              >
                הבא דיווחים
              </Button>
            </Col>
          </Row>
        </Form>
      </Flex>
    </>
  );
};

export default DepartmentReportsForm;
