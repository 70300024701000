import { Button, Col, Form, Input, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import logger from '../../logger';

const initDepartmentState = {
  department_name: '',
  account_id: undefined,
};

const DepartmentForm = ({ departmentDetails, isLoading, onConfirm, accounts }) => {
  const isDepartmentDetailsAvailable = departmentDetails !== null;
  const [form] = Form.useForm();
  const [disabledSave, setDisabledSave] = useState(true);
  const [disabledReset, setDisabledReset] = useState(true);
  const [accountsOptions, setAccountsOptions] = useState([]);

  useEffect(() => {
    if (isDepartmentDetailsAvailable) {
      form.setFieldsValue({
        department_name: departmentDetails.department_name,
        account_id: departmentDetails.account_id,
      });
      setDisabledSave(true);
      setDisabledReset(true);
    } else {
      form.resetFields();
      setDisabledSave(true);
      setDisabledReset(true);
    }
    if (accounts) {
      setAccountsOptions(
        accounts
          .filter((account) => account.active)
          .map((account) => ({ value: account.id, label: account.account_name }))
      );
      if (accounts.length) {
        form.setFieldsValue({ account_id: accounts[0].id }); // default until we will have more accounts
      }
    }
  }, [form, isDepartmentDetailsAvailable, departmentDetails, accounts]);

  const handleReset = () => {
    if (isDepartmentDetailsAvailable) {
      form.setFieldsValue(departmentDetails);
    } else {
      form.resetFields();
    }
    setDisabledSave(true);
    setDisabledReset(true);
  };

  const checkIfSame = () => {
    if (departmentDetails)
      return form.getFieldValue('department_name') === departmentDetails?.department_name;
  };

  const onFieldValueChanged = (_, allFields) => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    const isFilled = allValuesFilled();
    setDisabledSave((hasErrors || !isFilled) && checkIfSame());
    setDisabledReset(!isFilled && isDepartmentDetailsAvailable && checkIfSame());
  };

  const allValuesFilled = () => {
    const formValues = form.getFieldsValue();
    const { department_name, account_id } = formValues;
    return department_name.length > 1 && account_id !== undefined;
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        onConfirm({ ...values, id: departmentDetails?.id }).then((res) => {
          //TODO:if confirmation not succeeded than do not reset the fields
          if (res) {
            form.resetFields();
            setDisabledSave(true);
            setDisabledReset(true);
          }
        });
      })
      .catch((info) => {
        logger.info('Validate Failed:', info);
      });
  };

  return (
    <Form
      form={form}
      layout='vertical'
      name='departmentForm'
      onFinish={handleOk}
      initialValues={initDepartmentState}
      onFieldsChange={onFieldValueChanged}
    >
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item
            name='department_name'
            label='שם מחלקה'
            rules={[
              {
                required: true,
                message: 'נא להזין שם מחלקה!',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name='account_id'
            label='חשבון'
            rules={[
              {
                required: true,
                message: 'נא לבחור חשבון!',
              },
            ]}
          >
            <Select
              options={accountsOptions}
              defaultValue={1}
              disabled
            />
          </Form.Item>
        </Col>
      </Row>
      <Row
        gutter={10}
        justify={'end'}
      >
        <Col>
          <Button
            type='default'
            disabled={disabledReset}
            onClick={handleReset}
          >
            {isDepartmentDetailsAvailable ? 'איפוס' : 'ניקוי'}
          </Button>
        </Col>
        <Col>
          <Button
            type='primary'
            htmlType='submit'
            disabled={disabledSave}
            loading={isLoading}
          >
            אישור
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default DepartmentForm;
