import { SET_USER, LOGOUT_USER, AUTH_LOADING } from '../actions/authActions';

const initialState = {
  user: null,
  isAuthenticated: false,
  loading: true,
};

const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_USER:
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loading: false,
      };
    case LOGOUT_USER:
      return {
        ...state,
        user: null,
        token: null,
        isAuthenticated: false,
        loading: false,
      };
    case AUTH_LOADING:
      return {
        ...state,
        loading: payload,
      };
    default:
      return state;
  }
};

export default authReducer;
