import React from 'react';
import { useSelector } from 'react-redux';
import Highlighter from 'react-highlight-words';
import ReportsTable from '../../components/tables/ReportsTable';
import { PaperClipOutlined, SearchOutlined } from '@ant-design/icons';
import { getAuthState } from '../../utils';
import { useRef, useState } from 'react';
import useUpload from '../../hooks/useUpload';
import { Button, Space, Popover, Typography, Input, Descriptions } from 'antd';
import dayjs from 'dayjs';
import TooltipButton from '../../components/buttons/TooltipButton';
import logger from '../../logger';

const ReportsItem = ({ reports, summary, loading, paginationSettings }) => {
  const { token } = useSelector((state) => getAuthState(state));
  const { downloadFileById } = useUpload(token);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const parseComments = (comments) => {
    // Split the string into an array of rows
    const rows = comments.split('\n');

    // Map over the array to generate JSX elements for each row
    const content = rows.map((row, index) => (
      <p
        key={index}
        className='m-0'
      >
        {row}
      </p>
    ));

    return content;
  };

  const handleDownload = (file_id) => {
    if (file_id === undefined) return null;
    return downloadFileById(file_id).catch((error) => {
      logger.error(error);
    });
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    setSearchText('');
    confirm();
  };

  const getColumnSearchProps = (dataIndex, title = '') => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`חיפוש לפי ${title}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{
              width: 90,
            }}
          >
            חיפוש
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size='small'
            style={{
              width: 90,
            }}
          >
            איפוס
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: 'תאריך',
      dataIndex: 'reportDate',
      key: 'reportDate',
      width: '10%',
      sorter: (a, b) => dayjs(a.reportDate, 'DD/MM/YYYY').unix() - dayjs(b.reportDate, 'DD/MM/YYYY').unix(),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'שם המדווח',
      dataIndex: 'name',
      key: 'name',
      width: '10%',
      ...getColumnSearchProps('name', 'שם המדווח'),
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'פרויקט',
      dataIndex: 'projectName',
      key: 'projectName',
      width: '10%',
      ...getColumnSearchProps('project', 'שם הפרויקט'),
      sorter: (a, b) => a.projectName.localeCompare(b.projectName),
      sortDirections: ['descend', 'ascend'],
      render: (act, _) => {
        return act;
      },
    },
    {
      title: 'פעילות',
      dataIndex: 'activity',
      key: 'activity',
      width: '10%',
      ...getColumnSearchProps('activity', 'שם הפעילות'),
      sorter: (a, b) => a.activity.localeCompare(b.activity),
      sortDirections: ['descend', 'ascend'],
      render: (act, _) => {
        return act;
      },
    },
    {
      title: 'זמן התחלה',
      dataIndex: 'start_time',
      key: 'start_time',
      width: '10%',
      align: 'center',
      sorter: (a, b) => dayjs(a.start_time, 'HH:mm').unix() - dayjs(b.start_time, 'HH:mm').unix(),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'זמן סיום',
      dataIndex: 'end_time',
      key: 'end_time',
      width: '10%',
      align: 'center',
      sorter: (a, b) => dayjs(a.end_time, 'HH:mm').unix() - dayjs(b.end_time, 'HH:mm').unix(),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'סה"כ',
      dataIndex: 'totalDuration',
      key: 'totalDuration',
      width: '10%',
      align: 'center',
      sorter: (a, b) => dayjs(a.totalDuration, 'HH:mm').unix() - dayjs(b.totalDuration, 'HH:mm').unix(),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'תיאור',
      dataIndex: 'description',
      key: 'description',
      width: '17%',
      render: (comments) => {
        return (
          <Popover
            overlayStyle={{
              maxWidth: '15vw',
            }}
            zIndex={40}
            className='pointer'
            content={parseComments(comments)}
            trigger='click'
            placement='rightBottom'
          >
            <Typography.Text style={{ cursor: 'pointer' }}>{comments}</Typography.Text>
          </Popover>
        );
      },
    },
    {
      title: 'קובץ מצורף',
      dataIndex: 'file',
      key: 'file',
      align: 'center',
      width: '10%',
      filterSearch: true,
      filters: [
        {
          text: 'מצורף קובץ',
          value: true,
        },
        {
          text: 'לא מצורף קובץ',
          value: false,
        },
      ],
      onFilter: (value, record) => {
        const isFileExist = record.file !== null;
        return isFileExist === value;
      },
      render: (file) => {
        return (
          file && (
            <TooltipButton
              placement='top'
              type='text'
              title='הצג קובץ מצורף'
              icon={<PaperClipOutlined />}
              onClick={() => handleDownload(file.id)}
            />
          )
        );
      },
    },
  ];

  const renderFooter = () => {
    const items = summary.map((item, index) => {
      const text = item.total_days > 1 ? `${item.total_days} ימים` : `יום ${item.total_days}`;
      return {
        key: index,
        label: item.activity_name,
        children: text,
        className: 'pb-0',
      };
    });
    return (
      <Descriptions
        size='small'
        items={items}
        column={1}
      />
    );
  };

  return (
    <ReportsTable
      sortTitle={'ללא סינון'}
      columns={columns}
      data={reports}
      loading={loading.read}
      paginationSettings={paginationSettings}
      footer={summary ? renderFooter : null}
    />
  );
};

export default ReportsItem;
