import { CREATE_USER, UPDATE_USER, UPDATE_USER_PROJECTS } from '../actions/types';

export const manageUsersReducer = (state, { payload, type }) => {
  switch (type) {
    case CREATE_USER:
      return { ...state, title: 'צור משתמש חדש', ...payload, user: null, editProjects: false };
    case UPDATE_USER:
      return {
        ...state,
        title: `עריכת פרטים ל${payload.user.first_name}`,
        ...payload,
        editProjects: false,
      };
    case UPDATE_USER_PROJECTS:
      return {
        ...state,
        title: `עדכון פרויקטים ל${payload.user.first_name}`,
        ...payload,
        editProjects: true,
      };
    default:
      return { ...state };
  }
};
