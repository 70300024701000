import { Button, DatePicker, Form, Input, Modal, Select } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import UploadDocument from '../upload/UploadDocument';
import { resetReport } from '../../actions/timeReportActions';
import TimePickerComponent from '../calendars/TimePickerComponent';
import logger from '../../logger';
import dayjs from 'dayjs';
import { DocumentModeDictionary } from '../../utils/handleDocumentMode';
import { CalendarOutlined } from '@ant-design/icons';

const initValues = {
  activity_id: undefined,
  in_time: null,
  location_id: undefined,
  out_time: null,
  project_id: undefined,
  user_id: undefined,
  comments: '',
  file_id: undefined,
};

const TimeReportInlineForm = ({
  className = '',
  documentMode,
  setDocumentMode,
  layout = null,
  onFinish,
  onUpdateFiles,
  projects = [],
  locations = [],
  getActivities,
  disabledDate,
  hidden,
  getUserTimeReportFromClock,
  userPreferences,
  selectedDate,
  loading,
}) => {
  const [projectOptions, setProjectOptions] = useState([]);
  const [activitiesOptions, setActivitiesOptions] = useState([]);
  // const [disableSave, setDisableSave] = useState(true);
  const [locationsOptions, setLocationsOptions] = useState([]);
  const [activities, setActivities] = useState([]);
  const [formValues, setFormValues] = useState(initValues);
  const [commentsModalOpen, setCommentsModalOpen] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [isGeneral, setIsGeneral] = useState(false);
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const normFile = (e) => {
    // logger.info('Upload event:', e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const displaySelectedDate = (date) => {
    return date && !disabledDate(date) ? dayjs(date).format('DD/MM/YYYY') : null;
  };

  const onFieldValueChanged = (_, allFields) => {
    // const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);

    if (_[0].name[0] === 'project_id' && _[0].value !== formValues.project_id) {
      form.setFieldValue('activity_id', undefined);
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        activity_id: undefined,
      }));
      setDocumentMode(DocumentModeDictionary.NO_NEED); //reset docment mode when clearing activity
      setActivities([]);
    }
    if (_[0].value) {
      //clears errors when selected value
      form.setFields([{ name: _[0].name[0], errors: [] }]);
    }

    if (_[0].name[0] === 'activity_id' && _[0].value !== formValues.activity_id) {
      form.setFields([
        {
          name: 'upload',
          errors: [],
        },
      ]);
      setDocumentMode(() => {
        const document_mode = activities.find((activity) => activity.id === _[0].value)?.document_mode;
        return DocumentModeDictionary[document_mode ?? 'NO_NEED'];
      });
    }

    // if (formValues.activity_id) {
    //   setDocumentMode(() => {
    //     const document_mode = activities.find(
    //       (activity) => activity.id === formValues.activity_id
    //     )?.document_mode;

    //     return DocumentModeDictionary[document_mode];
    //   });
    // }

    // const isFilled = allValuesFilled();
    // setDisableSave(hasErrors /*|| !isFilled*/);
    // setDisableSave(hasErrors || !form.isFieldsTouched(true));
    // setDisabledReset(!isFilled && isLocationAvailable);
  };

  const onFormValuesChange = (prop) => {
    setFormValues({ ...formValues, ...prop });
  };

  const setFavoriteProjectAndActivity = useCallback(
    (forceSet) => {
      const { favorite_project, favorite_activity } = userPreferences;
      const { project_id, activity_id } = formValues;

      if (favorite_project && projects.some((p) => p.id === favorite_project.id))
        if ((!project_id && !activity_id) || forceSet) {
          if (favorite_project) {
            form.setFieldsValue({ project_id: favorite_project.id });
            setFormValues({ ...formValues, project_id: favorite_project.id });
            if (favorite_activity) {
              form.setFieldsValue({ activity_id: favorite_activity.id });
              setFormValues((prevFormValues) => ({
                ...prevFormValues,
                activity_id: favorite_activity.id,
              }));
            }
          }
        } else if (project_id && !activity_id) {
          if (project_id === favorite_project?.id && favorite_activity) {
            form.setFieldsValue({ activity_id: favorite_activity.id });
            setFormValues((prevFormValues) => ({
              ...prevFormValues,
              activity_id: favorite_activity.id,
            }));
          }
        }
    },
    [form, formValues, projects, userPreferences]
  );

  const handleDateChange = (date, dateString, forceSet = false) => {
    if (!dateString.length || !date?.isValid()) return;
    if (date.isValid() && disabledDate(date)) {
      form.setFieldValue('date', null);
      return;
    } else {
      // clears the date error if exists
      form.setFields([
        {
          name: 'date',
          errors: [],
        },
      ]);
    }

    getUserTimeReportFromClock(form, dateString).then((foundHours) => {
      if (foundHours) {
        // Set location to office if reports are found
        const officeLocation = locationsOptions.find((loc) => loc.label === 'משרד');
        form.setFieldsValue({ location_id: officeLocation ? officeLocation.value : undefined });
      } else {
        // Reset location if no reports found
        form.resetFields(['location_id']);
      }
    });
    if (userPreferences) {
      //insert favorite project & activity if exists
      setFavoriteProjectAndActivity(forceSet);
    }
  };

  const handleFinish = async (values) => {
    const parsedvalues = { ...initValues }; // copy the object
    // const date = dayjs(values.date);
    parsedvalues.activity_id = values.activity_id;
    if (isGeneral) {
      parsedvalues.in_time = values.startDate.hour(0).minute(0).format('DD/MM/YYYY HH:mm');
      parsedvalues.out_time = values.endDate.hour(0).minute(0).format('DD/MM/YYYY HH:mm');
    } else {
      const in_hour = values.in_time ? dayjs(values.in_time).hour() : 0;
      const in_minutes = values.in_time ? dayjs(values.in_time).minute() : 0;
      const out_hour = values.out_time ? dayjs(values.out_time).hour() : 0;
      const out_minutes = values.out_time ? dayjs(values.out_time).minute() : 0;

      parsedvalues.in_time = values.date.hour(in_hour).minute(in_minutes).format('DD/MM/YYYY HH:mm');
      parsedvalues.out_time = values.date.hour(out_hour).minute(out_minutes).format('DD/MM/YYYY HH:mm');
    }

    parsedvalues.project_id = values.project_id;

    parsedvalues.user_id = values.user_id;
    parsedvalues.location_id = values.location_id;
    parsedvalues.comments = values.comments ?? '';
    // parsedvalues.file_id = // get file id after uploading the file
    if (documentMode !== DocumentModeDictionary.NO_NEED) {
      try {
        if (values.upload !== undefined) {
          await onUpdateFiles(values.upload).then((file_id) => {
            if (file_id) {
              parsedvalues.file_id = file_id;
            }
          });
        }
      } catch {
        return;
      }
    }

    onFinish(parsedvalues).then((success) => {
      if (success) {
        form.resetFields();
        setDocumentMode(DocumentModeDictionary.NO_NEED);
        setFormValues(initValues); // set selectedDate.current if default proj not loaded on refresh
        form.setFields([
          {
            name: 'date',
            value: selectedDate.current,
          },
        ]);
        dispatch(resetReport());
        logger.info('Time report save success');
      } else logger.info('Time report save failed');
    });
  };

  // const validateTimes = ({ getFieldValue }) => ({
  //   validator(_, value) {
  //     const inTime = getFieldValue('in_time');
  //     if (!value || !inTime || dayjs(value).isAfter(inTime) || (isGeneral && dayjs(value).isSame(inTime))) {
  //       return Promise.resolve();
  //     }
  //     return Promise.reject(new Error('זמן סיום חייב להיות אחרי זמן התחלה'));
  //   },
  // });

  const checkIfDisableEndDate = (value) => {
    // checks if endDate is greater than start date
    const disDate = disabledDate(value);
    const res = dayjs(value).isBefore(form.getFieldValue('startDate'));
    return res || disDate;
  };

  useEffect(() => {
    setProjectOptions(
      projects.map((project) => ({
        value: project.id,
        label: project.project_name,
      }))
    );
    setLocationsOptions(
      locations.map((location) => ({
        value: location.id,
        label: location.location_name,
      }))
    );
    setActivitiesOptions(
      activities.map((activity) => ({
        value: activity.id,
        label: activity.activity_name,
      }))
    );
  }, [activities, locations, projects]);

  useEffect(() => {
    if (formValues.project_id) {
      var project = projects.find((project) => project.id === formValues.project_id);
      if (project) {
        setIsGeneral(project.is_general);
      }
      getActivities(formValues.project_id).then((res) => {
        if (res) setActivities(res.data);
      });
    } else setIsGeneral(false);
  }, [form, formValues.project_id, projects, getActivities]);

  useEffect(() => {
    if (selectedDate && selectedDate.current) {
      setFormValues({
        ...formValues,
        project_id: undefined,
        activity_id: undefined,
        date: selectedDate.current,
      });
      form.setFields([
        { name: 'project_id', value: undefined },
        { name: 'activity_id', value: undefined },
        { name: 'date', value: selectedDate.current },
      ]);
      setIsGeneral(false);
      handleDateChange(selectedDate.current, selectedDate.current.format('DD/MM/YYYY'), true);
    }
    // eslint-disable-next-line
  }, [selectedDate]);

  useEffect(() => {
    if (userPreferences && selectedDate && projects?.length) {
      //insert favorite project & activity if exists
      setFavoriteProjectAndActivity(false);
    }
    // eslint-disable-next-line
  }, [selectedDate, userPreferences, projects]);

  return (
    <div style={{ minHeight: '35px' }}>
      <Form
        form={form}
        name='create_form'
        layout={layout}
        onFinish={handleFinish}
        onFieldsChange={(changedFields, allFields) => {
          // handle changes without triggering validation
          onFieldValueChanged(changedFields, allFields);
        }}
        onValuesChange={(changedValues, allValues) => {
          // handle changes without triggering validation
          onFormValuesChange(changedValues, allValues);
        }}
      >
        <Form.Item
          name='project_id'
          validateTrigger='onSubmit'
          rules={[
            {
              required: true,
              message: 'נא לבחור פרויקט',
            },
          ]}
        >
          <Select
            allowClear
            style={{ maxWidth: '180px', width: '100vh' }}
            options={projectOptions}
            placeholder='פרויקט'
          />
        </Form.Item>
        <Form.Item
          name='activity_id'
          validateTrigger='onSubmit'
          rules={[
            {
              required: true,
              message: 'נא לבחור פעילות',
            },
          ]}
        >
          <Select
            style={{ maxWidth: '180px', width: '100vh' }}
            allowClear
            options={activitiesOptions}
            placeholder='פעילות'
          />
        </Form.Item>
        {!isGeneral ? (
          <Form.Item
            name='date'
            validateTrigger='onSubmit'
            valuePropName='date'
            rules={[
              {
                required: true,
                message: 'נא לבחור תאריך',
              },
            ]}
          >
            <Input
              value={displaySelectedDate(selectedDate.current)}
              readOnly
              disabled={disabledDate(selectedDate.current)}
              status={disabledDate(selectedDate.current) ? 'error' : null}
              placeholder={disabledDate(selectedDate.current) ? '(לא ניתן לדווח על יום זה)' : null}
              suffix={
                <CalendarOutlined
                  style={{ color: !disabledDate(selectedDate.current) ? '#00000040' : 'red' }}
                />
              }
            />
          </Form.Item>
        ) : (
          <>
            <Form.Item
              name='startDate'
              validateTrigger='onSubmit'
              rules={[
                {
                  required: true,
                  message: 'נא לבחור תאריך התחלה',
                },
              ]}
            >
              <DatePicker
                placeholder='תאריך התחלה'
                disabledDate={disabledDate}
              />
            </Form.Item>

            <Form.Item
              name='endDate'
              validateTrigger='onSubmit'
              rules={[
                {
                  required: true,
                  message: 'נא לבחור תאריך סיום',
                },
              ]}
            >
              <DatePicker
                placeholder='תאריך סיום'
                disabledDate={checkIfDisableEndDate}
              />
            </Form.Item>
          </>
        )}
        <Form.Item
          name='location_id'
          validateTrigger='onSubmit'
          rules={[
            {
              required: true,
              message: 'נא לבחור מיקום',
            },
          ]}
        >
          <Select
            style={{ maxWidth: '180px', width: '100vh' }}
            allowClear
            // showSearch
            options={locationsOptions}
            optionFilterProp='children'
            // filterOption={(input, option) => (option?.label ?? '').includes(input)}
            // filterSort={(optionA, optionB) => (optionA?.label ?? '').localeCompare(optionB?.label ?? '')}
            placeholder='מיקום'
          />
        </Form.Item>

        {!isGeneral && (
          <>
            <Form.Item
              name='in_time'
              validateTrigger='onSubmit'
              rules={[
                {
                  required: !isGeneral,
                  message: 'נא לבחור זמן התחלה',
                },
              ]}
              dependencies={['out_time']}
            >
              <TimePickerComponent placeholder='זמן התחלה' />
            </Form.Item>
            <Form.Item
              name='out_time'
              validateTrigger='onSubmit'
              rules={[
                {
                  required: !isGeneral,
                  message: 'נא לבחור זמן סיום',
                },
              ]}
              dependencies={['in_time']}
            >
              <TimePickerComponent placeholder='זמן סיום' />
            </Form.Item>
          </>
        )}
        <Form.Item name='comments'>
          <Input.TextArea
            placeholder='הוסף הערות'
            readOnly
            allowClear
            maxLength={200}
            autoSize={{
              maxRows: 1,
            }}
            onClick={() => setCommentsModalOpen(true)}
          />
        </Form.Item>
        {isGeneral && documentMode !== DocumentModeDictionary.NO_NEED && (
          <Form.Item
            name='upload'
            valuePropName='fileList'
            getValueFromEvent={normFile}
            validateTrigger='onSubmit'
            rules={[
              {
                required: documentMode === DocumentModeDictionary.MANDATORY,
                message: 'נא לצרף קובץ!',
              },
            ]}
          >
            <UploadDocument
              name='attachment'
              fileList={fileList}
              onChange={(info) => setFileList(info.fileList)}
            />
          </Form.Item>
        )}
        <Form.Item shouldUpdate>
          {() => (
            <Button
              type='primary'
              htmlType='submit'
              // disabled={disableSave}
              loading={loading.update}
              //   !form.isFieldsTouched(true) ||
              //   !!form.getFieldsError().filter(({ errors }) => errors.length).length
              // }
            >
              שליחה
            </Button>
          )}
        </Form.Item>
        <Modal
          open={commentsModalOpen}
          title='הערות'
          onCancel={() => setCommentsModalOpen(false)}
          footer={null}
        >
          <Form.Item name='comments'>
            <Input.TextArea
              allowClear
              maxLength={200}
              showCount
              autoSize={{
                minRows: 4,
                maxRows: 6,
              }}
            />
          </Form.Item>
        </Modal>
      </Form>
    </div>
  );
};

export default TimeReportInlineForm;
