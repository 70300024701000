import { useCallback, useState } from 'react';
import {
  createNewProject,
  getAllProjects,
  getProjectsByCurrentUser,
  getProjectsForReporting,
  updateExistingProject,
} from '../services/projects.js';
import logger from '../logger.js';
import { handleError } from '../utils/handleErrors.js';

const useProjectManagement = (initialToken) => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [token, setToken] = useState(initialToken);

  // load all projects
  const loadProjects = useCallback(() => {
    setLoading(true);
    getAllProjects(token)
      .then((res) => {
        setProjects(res.data);
      })
      .catch((error) => {
        const parsedError = handleError(error);
        logger.error(error, true);
        // toast.error(parsedError.toastMessage);
        setError(parsedError);
      })
      .finally(() => setLoading(false));
  }, [token]);

  const createProject = (data) => {
    setLoading(true);
    const projectName = data.project_name;
    return createNewProject(data, token)
      .then(() => {
        loadProjects();
        return true;
      })
      .catch((error) => {
        const parsedError = handleError(error, projectName);
        logger.error(error, true);
        // toast.error(parsedError.toastMessage);
        setError(parsedError);
        return false;
      })
      .finally(() => setLoading(false));
  };

  const updateProject = (data) => {
    const { id, ...restData } = data;
    setLoading(true);
    const projectName = data.project_name;
    return updateExistingProject(restData, id, token)
      .then(() => {
        loadProjects();
        return true;
      })
      .catch((error) => {
        const parsedError = handleError(error, projectName);
        logger.error(error, true);
        // toast.error(parsedError.toastMessage);
        setError(parsedError);
        return false;
      })
      .finally(() => setLoading(false));
  };

  const getUserProjects = useCallback(() => {
    setLoading(true);
    return getProjectsByCurrentUser(token)
      .catch((error) => {
        const parsedError = handleError(error);
        logger.error(error);
        // toast.error(parsedError.toastMessage);
        setError(parsedError);
        return false;
      })
      .finally(() => setLoading(false));
  }, [token]);

  const getProjectsForReports = useCallback(() => {
    setLoading(true);
    return getProjectsForReporting(token)
      .catch((error) => {
        const parsedError = handleError(error);
        logger.error(error, true);
        // toast.error(parsedError.toastMessage);
        setError(parsedError);
        return false;
      })
      .finally(() => setLoading(false));
  }, [token]);

  const setAuthToken = (token) => {
    setToken(token);
  };

  return {
    projects,
    loading,
    error,
    loadProjects,
    createProject,
    updateProject,
    getUserProjects,
    getProjectsForReports,
    setAuthToken,
  };
};

export default useProjectManagement;
