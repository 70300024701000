import Title from 'antd/es/typography/Title';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getAuthState } from '../../utils';
import usePermissionManagement from '../../hooks/usePermissionManagement';
import { toast } from 'react-toastify';
import CustomModal from '../modals/CustomModal';
import PermissionForm from '../forms/PermissionForm';
import { Card, Col, List, Row, Typography } from 'antd';
import LocalSearch from '../forms/LocalSearch';
import TooltipButton from '../buttons/TooltipButton';
import { EditOutlined } from '@ant-design/icons';
import authWrapper from '../../utils/authWrapper';
import Meta from '../Meta';

const { Text } = Typography;

const ManagePermissions = () => {
  const { token } = useSelector((state) => getAuthState(state));
  const [editedPermission, setEditedPermission] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { permissions, loading, updatePermission } = usePermissionManagement(token);
  const [keyword, setKeyword] = useState('');

  const handleEditPermission = (permission) => {
    setEditedPermission(permission);
    handleToggleModal();
  };

  const handleUpdate = (data) => {
    return updatePermission(data).then((success) => {
      if (success) {
        toast.success('עדכון ההרשאה התבצע בהצלחה!');
        setEditedPermission(null);
        handleToggleModal();
        return true;
      } else {
        return false;
      }
    });
  };

  const handleToggleModal = () => {
    setIsModalOpen((state) => !state);
  };

  const handleCancelModal = () => {
    setEditedPermission(null);
    handleToggleModal?.();
  };

  const searchFilter = (keyword) => (permission) => {
    return permission.permission_name.includes(keyword);
  };

  return (
    <div className='container w-75 m-2'>
      <Meta
        title={'ניהול הרשאות'}
        description={'מסך ניהול הרשאות'}
      />
      <Title
        level={1}
        className='text-center'
      >
        ניהול הרשאות
      </Title>
      <Card className='p-1'>
        <Row justify={'space-around'}>
          <Col span={10}>
            <LocalSearch
              keyword={keyword}
              setKeyword={setKeyword}
            />
            <List
              pagination={{
                position: 'bottom',
                pageSize: 8,
                defaultCurrent: 1,
                className: 'text-center',
                hideOnSinglePage: true,
              }}
              dataSource={permissions.filter(searchFilter(keyword))}
              renderItem={(permission) => (
                <List.Item
                  key={permission._id}
                  actions={[
                    <TooltipButton
                      placement='top'
                      type='text'
                      title='ערוך הרשאה'
                      icon={<EditOutlined />}
                      onClick={() => handleEditPermission(permission)}
                    ></TooltipButton>,
                  ]}
                >
                  <List.Item.Meta
                    title={<Text>{permission.label ?? `${permission.permission_name} (לא הוגדר)`}</Text>}
                  />
                </List.Item>
              )}
            />
          </Col>
        </Row>
      </Card>
      <CustomModal
        modalTitle={'עדכון הרשאה'}
        isModalOpen={isModalOpen}
        handleCancel={handleCancelModal}
        width={'fit-content'}
      >
        <PermissionForm
          name={'updatePermission'}
          permissionDetails={editedPermission}
          isLoading={loading}
          onConfirm={handleUpdate}
        />
      </CustomModal>
    </div>
  );
};

export default authWrapper('departments')(ManagePermissions);
