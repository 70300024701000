import { useCallback, useState } from 'react';
import {
  createNewActivity,
  getActivitiesByProjectId,
  getAllActivities,
  updateExistingActivity,
} from '../services/activities.js';
import logger from '../logger.js';
import { handleError } from '../utils/handleErrors.js';

const useActivityManagement = (initialToken) => {
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [token, setToken] = useState(initialToken);

  // load all activities
  const loadActivities = useCallback(() => {
    setLoading(true);
    getAllActivities(token)
      .then((res) => {
        setActivities(res.data);
      })
      .catch((error) => {
        const parsedError = handleError(error);
        logger.error(error, true);
        // toast.error(parsedError.toastMessage);
        setError(parsedError);
      })
      .finally(() => setLoading(false));
  }, [token]);

  // useEffect(() => {
  //   loadActivities();
  // }, [loadActivities]);

  const createActivity = (data) => {
    setLoading(true);
    const activityName = data.activity_name;
    return createNewActivity(data, token)
      .then(() => {
        loadActivities();
        return true;
      })
      .catch((error) => {
        const parsedError = handleError(error, activityName);
        logger.error(error, true);
        // toast.error(parsedError.toastMessage);
        setError(parsedError);
        return false;
      })
      .finally(() => setLoading(false));
  };

  const updateActivity = (data) => {
    const { id, ...restData } = data;
    setLoading(true);
    const activityName = data.activity_name;
    return updateExistingActivity(restData, id, token)
      .then(() => {
        loadActivities();
        return true;
      })
      .catch((error) => {
        const parsedError = handleError(error, activityName);
        logger.error(error, true);
        // toast.error(parsedError.toastMessage);
        setError(parsedError);
        return false;
      })
      .finally(() => setLoading(false));
  };

  const getActivitiesByProject = useCallback(
    (projectId) => {
      setLoading(true);
      return getActivitiesByProjectId(projectId, token)
        .catch((error) => {
          const parsedError = handleError(error);
          logger.error(error, true);
          // toast.error(parsedError.toastMessage);
          setError(parsedError);
          return false;
        })
        .finally(() => setLoading(false));
    },
    [token]
  );

  const setAuthToken = (token) => {
    setToken(token);
  };

  return {
    activities,
    loading,
    error,
    loadActivities,
    createActivity,
    updateActivity,
    getActivitiesByProject,
    setAuthToken,
  };
};

export default useActivityManagement;
