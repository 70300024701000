import React, { useEffect, useState } from 'react';
import { Avatar, Menu, Space } from 'antd';
import {
  UserOutlined,
  LogoutOutlined,
  FieldTimeOutlined,
  FileDoneOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getPageLayoutState, getAuthState } from '../../utils';
import { logout } from '../../services/auth';
import { logoutUser } from '../../actions/authActions';
import logger from '../../logger';
import config from '../../config';

const HeaderNav = () => {
  let dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { LOGO_URL } = config;
  const { user, token } = useSelector((state) => getAuthState(state));
  const [currentPage, setCurrent] = useState('');
  const { isMobile } = useSelector((state) => getPageLayoutState(state));
  // const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    setCurrent(location?.pathname.split('/')[1] || 'home');
  }, [location?.pathname]);

  const handleClick = (e) => {
    setCurrent(e.key);
  };

  const handleLogout = () => {
    logout(token)
      .then((res) => {
        if (res.status === 200) {
          logger.info(res.data?.message);
        }
      })
      .catch((error) => {
        logger.error(error);
      })
      .finally(() => {
        dispatch(logoutUser());
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        navigate('/');
      });
  };

  const loggedInNavItems = () => {
    return user
      ? [
          {
            key: 'time',
            icon: <FieldTimeOutlined />,
            label: <Link to='/time'>זמן</Link>,
          },
          {
            key: 'reports',
            icon: <FileDoneOutlined />,
            label: <Link to='/reportManagement'>דוחות</Link>,
          },
          user.is_admin && {
            key: 'administration',
            icon: <TeamOutlined />,
            label: <Link to='/administration'>ניהול</Link>,
          },
        ]
      : [];
  };

  const userActionItems = () => {
    const userItems = [];
    if (user) {
      userItems.push({
        key: 'SubMenu',
        label: (
          <div>
            <Avatar
              style={{
                backgroundColor: 'grey',
              }}
              icon={<UserOutlined />}
            />{' '}
            {user?.first_name}
          </div>
        ),
        className: 'ms-auto',
        children: [
          {
            key: 'myDetails',
            icon: <UserOutlined />,
            label: <Link to='/user/details'>הפרטים שלי</Link>,
          },
          {
            key: 'logout',
            icon: <LogoutOutlined />,
            className: 'ms-auto',
            label: (
              <Link
                onClick={handleLogout}
                to={''}
              >
                התנתקות
              </Link>
            ),
          },
        ],
      });
    } else {
      userItems.push({
        key: 'login',
        icon: <UserOutlined />,
        className: 'ms-auto',
        label: (
          <Link
            to={{
              pathname: '/login',
              state: { redirectTo: location.pathname },
            }}
            state={{ redirectTo: location.pathname }}
          >
            התחברות
          </Link>
        ),
      });
    }
    return userItems;
  };

  const handleNavbarItems = () => {
    const nav = [];

    if (isMobile) {
      nav.push({
        key: 'homem',
        label: (
          <Link to='/'>
            <Avatar
              src={LOGO_URL}
              alt='KTM'
              size={'large'}
            />
          </Link>
        ),
      });
      if (user)
        nav.push({
          key: 'time',
          icon: <FieldTimeOutlined />,
          label: <Link to='/time'>זמן</Link>,
        });
    } else {
      nav.push(
        {
          key: 'homed',
          label: (
            <Link to='/'>
              <Space size={'small'}>
                <Avatar
                  src={LOGO_URL}
                  alt='KTM'
                />
                KTM
              </Space>
            </Link>
          ),
        },
        ...loggedInNavItems()
      );
    }
    nav.push(...userActionItems());
    return nav;
  };

  // useEffect(() => {
  //   const mediaQuery = window.matchMedia('(max-width: 595px)');
  //   const handleMediaQueryChange = (e) => setIsSmallScreen(e.matches);
  //   handleMediaQueryChange(mediaQuery);
  //   mediaQuery.addEventListener('change', handleMediaQueryChange);
  //   return () => mediaQuery.removeEventListener('change', handleMediaQueryChange);
  // }, []);

  return (
    <>
      <Menu
        onClick={handleClick}
        selectedKeys={isMobile ? null : [currentPage]}
        mode='horizontal'
        style={{ flex: 'auto', minWidth: 0 }}
        items={handleNavbarItems()}
      />
    </>
  );
};

export default HeaderNav;
