import { useCallback, useEffect, useState } from 'react';
import { createNewRole, getAllRoles, updateExistingRole } from '../services/roles.js';
import logger from '../logger.js';
import { handleError } from '../utils/handleErrors.js';

const useRoleManagement = (initialToken) => {
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [token, setToken] = useState(initialToken);

  // load all roles
  const loadRoles = useCallback(() => {
    setLoading(true);
    getAllRoles(token)
      .then((res) => {
        setRoles(res.data);
      })
      .catch((error) => {
        const parsedError = handleError(error);
        logger.error(error, true);
        // toast.error(parsedError.toastMessage);
        setError(parsedError);
      })
      .finally(() => setLoading(false));
  }, [token]);

  useEffect(() => {
    loadRoles();
  }, [loadRoles]);

  const createRole = (data) => {
    setLoading(true);
    const roleName = data.role_name;
    return createNewRole(data, token)
      .then(() => {
        loadRoles();
        return true;
      })
      .catch((error) => {
        const parsedError = handleError(error, roleName);
        logger.error(error, true);
        // toast.error(parsedError.toastMessage);
        setError(parsedError);
        return false;
      })
      .finally(() => setLoading(false));
  };

  const updateRole = (data) => {
    const { id, ...restData } = data;
    setLoading(true);
    const roleName = data.role_name;
    return updateExistingRole(restData, id, token)
      .then(() => {
        loadRoles();
        return true;
      })
      .catch((error) => {
        const parsedError = handleError(error, roleName);
        logger.error(error, true);
        // toast.error(parsedError.toastMessage);
        setError(parsedError);
        return false;
      })
      .finally(() => setLoading(false));
  };

  const setAuthToken = (token) => {
    setToken(token);
  };

  return {
    roles,
    loading,
    error,
    createRole,
    updateRole,
    setAuthToken,
  };
};

export default useRoleManagement;
