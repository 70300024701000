import axios from 'axios';
import config from '../config';

export const getAllReportsByUser = async (token, date) => {
  return await axios.get(`${config.API_URL}/report/user-reports`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      date: date,
    },
  });
};

export const getReportsByRange = async (queryProps, token) => {
  return await axios.post(`${config.API_URL}/report/users-reports-range`, queryProps, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getMissingReportsByRange = async (queryProps, token) => {
  return await axios.post(`${config.API_URL}/report/users-reports-deficiencies`, queryProps, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getDepartmentReports = async (queryProps, token) => {
  return await axios.post(`${config.API_URL}/report/department-reports`, queryProps, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const createNewTimeReport = async (timeReportDetails, token) => {
  return await axios.post(`${config.API_URL}/report`, timeReportDetails, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateExistingTimeReport = async (timeReportDetails, id, token) => {
  return await axios.put(`${config.API_URL}/report/${id}`, timeReportDetails, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteReportById = async (reportId, token) => {
  return await axios.delete(`${config.API_URL}/report/${reportId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getClockTimeReportByDate = async (requestedReport, token) => {
  return await axios.post(`${config.API_URL}/clock-time-reports-by-request`, requestedReport, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
