import React, { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import dayLocaleData from 'dayjs/plugin/localeData';
import useProjectManagement from '../../hooks/useProjectManagement';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSelectedDateState,
  getDateReportsState,
  getAuthState,
  getDateEventsState,
  getPageLayoutState,
} from '../../utils';
import useLocationManagement from '../../hooks/useLocationManagement';
import useActivityManagement from '../../hooks/useActivityManagement';
import useTimeManagement from '../../hooks/useTimeManagement';
import { duplicateReport, editReport, resetReport } from '../../actions/timeReportActions';
import useDateEventsManagement from '../../hooks/useDateEventsManagement';
import useUpload from '../../hooks/useUpload';

import DesktopTimeLayout from '../../components/layouts/DesktopTimeLayout';
import MobileTimeLayout from '../../components/layouts/MobileTimeLayout';
import Meta from '../../components/Meta';
import { DocumentModeDictionary } from '../../utils/handleDocumentMode';
import useUserPersonalDetails from '../../hooks/useUserPersonalDetails';

dayjs.extend(dayLocaleData);

const TypesOfDate = {
  0: 'יום עבודה רגיל',
  1: 'חצי יום (ע"ח קורן טק)',
  2: 'יום שלם (ע"ח קורן טק)',
};

const Time = () => {
  const dispatch = useDispatch();
  const { user, token } = useSelector((state) => getAuthState(state));
  const selectedDate = useSelector((state) => getSelectedDateState(state));
  const timeReports = useSelector((state) => getDateReportsState(state));
  const dateEvents = useSelector((state) => getDateEventsState(state));
  const { isMobile } = useSelector((state) => getPageLayoutState(state));
  const { getUserProjects } = useProjectManagement(token);
  const { locations } = useLocationManagement(token);
  const { getActivitiesByProject } = useActivityManagement(token);
  const {
    loadUserReports,
    loading,
    createTimeReport,
    updateTimeReport,
    deleteTimeReport,
    timeReportFromClock,
  } = useTimeManagement(token);
  const { loading: calendarLoading, loadDateEventsByDate } = useDateEventsManagement(token);
  const { userPreferences, getPreferences } = useUserPersonalDetails(token);
  const { uploadFiles } = useUpload(token);

  const [projects, setProjects] = useState([]);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [documentMode, setDocumentMode] = useState(DocumentModeDictionary.NO_NEED);
  const [combinedReports, setCombinedReports] = useState([]);

  const loadReports = useCallback(() => {
    const parsedDate = selectedDate.current !== null ? selectedDate.current.format('YYYY-MM-DD') : null;
    loadUserReports(parsedDate).then((success) => {
      if (!success) {
        dispatch(resetReport());
      }
    });

    if (!selectedDate.current.isSame(selectedDate.prev, 'month')) {
      loadDateEventsByDate(parsedDate, [user.department_id]);
    }
  }, [dispatch, loadDateEventsByDate, loadUserReports, selectedDate, user]);

  useEffect(() => {
    getUserProjects(user.id).then((res) => {
      if (res) setProjects(res.data);
    });
  }, [getUserProjects, user?.id]);

  useEffect(() => {
    loadReports();
    getPreferences();
  }, [loadReports, getPreferences]);

  const combineReports = (timeReports, parsedEvents) => {
    const combinedList = [...parsedEvents];

    timeReports.forEach((timeReport) => {
      const matchingEvent = combinedList.find((event) => event.date === timeReport.date);

      if (matchingEvent) {
        matchingEvent.reports = [...matchingEvent.reports, ...timeReport.reports];
      } else {
        combinedList.push({ date: timeReport.date, reports: [...timeReport.reports] });
      }
    });

    // Sort the combined list by date in descending order
    combinedList.sort((a, b) => new Date(b.date) - new Date(a.date));

    return combinedList;
  };

  useEffect(() => {
    if (dateEvents && dateEvents.length) {
      const parsedEvents = dateEvents
        .map((de) => ({
          date: de.date,
          reports: de.events
            .filter((ev) => ev.event_type !== 0) // filter regular days date events
            .map((ev) => ({
              id: 0,
              in_time: `${ev.start_date}T00:00:00`,
              out_time: `${ev.end_date}T00:00:00`,
              project: { project_name: 'אירוע יומן' },
              activity: { activity_name: ev.event_name },
              location: { location_name: '-' },
              comments: '(מילוי אוטומטי)',
              details: TypesOfDate[ev.event_type],
            })),
        }))
        .filter((pe) => pe.reports.length > 0); // filter days with no reports

      setCombinedReports(combineReports(timeReports, parsedEvents));
    } else setCombinedReports(timeReports);
  }, [dateEvents, timeReports]);

  const createReport = (values) => {
    return createTimeReport({ ...values, user_id: user.id }).then((success) => {
      if (success) loadReports();
      return success;
    });
  };

  const getUserTimeReportFromClock = (form, date, attId) => {
    return timeReportFromClock(date, attId ?? user.att_id)
      .then((clockHours) => {
        form.resetFields(['in_time', 'out_time']);
        let foundClockHours = false;

        if (clockHours.length > 2) {
          // Multiple reports found
          foundClockHours = true;
        } else if (clockHours.length === 2) {
          // In & Out reports found
          form.setFieldsValue({
            in_time: clockHours[0].time_report,
            out_time: clockHours[1].time_report,
          });
          foundClockHours = true;
        } else if (clockHours.length === 1) {
          // Only one report found
          const [reportHours] = clockHours;
          form.setFieldsValue({
            [reportHours.in_out_mode ? 'out_time' : 'in_time']: reportHours.time_report,
          });
          foundClockHours = true;
        }
        return foundClockHours;
      })
      .catch((error) => {
        // logger.debug('No clock reports found for', dateString);
        return false;
      });
  };

  const handleUpload = (fileList) => {
    if (fileList === undefined) return null;
    const formData = new FormData();
    formData.append('file', fileList[0].originFileObj);
    return uploadFiles(formData).then((file_id) => {
      return file_id;
    });
    //call saveFile method
  };

  const updateReport = (values) => {
    return updateTimeReport({ ...values, user_id: user.id }).then((success) => {
      if (success) {
        loadReports();
        setEditModalOpen(false);
      }
      return success;
    });
  };

  const handleEdit = (values) => {
    dispatch(editReport(values));
    setEditModalOpen(true);
  };

  const onCloseEditModal = () => {
    setDocumentMode(DocumentModeDictionary.NO_NEED);
    setEditModalOpen(false);
    dispatch(resetReport());
  };

  const handleDelete = (values) => {
    if (values && values.id)
      deleteTimeReport(values.id).then((success) => {
        if (success) {
          loadUserReports();
        }
      });
  };

  const handleDuplicate = (values) => {
    dispatch(duplicateReport(values));
    // if (isMobile) {
    setEditModalOpen(true);
    // }
  };

  const filteredTimeReports = (filterBy) => {
    const choosenDate = dayjs(selectedDate.current);
    if (combinedReports) {
      let filteredReports = [];
      if (filterBy) {
        switch (filterBy) {
          case 'day':
            filteredReports = combinedReports.filter((reports) =>
              dayjs(reports.date).isSame(choosenDate, 'day')
            );
            break;
          case 'week':
            filteredReports = combinedReports.filter((reports) =>
              dayjs(reports.date).isSame(choosenDate, 'week')
            );
            break;
          case 'month':
          default:
            filteredReports = combinedReports.filter((reports) =>
              dayjs(reports.date).isSame(choosenDate, 'month')
            );
            break;
        }
      }
      return filteredReports;
    }
  };

  const disabledDate = (current) => {
    const today = dayjs();

    // check if the current date has an event with event_type === 2
    const hasEventWithType2 = dateEvents?.some(
      (ev) => ev.date === current.format('YYYY-MM-DD') && ev.events.some((event) => event.event_type === 2)
    );

    // check the document mode conditions
    let disableDate = false;
    if (documentMode === DocumentModeDictionary.MANDATORY) {
      // disable dates after 3 weeks from today
      disableDate = current > today.add(3, 'week');
    } else if (
      documentMode !== DocumentModeDictionary.NO_NEED &&
      documentMode !== DocumentModeDictionary.OPTIONAL
    ) {
      // if document mode is MANDATORY, disable dates
      disableDate = current > today;
    }

    // return true if the date should be disabled
    return hasEventWithType2 || disableDate;
  };

  return (
    <>
      <Meta
        title={'דיווח שעות'}
        description={'דיווח שעות יומי'}
      />
      {isMobile ? (
        <MobileTimeLayout
          documentMode={documentMode}
          setDocumentMode={setDocumentMode}
          createReport={createReport}
          handleUpload={handleUpload}
          editModalOpen={editModalOpen}
          setEditModalOpen={setEditModalOpen}
          projects={projects}
          locations={locations}
          getActivitiesByProject={getActivitiesByProject}
          handleDuplicate={handleDuplicate}
          loading={loading}
          onCloseEditModal={onCloseEditModal}
          updateReport={updateReport}
          dateEvents={dateEvents}
          calendarLoading={calendarLoading}
          timeReports={timeReports}
          disabledDate={disabledDate}
          filteredTimeReports={filteredTimeReports}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          getUserTimeReportFromClock={getUserTimeReportFromClock}
          userPreferences={userPreferences}
        />
      ) : (
        <DesktopTimeLayout
          documentMode={documentMode}
          setDocumentMode={setDocumentMode}
          createReport={createReport}
          handleUpload={handleUpload}
          editModalOpen={editModalOpen}
          projects={projects}
          locations={locations}
          getActivitiesByProject={getActivitiesByProject}
          handleDuplicate={handleDuplicate}
          loading={loading}
          onCloseEditModal={onCloseEditModal}
          updateReport={updateReport}
          dateEvents={dateEvents}
          calendarLoading={calendarLoading}
          timeReports={timeReports}
          disabledDate={disabledDate}
          filteredTimeReports={filteredTimeReports}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          getUserTimeReportFromClock={getUserTimeReportFromClock}
          userPreferences={userPreferences}
          selectedDate={selectedDate}
        />
      )}
    </>
  );
};

export default Time;
