import { Drawer, FloatButton, Layout, Segmented } from 'antd';
import { Content } from 'antd/es/layout/layout';
import TimeReportGridForm from '../forms/TimeReportGridForm';
import TimeReportsList from '../../components/lists/TimeReportsList';
import TimeCalendar from '../calendars/TimeCalendar';
import { MdAdd, MdOutlineCalendarToday } from 'react-icons/md';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getManagedReportState } from '../../utils';
import CustomModal from '../modals/CustomModal';

const reportsViewOptions = [
  {
    label: 'חודש',
    value: 'month',
  },
  {
    label: 'שבוע',
    value: 'week',
  },
  {
    label: 'יום',
    value: 'day',
  },
];

const MobileTimeLayout = ({
  documentMode,
  setDocumentMode,
  handleUpload,
  editModalOpen: isModalOpen,
  setEditModalOpen: setIsModalOpen,
  projects,
  locations,
  disabledDate,
  getActivitiesByProject,
  handleDuplicate,
  loading,
  onCloseEditModal,
  updateReport,
  dateEvents,
  calendarLoading,
  timeReports,
  filteredTimeReports,
  handleEdit,
  handleDelete,
  getUserTimeReportFromClock,
  userPreferences,
}) => {
  const [openCalender, setOpenCalender] = useState(false);
  const [reportsView, setReportsView] = useState('week');
  const managedReport = useSelector((state) => getManagedReportState(state));
  const modalTitle = managedReport.project_id
    ? managedReport.id
      ? 'עריכת דיווח'
      : 'שיכפול דיווח'
    : 'דיווח חדש';

  return (
    <Layout>
      <FloatButton.Group
        className={openCalender || isModalOpen ? 'collapse' : ''}
        style={{
          zIndex: 1001,
        }}
      >
        <FloatButton
          icon={<MdAdd />}
          type='primary'
          onClick={() => setIsModalOpen(true)}
        />
        <FloatButton
          icon={<MdOutlineCalendarToday />}
          onClick={() => setOpenCalender(true)}
        >
          Open
        </FloatButton>
        <FloatButton.BackTop
          // visibilityHeight={400}
          type='primary'
          target={() => document.getElementById('time-layout')}
        />
      </FloatButton.Group>

      <CustomModal
        modalTitle={modalTitle}
        isModalOpen={isModalOpen}
        handleCancel={() => {
          if (isModalOpen) {
            setIsModalOpen(false);
          }
          onCloseEditModal();
        }}
      >
        <TimeReportGridForm
          documentMode={documentMode}
          setDocumentMode={setDocumentMode}
          onFinish={updateReport}
          onUpdateFiles={handleUpload}
          disabledDate={disabledDate}
          projects={projects}
          locations={locations}
          getActivities={getActivitiesByProject}
          handleDuplicate={handleDuplicate}
          getUserTimeReportFromClock={getUserTimeReportFromClock}
          userPreferences={userPreferences}
          loading={loading}
        />
      </CustomModal>

      <Content
        style={{ overflow: 'auto' }}
        id='time-layout'
      >
        <Drawer
          open={openCalender}
          onClose={() => setOpenCalender(false)}
        >
          <TimeCalendar
            dateEvents={dateEvents}
            loading={calendarLoading}
          />
        </Drawer>
        <Segmented
          className='px-3'
          block
          size='large'
          options={reportsViewOptions}
          value={reportsView}
          onChange={setReportsView}
        />
        {timeReports && (
          <TimeReportsList
            timeReports={filteredTimeReports(reportsView)}
            dateEvents={dateEvents}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            handleDuplicate={handleDuplicate}
            loading={loading}
          />
        )}
      </Content>
    </Layout>
  );
};

export default MobileTimeLayout;
