import { useCallback, useEffect, useState } from 'react';
import { createNewLocation, getAllLocations, updateExistingLocation } from '../services/locations.js';
import logger from '../logger.js';
import { handleError } from '../utils/handleErrors.js';
import { toast } from 'react-toastify';

const useLocationManagement = (initialToken) => {
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [token, setToken] = useState(initialToken);

  // load all locations
  const loadLocations = useCallback(() => {
    setLoading(true);
    getAllLocations(token)
      .then((res) => {
        setLocations(res.data);
      })
      .catch((error) => {
        const parsedError = handleError(error);
        logger.error(error, true);
        // toast.error(parsedError.toastMessage);
        setError(parsedError);
      })
      .finally(() => setLoading(false));
  }, [token]);

  useEffect(() => {
    loadLocations();
  }, [loadLocations]);

  const createLocation = (data) => {
    setLoading(true);
    const locationName = data.location_name;
    return createNewLocation(data, token)
      .then(() => {
        loadLocations();
        return true;
      })
      .catch((error) => {
        const parsedError = handleError(error, locationName);
        logger.error(error, true);
        // toast.error(parsedError.toastMessage);
        setError(parsedError);
        return false;
      })
      .finally(() => setLoading(false));
  };

  const updateLocation = (data) => {
    const { id, ...restData } = data;
    setLoading(true);
    const locationName = data.location_name;
    return updateExistingLocation(restData, id, token)
      .then(() => {
        loadLocations();
        return true;
      })
      .catch((error) => {
        const parsedError = handleError(error, locationName);
        logger.error(error);
        toast.error(parsedError.toastMessage);
        setError(parsedError);
        return false;
      })
      .finally(() => setLoading(false));
  };

  const setAuthToken = (token) => {
    setToken(token);
  };

  return {
    locations,
    loading,
    error,
    createLocation,
    updateLocation,
    setAuthToken,
  };
};

export default useLocationManagement;
