import { Button, Col, Divider, Form, Input, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import logger from '../../logger';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

const initUserState = {
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  confirmPassword: '',
};

const PASSWORD_MIN_LENGTH = 4;

const UserDetailsForm = ({ userDetails, isLoading, onConfirm }) => {
  const [form] = Form.useForm();
  const [disabledSave, setDisabledSave] = useState(true);
  const [disabledReset, setDisabledReset] = useState(true);

  useEffect(() => {
    if (userDetails) {
      form.setFieldsValue({
        first_name: userDetails.first_name,
        last_name: userDetails.last_name,
        email: userDetails.email,
        password: '',
        confirmPassword: '',
      });
    }
    setDisabledSave(true);
    setDisabledReset(true);
  }, [form, userDetails]);

  const handleReset = () => {
    form.setFieldsValue({
      ...userDetails,

      password: '',
      confirmPassword: '',
    });

    setDisabledSave(true);
    setDisabledReset(true);
  };

  const hasFormChanged = () => {
    const formValues = form.getFieldsValue();
    const initialValues = {
      first_name: userDetails.first_name,
      last_name: userDetails.last_name,
      email: userDetails.email,
      password: '',
      confirmPassword: '',
    };

    return Object.keys(formValues).some((key) => formValues[key] !== initialValues[key]);
  };

  const onFieldValueChanged = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    const isFilled = allValuesFilled();
    const isChanged = hasFormChanged();
    setDisabledSave(hasErrors || !isFilled || !isChanged);
    setDisabledReset(!isChanged);
  };

  const allValuesFilled = () => {
    const formValues = form.getFieldsValue();
    const { first_name, last_name, email, password, confirmPassword } = formValues;
    return (
      first_name &&
      last_name &&
      email &&
      (password.length === 0 || (password.length >= PASSWORD_MIN_LENGTH && password === confirmPassword))
    );
  };

  const checkPasswords = ({ password, confirmPassword }) => {
    if (password.length && confirmPassword.length) {
      if (password === confirmPassword) return true;
    }
    return false;
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        let userUpdatedDetails = {
          email: values.email,
          first_name: values.first_name,
          last_name: values.last_name,
        };

        if (checkPasswords(values)) {
          userUpdatedDetails = { ...userUpdatedDetails, password: values.password };
        }
        onConfirm(userUpdatedDetails).then((res) => {
          if (res) {
            handleReset();
          }
        });
      })
      .catch((info) => {
        logger.info('Validate Failed:', info);
      });
  };

  return (
    <Form
      form={form}
      layout='vertical'
      name='userDetailsForm'
      onFinish={handleOk}
      initialValues={initUserState}
      onFieldsChange={onFieldValueChanged}
    >
      <Row gutter={10}>
        <Col
          xs={12}
          sm={12}
          md={12}
        >
          <Form.Item
            name='first_name'
            label='שם פרטי'
            rules={[
              {
                required: true,
                message: 'נא להזין שם פרטי!',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col
          xs={12}
          sm={12}
          md={12}
        >
          <Form.Item
            name='last_name'
            label='שם משפחה'
            rules={[
              {
                required: true,
                message: 'נא להזין שם משפחה!',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10}>
        <Col
          xs={24}
          sm={24}
          md={24}
        >
          <Form.Item
            name='email'
            label='אימייל'
            rules={[
              {
                required: true,
                message: 'נא להזין אימייל!',
              },
              {
                pattern: new RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/),
                message: 'אימייל לא תקין',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Divider orientation='left'>עדכון סיסמה</Divider>
      <Row gutter={12}>
        <Col
          xs={12}
          sm={12}
          md={12}
        >
          <Form.Item
            name='password'
            label='סיסמה'
            rules={[
              {
                min: 8,
                message: 'מינימום 8 תווים',
              },
              {
                validator(_, value) {
                  if (!value) {
                    return Promise.reject(new Error('אנא הזן סיסמה!'));
                  }
                  const hasUpperCase = /[A-Z]/.test(value);
                  const hasLowerCase = /[a-z]/.test(value);
                  const hasNumber = /\d/.test(value);
                  // const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(value);

                  if (!hasUpperCase) {
                    return Promise.reject(new Error('חייב לפחות אות גדולה אחת (A-Z)!'));
                  }
                  if (!hasLowerCase) {
                    return Promise.reject(new Error('חייב לפחות אות קטנה אחת (a-z)!'));
                  }
                  if (!hasNumber) {
                    return Promise.reject(new Error('חייב לפחות ספרה אחת (0-9)!'));
                  }
                  // if (!hasSpecialChar) {
                  //   return Promise.reject(new Error('חייב לפחות תו מיוחד אחד (!@#$%^&*)!'));
                  // }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input.Password
              autoComplete='new-password'
              iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            />
          </Form.Item>
        </Col>
        <Col
          xs={12}
          sm={12}
          md={12}
        >
          <Form.Item
            name='confirmPassword'
            label='אימות סיסמה'
            dependencies={['password']}
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('הסיסמאות אינן תואמות!'));
                },
              }),
            ]}
          >
            <Input.Password
              autoComplete='new-password'
              iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row
        gutter={10}
        justify={'end'}
      >
        <Col>
          <Button
            type='default'
            disabled={disabledReset}
            onClick={handleReset}
          >
            איפוס
          </Button>
        </Col>
        <Col>
          <Button
            type='primary'
            htmlType='submit'
            disabled={disabledSave}
            loading={isLoading}
          >
            שמירה
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default UserDetailsForm;
