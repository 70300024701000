import { Button, Col, Form, Input, Row } from 'antd';
import React from 'react';
import { useState } from 'react';
import logger from '../../logger';

const initTemplateState = {
  template_name: '',
};

const ReportTemplateForm = ({ templateDetails, isLoading, onConfirm }) => {
  const [form] = Form.useForm();
  const [disabledSave, setDisabledSave] = useState(true);
  const [disabledReset, setDisabledReset] = useState(true);

  const handleReset = () => {
    form.resetFields();
    setDisabledSave(true);
    setDisabledReset(true);
  };

  const onFieldValueChanged = (_, allFields) => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    const isFilled = allValuesFilled();
    setDisabledSave(hasErrors || !isFilled);
    setDisabledReset(!isFilled);
  };

  const allValuesFilled = () => {
    const formValues = form.getFieldsValue();
    const { template_name } = formValues;
    return template_name.length > 0 && templateDetails;
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        onConfirm({ template_name: values.template_name, templateDetails: templateDetails }).then((res) => {
          if (res) {
            form.resetFields();
            setDisabledSave(true);
            setDisabledReset(true);
          }
        });
      })
      .catch((info) => {
        logger.info('Validate Failed:', info);
      });
  };

  return (
    <Form
      form={form}
      layout='vertical'
      name='templateSearchForm'
      onFinish={handleOk}
      initialValues={initTemplateState}
      onFieldsChange={onFieldValueChanged}
    >
      <Row>
        <Form.Item
          name='template_name'
          label='שם דוח'
          rules={[
            { required: true, message: 'חובה למלא שם!' },
            { min: 3, message: 'מינימום 3 תווים' },
          ]}
        >
          <Input />
        </Form.Item>
      </Row>
      <Row
        gutter={10}
        justify={'end'}
      >
        <Col>
          <Button
            type='default'
            disabled={disabledReset}
            onClick={handleReset}
          >
            ניקוי
          </Button>
        </Col>
        <Col>
          <Button
            type='primary'
            htmlType='submit'
            disabled={disabledSave}
            loading={isLoading}
          >
            אישור
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ReportTemplateForm;
