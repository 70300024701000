import { Button, Checkbox, Col, Form, Input, InputNumber, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import logger from '../../logger';

const initUserState = {
  username: '',
  first_name: '',
  last_name: '',
  email: '',
  department_id: undefined,
  active: true,
};

const UserForm = ({ userDetails, isLoading, onConfirm, departments, accounts, roles }) => {
  const isUserDetailsAvailable = userDetails !== null;
  const [form] = Form.useForm();
  const [disabledSave, setDisabledSave] = useState(true);
  const [disabledReset, setDisabledReset] = useState(true);
  // const [accountsOptions, setAccountsOptions] = useState([]);
  // const [selectedAccount, setSelectedAccount] = useState(null);
  const [departmentsOptions, setDepartmentsOptions] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);

  // Effect for updating form fields when user details are available
  useEffect(() => {
    if (isUserDetailsAvailable) {
      form.setFieldsValue({
        username: userDetails.username,
        first_name: userDetails.first_name,
        last_name: userDetails.last_name,
        email: userDetails.email,
        department_id: userDetails.department.id,
        role_id: userDetails.role_id,
        att_id: userDetails.att_id,
        active: userDetails.active,
      });
      setDisabledSave(false);
      setDisabledReset(true);
      // setSelectedAccount(userDetails.department.account_id);
    } else {
      form.resetFields();
      setDisabledSave(true);
      setDisabledReset(true);
      // setSelectedAccount(null);
    }
  }, [form, isUserDetailsAvailable, userDetails]);

  // Effect for updating account options
  // useEffect(() => {
  //   if (accounts) {
  //     const activeAccounts = accounts.filter((account) => account.active);
  //     setAccountsOptions(
  //       activeAccounts.map((account) => ({
  //         value: account.id,
  //         label: account.account_name,
  //       }))
  //     );
  //     if (activeAccounts?.length) setSelectedAccount(activeAccounts[0].id); //selecte the first active account
  //   }
  // }, [accounts]);

  // Effect for updating department-related data
  // useEffect(() => {
  //   if (departments && userDetails) {
  //     setSelectedAccount(userDetails.department.account_id);
  //   }
  // }, [departments, userDetails]);

  // Effect for updating role options
  useEffect(() => {
    if (roles) {
      setRoleOptions(roles.map((role) => ({ value: role.id, label: role.role_name })));
    }
  }, [roles]);

  const handleReset = () => {
    if (isUserDetailsAvailable) {
      form.setFieldsValue(userDetails);
    } else {
      form.resetFields();
      // setSelectedAccount(null);
      setDisabledSave(true);
    }
    setDisabledReset(true);
  };

  const onFieldValueChanged = (_, allFields) => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    const isFilled = allValuesFilled();
    setDisabledSave(hasErrors || !isFilled);
    setDisabledReset(!isFilled && isUserDetailsAvailable);
  };

  const allValuesFilled = () => {
    const formValues = form.getFieldsValue();
    const { username, first_name, last_name, email, department_id, role_id } = formValues;
    return (
      username !== undefined &&
      first_name !== undefined &&
      last_name !== undefined &&
      email !== undefined &&
      department_id !== undefined &&
      role_id !== undefined
    );
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        onConfirm({ ...values, id: userDetails?.id }).then((res) => {
          //TODO:if confirmation not succeeded than do not reset the fields
          if (res) {
            form.resetFields();
            setDisabledSave(true);
            setDisabledReset(true);
          }
        });
      })
      .catch((info) => {
        logger.info('Validate Failed:', info);
      });
  };

  useEffect(() => {
    // if (selectedAccount) {
    setDepartmentsOptions(
      departments
        // .filter((department) => department.account.id === selectedAccount)
        .map((department) => ({ value: department.id, label: department.department_name }))
    );
    setDisabledReset(false);
    // }
  }, [departments]);

  return (
    <Row>
      {/* {!isUserDetailsAvailable && (
        <Col span={10}>
          <Select
            placeholder='בחר חשבון'
            className='w-100'
            options={accountsOptions}
            value={selectedAccount}
            onSelect={(value) => setSelectedAccount(value)}
            disabled
          />
        </Col>
      )} */}
      <Col span={24}>
        <Form
          form={form}
          layout='vertical'
          name='userForm'
          onFinish={handleOk}
          initialValues={initUserState}
          onFieldsChange={onFieldValueChanged}
        >
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                name='username'
                label='שם משתמש'
                rules={[
                  {
                    min: 3,
                    message: 'מינימום 3 תווים',
                  },
                  {
                    required: true,
                    message: 'נא להזין שם משתמש!',
                  },
                  {
                    pattern: new RegExp(/^[a-z][a-z0-9]*$/),
                    message: 'שם משתמש לא תקין',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='password'
                label='סיסמה'
                rules={[
                  !isUserDetailsAvailable && {
                    required: true,
                    message: 'נא להזין סיסמה!',
                  },
                  {
                    min: 4,
                    message: 'מינימום 4 תווים',
                  },
                ]}
              >
                <Input.Password autoComplete='new-password' />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                name='first_name'
                label='שם פרטי'
                rules={[
                  {
                    required: true,
                    message: 'נא להזין שם פרטי!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='last_name'
                label='שם משפחה'
                rules={[
                  {
                    required: true,
                    message: 'נא להזין שם משפחה!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                name='email'
                label='Email'
                rules={[
                  {
                    required: true,
                    message: 'נא להזין אימייל!',
                  },
                  {
                    pattern: new RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/),
                    message: 'אימייל לא תקין',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='department_id'
                label='מחלקה'
                rules={[
                  {
                    required: true,
                    message: 'נא לבחור מחלקה!',
                  },
                ]}
              >
                <Select options={departmentsOptions} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                name='role_id'
                label='תפקיד'
                rules={[
                  {
                    required: true,
                    message: 'נא לבחור תפקיד!',
                  },
                ]}
              >
                <Select options={roleOptions} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='att_id'
                label='קוד שעון'
              >
                <InputNumber
                  min={1}
                  max={9999}
                  keyboard
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col>
              <Form.Item
                name='active'
                valuePropName='checked'
              >
                <Checkbox>האם המשתמש פעיל?</Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row
            gutter={10}
            justify={'end'}
          >
            <Col>
              <Button
                type='default'
                disabled={disabledReset}
                onClick={handleReset}
              >
                {isUserDetailsAvailable ? 'איפוס' : 'ניקוי'}
              </Button>
            </Col>
            <Col>
              <Button
                type='primary'
                htmlType='submit'
                disabled={disabledSave}
                loading={isLoading}
              >
                אישור
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

export default UserForm;
