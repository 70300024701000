import { EditOutlined, SearchOutlined, UserAddOutlined, ProjectOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table, Typography } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { FaBan, FaCheck } from 'react-icons/fa6';
import TooltipButton from '../../components/buttons/TooltipButton';
import Highlighter from 'react-highlight-words';

const { Text } = Typography;

const UsersTable = ({
  users,
  loading,
  handleCreateUser,
  handleEditUser,
  handleEditProjects,
  departments,
  accounts,
  roles,
}) => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [departmentsOptions, setDepartmentsOptions] = useState([]);
  const [rolesOptions, setRoleOptions] = useState([]);

  const handleRenderRole = (user) => {
    if (!user || !user.role) {
      return '';
    }
    return user.role.role_name;
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    setSearchText('');
    confirm();
  };

  const getColumnSearchProps = (dataIndex, title = '') => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`חיפוש לפי ${title}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{
              width: 90,
            }}
          >
            חיפוש
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size='small'
            style={{
              width: 90,
            }}
          >
            איפוס
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  useEffect(() => {
    if (accounts && departments) {
      setDepartmentsOptions(
        accounts.map((account) => ({
          value: account.account_name,
          text: account.account_name,
          children: departments
            .filter((d) => d.account.id === account.id)
            .map((department) => ({
              text: department.department_name,
              value: department.id,
            })),
        }))
      );
    }
  }, [accounts, departments]);

  useEffect(() => {
    setRoleOptions(
      roles.map((role) => ({
        value: role.id,
        text: role.role_name,
      }))
    );
  }, [roles]);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '5%',
    },
    {
      title: 'שם משתמש',
      dataIndex: 'username',
      key: 'username',
      ...getColumnSearchProps('username', 'שם משתמש'),
      sorter: (a, b) => a.username.localeCompare(b.username),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'שם פרטי',
      dataIndex: 'first_name',
      key: 'first_name',
      ...getColumnSearchProps('first_name', 'שם פרטי'),
      sorter: (a, b) => a.first_name.localeCompare(b.first_name),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'שם משפחה',
      dataIndex: 'last_name',
      key: 'last_name',
      ...getColumnSearchProps('last_name', 'שם משפחה'),
      sorter: (a, b) => a.last_name.localeCompare(b.last_name),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      ...getColumnSearchProps('email', 'Email'),
      sorter: (a, b) => a.email.localeCompare(b.email),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'מחלקה',
      dataIndex: 'department',
      key: 'department',
      filters: departmentsOptions,
      filterMode: 'tree',
      onFilter: (value, record) => {
        return record.department.id === value;
      },
      render: (department) => department.department_name,
    },
    {
      title: 'תפקיד',
      dataIndex: 'roles',
      key: 'roles',
      filters: rolesOptions,
      onFilter: (value, record) => {
        return record.roles[0].id === value;
      },
      render: (_, record) => (
        <Space size='middle'>
          <Text>{handleRenderRole(record)}</Text>
        </Space>
      ),
    },
    {
      title: 'פעיל?',
      dataIndex: 'active',
      key: 'active',
      width: '5%',
      filters: [
        {
          text: 'פעיל',
          value: true,
        },
        {
          text: 'לא פעיל',
          value: false,
        },
      ],
      onFilter: (value, record) => {
        return record.active === value;
      },
      render: (isActive) => (
        <div className='d-flex justify-content-center'>
          {isActive === true ? <FaCheck className='text-success' /> : <FaBan className='text-danger' />}
        </div>
      ),
    },
    {
      title: 'פעולות',
      key: 'actions',
      width: '10%',
      render: (_, record) => (
        <Space size='middle'>
          <TooltipButton
            placement='top'
            type='text'
            title='ערוך משתמש'
            icon={<EditOutlined />}
            onClick={() => handleEditUser(record)}
          ></TooltipButton>
          <TooltipButton
            disabled={!record.active}
            placement='top'
            type='text'
            title='ערוך פרויקטים'
            icon={<ProjectOutlined />}
            onClick={() => handleEditProjects(record)}
          ></TooltipButton>
        </Space>
      ),
    },
  ];

  return (
    <Table
      title={() => (
        <div>
          <Button
            type='primary'
            shape='square'
            onClick={handleCreateUser}
            icon={<UserAddOutlined />}
          >
            הוספה
          </Button>
        </div>
      )}
      columns={columns}
      dataSource={users}
      loading={loading}
      scroll={{ x: 500 }}
      pagination={{
        pageSize: 8,
        hideOnSinglePage: true,
        position: ['bottomCenter'],
      }}
    />
  );
};

export default UsersTable;
